import { BoardColour, HardwareColour, HardwareHandleType, HardwareType } from "~shared/enums";

import { ConnectionType, ItemType } from "../enums/item.enum";

import { Dimension, TopDimensions, SideDimensions, FrontDimensions, ICoordinates } from "./dimension.types";
import { IArticle, IArticleHardware } from "./article.types";
import { IArticleZone } from "./article-zone.types";
import { Shape } from "./shape.types";

export interface ItemMaterialMap {
	dimension: {
		length: number;
		width: number;
		thickness: number;
	};
	colour: BoardColour;
	sku: string;
}

export interface ItemHandleConnector {
	sku: string;
	catalogItem: {
		handleType: HardwareHandleType;
		hardwareType: HardwareType;
		id: string;
		label: string;
		locations: string[];
	}
	variant: {
		colour: HardwareColour;
		sku: string;
	}
}

export interface ItemHandle {
	id: string;
	connectionType: string;
	hardware: IArticleHardware[];
	location: string;
	locations: string[];
	objPositions: {
		offset: ICoordinates;
		position: ICoordinates;
		rotation: ICoordinates;
	}[]
}

export interface ItemDimension {
	height: { [key: string]: Dimension };
	depth: { [key: string]: Dimension };
	width: { [key: string]: Dimension };
	topDimension?: TopDimensions;
	sideDimension?: SideDimensions;
	frontDimension?: FrontDimensions;
}

export interface ItemPartCoordinate {
	partCoordinate: {
		x: number;
		y: number;
		z: number;
	};
	partRotation: {
		x: number;
		y: number;
		z: number;
	};
}

export interface ItemOutline {
	baseIdx: number;
	dimension: ItemDimension;
	fillerIdxs: number[];
	frontOutline?: string;
	sideOutline?: string;
	topOutline?: string;
}

export interface ItemConfigurationOptions {
	connectionType: ConnectionType;
	maximumEdgebanding: boolean;
	visibleConnections: boolean;
	connectionTypeOptions: string[];
}

export interface FillerZone {
	id: string;
	shape: Shape;
	article: IArticle;
	outlineFaceIdx: number;
	outlineFaceNormal: string;
}

export interface Item {
	id: string;
	itemType: ItemType;
	name: string;
	articleZones: IArticleZone[];
	fillerZones: FillerZone[];
	articles: IArticle[];
	outline: ItemOutline;
	configurationOptions: ItemConfigurationOptions;
	customisations: unknown[];
	fillerArticles: IArticle[];
	frontArticle?: IArticle;
	materialMap: Record<string, string[]>;
	partId: string;
	partCoordinate?: {
		x: number;
		y: number;
		z: number;
	};
	partRotation?: {
		x: number;
		y: number;
		z: number;
	};
}
