import { GenericItem } from "~shared/components/cabinet-builder/engine-render.service";
import { DesignerMode, OutlineType, PanelType } from "~shared/enums";
import { IArticle, Outline, IParameterValue } from "~shared/types";

interface IdentificationParameters {
	id?: string;
	articleId?: string;
}

export enum EditorMode {
	DEFAULT,
	SELECT_ARTICLE_ZONES
}

export interface EditorState {
	activeConfigurationPanel: null | string;
	outlineMap: null | Record<OutlineType, Outline>;
	dimensions: Record<string, number | string>
	loading: boolean;
	locked: boolean;
	partEditorFullscreen: boolean;
	partEditorSelectedItem?: string;
	selectedFaceIdx: number;
	designerMode: DesignerMode;
	articles?: Partial<IArticle>[];
	transparentPanelTypes: PanelType[];
	parameters?: Partial<IParameterValue & IdentificationParameters>[];
	selectedItems: GenericItem[];
	openDoorIds: string[];
	fillerHighlight?: string;
	isolatedItem: null | GenericItem;
	mode: EditorMode;
}
