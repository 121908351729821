import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map , catchError } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { Project, Part } from '~shared/types';
import { ProjectStatus } from '~shared/enums';

@Injectable()
export class ProjectService {
	private projectUrl: string = environment.projectServiceUrl + 'project';
	private partsUrl: string = environment.projectServiceUrl + 'part';

	constructor(
		private http: HttpClient,
	) {}

	getProjects(accountId?: string): Observable<Project[]> {
		return this.http
			.get<Project[]>(this.projectUrl, {
				params: { ...(accountId && { account_id: accountId }) }
			});
	}

	getProject(id): Observable<Project> {
		return this.http
			.get<Project>(this.projectUrl + `/${id}`)
			.pipe(map((data) => ({
				...data[0],
			}))); // Gets valid project in history
	}

	updateProject(projectId: string, project: Project): Observable<Project> {
		return this.http
			.put<Project>(this.projectUrl + `/${projectId}`, project);
	}

	createProject(project: Project): Observable<Project> {
		return this.http
			.post<Project>(this.projectUrl, project);
	}

	deleteProject(id): Observable<void> {
		return this.http
			.delete<void>(this.projectUrl + `/${id}`);
	}

	duplicateProject(id): Observable<Project> {
		return this.http
			.post<Project>(this.projectUrl + `/${id}`, {});
	}

	updateProjectStatus(projectId: string, status: ProjectStatus): Observable<Project> {
		return this.http
			.patch<Project>(`${this.projectUrl}/${projectId}/status`, {
				status
			});
	}

	public editProject(newProject: Project, id): Observable<Project> {
		return this.http.get<Project>(this.partsUrl);
	}

	public getPartsByProjectId(projectId: string): Observable<Part[]> {
		return this.http
			.get<Part[]>(this.partsUrl, {
				params: {
					project_id: projectId
				}
			});
	}

	public getParts({ projectId, accountId }): Observable<Part[]> {
		return this.http
			.get<Part[]>(this.partsUrl, {
				params: {
					...(accountId && { account_id: accountId }),
					...(projectId && { project_id: projectId })
				}
			});
	}

	public getPartHistory(projectId: string, partId: string): Observable<Part[]> {
		return this.http
			.get<Part[]>(`${this.partsUrl}/${partId}`);
	}

	public addCustomisationToPart(partId: string, customisationId: string): Observable<Part> {
		return this.http
			.post<Part>(`${this.partsUrl}/${partId}/customisation/${customisationId}`, {});
	}

	public deleteCustomisationFromPart(partId: string, customisationId: string): Observable<Part> {
		return this.http
			.delete<Part>(`${this.partsUrl}/${partId}/customisation/${customisationId}`, {});
	}

	public changePartStatus(part_id: string, status: string): Observable<Part[]> {
		const body = {
			status,
		};
		return this.http
			.patch<Part[]>(this.partsUrl + '/' + part_id, body);
	}

	public editPart(newPart: Part): Observable<Part> {
		return this.http
			.put<Part>(this.partsUrl + '/' + newPart.id, newPart);
	}

	public deletePart(part_id) {
		return this.http
			.delete(this.partsUrl + '/' + part_id);
	}

	public createPart(to_create): Observable<Part> {
		return this.http
			.post<Part>(this.partsUrl, to_create);
	}

	public updatePart(partId, part): Observable<Part> {
		return this.http
			.put<Part>(`${this.partsUrl}/${partId}`, part);
	}

	public duplicatePart(partId: string): Observable<Part> {
		return this.http
			.post<Part>(`${this.partsUrl}/${partId}`, {});
	}

	public updatePartStatus(partId: string, status): Observable<Part> {
		return this.http
			.patch<Part>(`${this.partsUrl}/${partId}/status`, { status });
	}
}
