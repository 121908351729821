export enum ConstraintType {
    LARGER_THEN = "LARGER_THEN",
    SMALLER_THEN = "SMALLER_THEN",
    INLIST = "INLIST",
    BETWEEN = "BETWEEN",
    LARGERTHEN_INLIST = "LARGERTHEN_INLIST",
    SMALLERTHEN_INLIST = "SMALLERTHEN_INLIST",

}

export interface IConstraint {
	type: ConstraintType;
	violated: boolean;
	lowerBoundary: string;
	upperBoundary: string;
	strict_lower?: boolean;
	strict_upper?: boolean;
	strict?: boolean;
	values?: unknown[];
	boundary: string;
}
