export enum CustomisationType {
	SOCKET_CUTOUT = 'SOCKETCUTOUT',
	FILLER = 'FILLER',
	GENERIC = 'GENERIC'
}

export enum CustomisationStatus {
	PENDING = 'PENDING',
	ACCEPTED = 'ACCEPTED',
	VALIDATED = 'VALIDATED',
	ARCHIVED = 'ARCHIVED',
}

export interface Customisation {
	id: string;
	customisationType: CustomisationType;
	description: string;
	response: string;
	price: number;
	status: CustomisationStatus;
	files: any[];
}
