import { Injectable } from '@angular/core';
import { updateRequestStatus } from '@ngneat/elf-requests';
import { take, tap } from 'rxjs/operators';
import { deleteEntities, setActiveId, setEntities, updateEntities, upsertEntities } from '@ngneat/elf-entities';
import { Observable, map } from 'rxjs';
import { setProp } from '@ngneat/elf';

import { CustomerBoard, EnrichedBoard, FinishedBoard, Item, RawBoard } from '~shared/types';
import { BoardOverlayType, BoardType } from '~shared/enums';
import { appStore } from '~shared/store/app/app.store';

import { BoardsService } from '../../services/boards.service'

import { boardStore } from './boards.store';
import { boardsSelector } from './boards.selectors';

@Injectable()
export class BoardsRepository {
	public boards$ = boardsSelector.boards$;
	public enrichedBoardsLoading$ = boardsSelector.enrichedBoardsLoading$;
	public createRawBoardLoading$ = boardsSelector.createRawBoardLoading$;
	public createFinishedBoardLoading$ = boardsSelector.createFinishedBoardLoading$;
	public getCustomerBoardsLoading$ = boardsSelector.getCustomerBoardsLoading$;
	public getCatalogBoardLoading$ = boardsSelector.getCatalogBoardLoading$;
	public createCustomerBoardLoading$ = boardsSelector.createCustomerBoardLoading$;
	public deleteCustomerBoardLoading$ = boardsSelector.deleteCustomerBoardLoading$;
	public updateFinishedBoardLoading$ = boardsSelector.updateFinishedBoardLoading$;
	public deleteCatalogBoardLoading$ = boardsSelector.deleteCatalogBoardLoading$;
	public updateRawBoardLoading$ = boardsSelector.updateRawBoardLoading$;
	public activeItem$ = boardsSelector.activeItem$;

	constructor(private readonly boardsService: BoardsService) {}

	public getEnrichedBoards(accountId: string, boardType: BoardType, overlayType?: BoardOverlayType): Observable<EnrichedBoard[]> {
		boardStore.update(updateRequestStatus('get-enriched-boards', 'pending'));
		appStore.update(setProp('loading', true));
		return this.boardsService
			.getEnrichedBoards(accountId, boardType, overlayType)
			.pipe(
				take(1),
				tap((boards) => {
					appStore.update(setProp('loading', false));
					boardStore.update(
						setEntities(boards),
						setActiveId(boards?.[0]?.id),
						updateRequestStatus('get-enriched-boards', 'success')
					)
				})
			)
	}

	public createRawBoard(board: RawBoard): Observable<RawBoard> {
		boardStore.update(updateRequestStatus('create-raw-board', 'pending'));
		appStore.update(setProp('loading', true));
		return this.boardsService
			.createRawBoard(board)
			.pipe(
				take(1),
				tap((boards) => {
					appStore.update(setProp('loading', false));
					boardStore.update(
						upsertEntities(boards),
						updateRequestStatus('create-raw-board', 'success')
					)
				})
			)
	}

	public createFinishedBoard(board: FinishedBoard): Observable<FinishedBoard> {
		boardStore.update(updateRequestStatus('create-finished-board', 'pending'));
		appStore.update(setProp('loading', true));
		return this.boardsService
			.createFinishedBoard(board)
			.pipe(
				take(1),
				tap((boards) => {
					appStore.update(setProp('loading', false));
					boardStore.update(
						upsertEntities(boards),
						updateRequestStatus('create-finished-board', 'success')
					)
				})
			)
	}

	public deleteCatalogBoard(boardSku: string): Observable<void> {
		boardStore.update(updateRequestStatus('delete-catalog-board', 'pending'));
		appStore.update(setProp('loading', true));
		return this.boardsService
			.deleteCatalogBoard(boardSku)
			.pipe(
				take(1),
				tap(() => {
					appStore.update(setProp('loading', false));
					boardStore.update(
						deleteEntities(boardSku),
						updateRequestStatus('delete-catalog-board', 'success')
					)
				})
			)
	}

	public getCustomerBoards(accountId: string): Observable<(RawBoard | FinishedBoard)[]> {
		boardStore.update(updateRequestStatus('get-customer-boards', 'pending'));
		appStore.update(setProp('loading', true));
		return this.boardsService
			.getCustomerBoards(accountId)
			.pipe(
				take(1),
				map((boards) => boards.map((board) => ({
					...board.board,
					customerBoardId: board.id,
					isFavourite: board.isFavourite,
				}))),
				tap((boards) => {
					appStore.update(setProp('loading', false));
					boardStore.update(
						setEntities(boards),
						setActiveId(boards?.[0]?.id),
						updateRequestStatus('get-customer-boards', 'success')
					)
				})
			)
	}

	public getCatalogBoard(catalogBoardId: string, upsert = true): Observable<RawBoard | FinishedBoard | EnrichedBoard> {
		boardStore.update(updateRequestStatus('get-catalog-board', 'pending'));
		appStore.update(setProp('loading', true));
		return this.boardsService
			.getCatalogBoard(catalogBoardId)
			.pipe(
				take(1),
				tap((board) => {
					appStore.update(setProp('loading', false));
					if (!upsert) {
						return boardStore.update(
							updateRequestStatus('get-catalog-board', 'success')
						);
					}

					boardStore.update(
						upsertEntities(board),
						setActiveId(catalogBoardId),
						updateRequestStatus('get-catalog-board', 'success')
					)
				})
			)
	}

	public updateRawBoard(boardSku: string, board): Observable<RawBoard> {
		boardStore.update(updateRequestStatus('update-raw-board', 'pending'));
		appStore.update(setProp('loading', true));
		return this.boardsService
			.updateRawBoard(boardSku, board)
			.pipe(
				take(1),
				tap((board) => {
					appStore.update(setProp('loading', false));
					boardStore.update(
						upsertEntities(board),
						updateRequestStatus('update-raw-board', 'success')
					)
				})
			)
	}

	public updateFinishedBoard(boardSku: string, board): Observable<FinishedBoard> {
		boardStore.update(updateRequestStatus('update-finished-board', 'pending'));
		appStore.update(setProp('loading', true));
		return this.boardsService
			.updateFinishedBoard(boardSku, board)
			.pipe(
				take(1),
				tap((board) => {
					appStore.update(setProp('loading', false));
					boardStore.update(
						upsertEntities(board),
						updateRequestStatus('update-finished-board', 'success')
					)
				})
			)
	}

	public favouriteCustomerBoard(accountId: string, boardSku: string): Observable<RawBoard | FinishedBoard | EnrichedBoard> {
		boardStore.update(updateRequestStatus('favourite-customer-board', 'pending'));
		appStore.update(setProp('loading', true));
		return this.boardsService
			.favouriteCustomerBoard(accountId, boardSku,)
			.pipe(
				take(1),
				map((board) => ({
					...board.board,
					customerBoardId: board.id,
					isFavourite: board.isFavourite
				})),
				tap((board) => {
					appStore.update(setProp('loading', false));
					boardStore.update(
						upsertEntities(board),
						updateRequestStatus('favourite-customer-board', 'success')
					)
				})
			)
	}

	public createCustomerBoards(accountId: string, boardSku: string, boardId?: string): Observable<RawBoard | FinishedBoard> {
		boardStore.update(updateRequestStatus('create-customer-board', 'pending'));
		appStore.update(setProp('loading', true));
		return this.boardsService
			.createCustomerBoard(accountId, boardSku)
			.pipe(
				take(1),
				tap(() => {
					appStore.update(setProp('loading', false));
					if (boardId) {
						return boardStore.update(
							updateEntities(boardId, (entity: EnrichedBoard) => ({
								...entity,
								dimensionVariants: entity.dimensionVariants?.map((map) => {
									if (map.boardSku !== boardSku) {
										return map;
									}

									return {
										...map,
										isFavourite: true,
										isClientBoard: true
									}
								})
							})),
							updateRequestStatus('create-customer-board', 'success')
						)
					}

					boardStore.update(
						updateRequestStatus('create-customer-board', 'success')
					)
				}),
				map((board) => board.board),
			)
	}

	public deleteCustomerBoard(accountId: string, boardId: string, boardSku: string): Observable<void> {
		boardStore.update(updateRequestStatus('delete-customer-board', 'pending'));
		appStore.update(setProp('loading', true));
		return this.boardsService
			.deleteCustomerBoard(accountId, boardSku)
			.pipe(
				take(1),
				tap(() => {
					appStore.update(setProp('loading', false));
					boardStore.update(
						updateEntities(boardId, (entity: EnrichedBoard) => ({
							...entity,
							dimensionVariants: entity.dimensionVariants?.map((map) => {
								if (map.boardSku !== boardSku) {
									return map;
								}

								return {
									...map,
									isFavourite: false,
									isClientBoard: false
								}
							})
						})),
						updateRequestStatus('delete-customer-board', 'success')
					)
				})
			)
	}

	public activateItem(externalSku: string): void {
		boardStore.update(
			setActiveId(externalSku),
		)
	}
}
