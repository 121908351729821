import { Component, Input, Type, ViewChild, ComponentFactoryResolver, OnInit, EventEmitter, AfterViewInit} from '@angular/core';
import { NgScrollbar } from 'ngx-scrollbar';

import { RibbonControllerDirective } from '../../directives/ribbon-controller/ribbon-controller.directive';

@Component({
	selector: 'app-item-details-ribbon',
	templateUrl: './item-details-ribbon.component.html'
})

export class ItemDetailsRibbonComponent implements OnInit, AfterViewInit {
	private _ribbonControllers: ControllerItem[] = [];
	@Input() set ribbonControllers(controllers: ControllerItem[]){
		this._ribbonControllers = controllers;
		this.loadComponent();
	}
	@ViewChild(NgScrollbar) scrollbarRef: NgScrollbar;
	public scrolledToRight = false;
	public scrolledToLeft = true;

	public ngAfterViewInit(): void {
		const element = this.scrollbarRef.nativeElement.children[0].children[0].children[0];

		setTimeout(() => {
			if (element.scrollWidth - (element as any).offsetWidth === 0) {
				this.scrolledToRight = true;
			}
		});

		this.scrollbarRef.scrolled.subscribe(e => {
			if ((e.target as any).scrollWidth - (e.target as any).offsetWidth <= (e.target as any).scrollLeft + 10) {
				this.scrolledToRight = true;
				this.scrolledToLeft = false;
			} else if ((e.target as any).scrollLeft < 10) {
				this.scrolledToRight = false;
				this.scrolledToLeft = true;
			} else {
				this.scrolledToRight = false;
				this.scrolledToLeft = false;
			}
		});
	}

	get ribbonControllers(): ControllerItem[]{
		return this._ribbonControllers;
	}

	@ViewChild(RibbonControllerDirective, {static: true}) ribbonControllerHost!: RibbonControllerDirective;

	ngOnInit() {
		this.loadComponent();
	}

	loadComponent(){
		const viewContainerRef = this.ribbonControllerHost.viewContainerRef;
		viewContainerRef.clear();

		this.ribbonControllers.forEach(controller => {
			const componentRef = viewContainerRef.createComponent(controller.component);
			componentRef.instance.data = controller.data;
			componentRef.instance.eventEmitter = controller.eventEmmitter;
		});
	}
}

export class ControllerItem {
	constructor(public component: Type<any>, public data?: any, public eventEmmitter?: EventEmitter<any>) {}
}
