import { createStore, withProps } from '@ngneat/elf';
import { withActiveId, withEntities } from '@ngneat/elf-entities';
import { withRequestsStatus } from '@ngneat/elf-requests';

import { Project } from '~shared/types';

export const projectStore = createStore(
	{ name: 'projects' },
	withEntities<Project>(),
	withActiveId(),
	withRequestsStatus<`projects` | `project-${string}`>()
);
