import { select } from '@ngneat/elf';
import { selectActiveEntity, selectAllEntities } from '@ngneat/elf-entities';
import { selectRequestStatus } from '@ngneat/elf-requests';
import { map } from 'rxjs/operators';

import { boardStore } from './boards.store';

export const boardsSelector = {
	boards$: boardStore.pipe(selectAllEntities()),
	enrichedBoardsLoading$: boardStore.pipe(
		selectRequestStatus('get-enriched-boards'),
		map((status) => status.value === 'pending')
	),
	createRawBoardLoading$: boardStore.pipe(
		selectRequestStatus('create-raw-board'),
		map((status) => status.value === 'pending')
	),
	createFinishedBoardLoading$: boardStore.pipe(
		selectRequestStatus('create-finished-board'),
		map((status) => status.value === 'pending')
	),
	getCustomerBoardsLoading$: boardStore.pipe(
		selectRequestStatus('get-customer-boards'),
		map((status) => status.value === 'pending')
	),
	getCatalogBoardLoading$: boardStore.pipe(
		selectRequestStatus('get-catalog-board'),
		map((status) => status.value === 'pending')
	),
	createCustomerBoardLoading$: boardStore.pipe(
		selectRequestStatus('create-customer-board'),
		map((status) => status.value === 'pending')
	),
	deleteCustomerBoardLoading$: boardStore.pipe(
		selectRequestStatus('delete-customer-board'),
		map((status) => status.value === 'pending')
	),
	updateRawBoardLoading$: boardStore.pipe(
		selectRequestStatus('update-raw-board'),
		map((status) => status.value === 'pending')
	),
	updateFinishedBoardLoading$: boardStore.pipe(
		selectRequestStatus('update-finished-board'),
		map((status) => status.value === 'pending')
	),
	deleteCatalogBoardLoading$: boardStore.pipe(
		selectRequestStatus('delete-catalog-board'),
		map((status) => status.value === 'pending')
	),
	activeItem$: boardStore.pipe(selectActiveEntity()),
};
