import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BoardsRepository } from '~modules/boards/store/boards/boards.repository';
import { BoardsService } from '~modules/boards/services/boards.service';

import { SharedModule } from '../../shared/shared.module';

import { ProjectsRoutingModule } from './projects-routing.module';
import { JsonReaderService } from './services/json-reader.service';
import { UserInputsService } from './services/user-inputs.service';
import { Pages } from './pages';
import { Pipes } from './pipes';
import { Repositories } from './store';
import { Directives } from './directives';
import { Modals } from './modals';
import { Components } from './components';
import { RibbonComponents } from './ribbon-components';
import { ConfigurationComponents } from './configuration-components';

@NgModule({
	declarations: [
		Pages,
		Pipes,
		Directives,
		Modals,
		Components,
		RibbonComponents,
		ConfigurationComponents
	],
	imports: [
		SharedModule,
		CommonModule,
		ProjectsRoutingModule
	],
	providers: [
		JsonReaderService,
		UserInputsService,
		Repositories,
		BoardsRepository,
		BoardsService
	],
})
export class ProjectsModule {}
