import { addBaseUrlToUrls, commonEnvironment } from "./environment.common";

export const environment = {
	...commonEnvironment,
	production: false,
	baseUrl: 'https://staging-api.kastenman.be',
	cognito: {
		REGION: 'eu-central-1',
		USER_POOL_ID: 'eu-central-1_jRhcKf4Hw',
		APP_CLIENT_ID: '1t334regugd7dc2kmbopiorkj6',
		IDENTITY_POOL_ID: 'eu-central-1:7e0aa52e-e97b-4b5d-8c08-fdf6c4d4d171',
	}
};

addBaseUrlToUrls(environment, commonEnvironment);
