import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { BaseAccount } from '~modules/auth/types/account.types';
import { InternalUser, ExternalUser } from "~modules/auth/types/user.types";

import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class AccountService {
	constructor(
		private readonly http: HttpClient
	) {}

	createUser(token: string, user: any): Observable<any> {
		const params: HttpParams = new HttpParams();
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('Authorization', `Bearer ${token}`);
		return this.http.post<any> (`${environment.accountServiceUrl}/user`, user, {
			params,
			headers
		})
			.pipe(map(response => response.user));

	}

	public createAccount(account: any): Observable<any> {
		return this.http.post<any> (`${environment.accountServiceUrl}/account`, account);
	}

	public getUser(userId: string): Observable<InternalUser | ExternalUser> {
		return this.http.get<InternalUser | ExternalUser>(`${environment.accountServiceUrl}/user/${userId}`)
			.pipe(
				switchMap((user) => {
					if (!(user as ExternalUser).accountId) {
						return of(user);
					}

					return this.http.get(`${environment.accountServiceUrl}/account/${(user as ExternalUser).accountId}`)
						.pipe(map((account) => ({
							...user,
							account
						}) as any))
				})
			);
	}

	public updateUser(userId: string, body): Observable<InternalUser | ExternalUser> {
		return this.http.put<InternalUser | ExternalUser>(`${environment.accountServiceUrl}/user/${userId}`, body);
	}

	public createExternalUser(userId: string, body): Observable<InternalUser | ExternalUser> {
		return this.http.post<ExternalUser>(`${environment.accountServiceUrl}/user/${userId}/external`, body);
	}

	public createInternalUser(userId: string, body): Observable<InternalUser | ExternalUser> {
		return this.http.post<InternalUser>(`${environment.accountServiceUrl}/user/${userId}/internal`, body);
	}

	public getAccounts(): Observable<BaseAccount[]> {
		return this.http.get<BaseAccount[]>(`${environment.accountServiceUrl}/account`);
	}

	public getAccount(accountId: string): Observable<BaseAccount> {
		return this.http.get<BaseAccount>(`${environment.accountServiceUrl}/account/${accountId}`);
	}

	public updateAccount(accountId: string, body): Observable<InternalUser | ExternalUser> {
		return this.http.put<InternalUser | ExternalUser>(`${environment.accountServiceUrl}/account/${accountId}`, body);
	}
}
