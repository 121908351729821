import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { getDocument, GlobalWorkerOptions, PDFPageProxy } from 'pdfjs-dist';
import { PanAndZoom } from "pan-and-zoom";
import { clamp } from 'ramda';
import { BehaviorSubject, map, take } from 'rxjs';
import { FormControl } from '@angular/forms';

import { DrawingPage } from './sandbox.types';

GlobalWorkerOptions.workerSrc = '/assets/pdfjs/pdf.worker.js'

@Component({
	templateUrl: './sandbox.component.html',
})
export class SandboxComponent implements AfterViewInit {
	@ViewChild('container', { read: ElementRef }) containerRef: ElementRef;

	public control = new FormControl('HLB');

	public paz;
	public pages: DrawingPage[];
	public dragging = false;
	public previousMouseValue: { x: number; y: number; };
	public meta$: BehaviorSubject<any> = new BehaviorSubject([
		{
			page: 1,
			comments: [
				{ position: { x: 60, y: 10 } }
			]
		},
		{
			page: 3,
			comments: [
				{ position: { x: 35, y: 90 } },
				{ position: { x: 20, y: 40 } },
			]
		}
	])

	public ngAfterViewInit(): void {
		const { nativeElement } = this.containerRef;
		// panzoom(this.containerRef.nativeElement, {
		// 	maxZoom: 5,
		// 	minZoom: 1,
		// 	beforeWheel: function(e) {
		// 		// allow wheel-zoom only if altKey is down. Otherwise - ignore
		// 		const shouldIgnore = !e.altKey;
		// 		return shouldIgnore;
		// 	}
		// })

		nativeElement.addEventListener("mousedown", (e) => {
			// e.preventDefault();
			this.dragging = true;
			this.previousMouseValue = { x: e.screenX, y: e.screenY };
		});

		document.addEventListener("mouseup", (e) => {
			e.preventDefault();
			this.dragging = false;
		});

		nativeElement.addEventListener("mousemove", (e) => {
			e.preventDefault();

			if (!this.dragging) {
				return;
			}

			const velocityX = this.previousMouseValue.x - e.screenX;
			const velocityY = this.previousMouseValue.y - e.screenY;

			this.paz.panX = clamp(
				-nativeElement.clientWidth * ((this.paz.zoom - 1) / 2),
				nativeElement.clientWidth * ((this.paz.zoom - 1) / 2),
				this.paz.panX - velocityX
			);
			this.paz.panY = clamp(
				-nativeElement.clientHeight * ((this.paz.zoom - 1) / 2),
				nativeElement.clientHeight * ((this.paz.zoom - 1) / 2),
				this.paz.panY - velocityY
			);

			this.previousMouseValue = { x: e.screenX, y: e.screenY };
		});

		this.paz = new PanAndZoom({
			// Callback fired after changing a `PanAndZoom' object's properties
			update: () => {
				nativeElement.style.transform = this.paz;
			},
		});

		const loadingTask = getDocument('/assets/example.pdf')

		loadingTask.promise.then(async (pdf) => {
			this.meta$
				.subscribe(async (meta) => {
					this.pages = await Promise.all([...new Array(pdf.numPages)].map(async (_, pageNum) => {
						const metadata = meta.find(({ page }) => page === pageNum + 1);

						return {
							pdf: await pdf.getPage(pageNum + 1),
							comments: metadata?.comments || []
						}
					}));
				})
		});
	}

	public trackByFn(index, item): void {
		return index;
	}

	public handleAddComment({ x, y }, pageNum): void {
		this.meta$
			.pipe(
				take(1),
				map((meta) => {
					const existingPageEntry = meta.find(({ page }) => page === pageNum);

					if (!existingPageEntry) {
						return [
							...meta,
							{
								page: pageNum,
								comments: [{ position: { x, y }}]
							}
						]
					}

					return [
						...meta.filter(({ page }) => page !== pageNum),
						{
							...existingPageEntry,
							comments: [
								...existingPageEntry.comments,
								{ position: { x, y }}
							]
						}
					]
				})
			)
			.subscribe((meta) => {
				this.meta$.next(meta)
			})
	}

	public zoomIn(): void {
		this.paz.zoom = clamp(1, 10, this.paz.zoom + 0.1);
		this.ensureWithinBounds();
	}

	public zoomOut(): void {
		this.paz.zoom = clamp(1, 10, this.paz.zoom - 0.1);
		this.ensureWithinBounds();
	}

	private ensureWithinBounds(): void {
		this.paz.panX = clamp(
			-this.containerRef.nativeElement.clientWidth * ((this.paz.zoom - 1) / 2),
			this.containerRef.nativeElement.clientWidth * ((this.paz.zoom - 1) / 2),
			this.paz.panX
		);

		this.paz.panY = clamp(
			-this.containerRef.nativeElement.clientHeight * ((this.paz.zoom - 1) / 2),
			this.containerRef.nativeElement.clientHeight * ((this.paz.zoom - 1) / 2),
			this.paz.panY
		);
	}
}
