import { createStore, withProps } from '@ngneat/elf';
import { withActiveId, withEntities } from '@ngneat/elf-entities';
import { withRequestsStatus } from '@ngneat/elf-requests';

import { CuttingList } from '~shared/types';

export const cuttingListStore = createStore(
	{ name: 'cutting-lists' },
	withEntities<CuttingList>(),
	withActiveId(),
	withRequestsStatus<string>()
);
