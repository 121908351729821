import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { catchError, merge, filter, combineLatest, map, Observable, of, Subject, switchMap, take, takeUntil, throwError, skip, tap } from 'rxjs';
import { pick } from 'ramda';

import { Colour, DrawerAccess, FiberCover, DrawerFrontType, DrawerHeight, DrawerDepth, SiphonSpaceWidth } from '~shared/enums/item.enum';
import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { ItemsRepository } from '~modules/projects/store/items/items.repository';
import { Item } from '~shared/types';
import { GenericItem } from '~shared/components/cabinet-builder/engine-render.service';
import { findUpdatedPanel } from '~shared/helpers';

import { DRAWER_FRONT_TYPES, DRAWER_HEIGHT_OPTIONS } from './drawer.const';

@Component({
	templateUrl: './drawer.component.html',
})
export class DrawerComponent implements OnInit, OnDestroy {
	public heightOptions: { badge?: string; value: string; empty?: true }[];
	public depthOptions: { badge?: string; value: number; empty?: true }[];
	public frontTypeOptions: { badge: string; value: string }[];
	public configurationForm: FormGroup<{
		id: FormControl<string>;
		drawerCardinality: FormControl<number>;
		drawerAccess: FormControl<DrawerAccess>;
		frameColour: FormControl<Colour>;
		frontType: FormControl<DrawerFrontType>;
		matColour: FormControl<FiberCover>;
		height: FormControl<DrawerHeight>;
		depth: FormControl<DrawerDepth>;
		tipOn: FormControl<boolean>;
		siphonSpace: FormControl<boolean>;
		siphonSpaceOffsetLeft: FormControl<number>;
		siphonSpaceWidth: FormControl<SiphonSpaceWidth>;
	}>;
	public openItems: Record<string, boolean> = {
		GENERAL: true,
		CARDINALITY: true,
		BLUM: true,
		DRAWER_CHANGES: true
	};

	public activeItem$: Observable<Item>;
	public activePanel$: Observable<GenericItem>;
	private componentDestroyed$: Subject<boolean> = new Subject()

	constructor(
		private readonly editorRepository: EditorRepository,
		private readonly itemsRepository: ItemsRepository,
		private readonly fb: FormBuilder
	) {}

	@HostListener('document:keydown.escape', ['$event'])
	public onKeydownHandler(event: KeyboardEvent) {
		this.initializeDefaults();
	}

	public ngOnInit(): void {
		this.activeItem$ = this.itemsRepository.activeItem$;
		this.activePanel$ = this.editorRepository.selectedItems$
			.pipe(map(([item]) => item));

		this.configurationForm = this.fb.group({
			id: ['new'],
			drawerCardinality: [0, [Validators.required]],
			drawerAccess: [DrawerAccess.INTERNAL],
			frameColour: [Colour.WHITE],
			frontType: [DrawerFrontType.GLASS],
			matColour: [FiberCover.BLACK],
			height: [null],
			depth: [null],
			tipOn: [false],
			siphonSpace: [false],
			siphonSpaceWidth: [SiphonSpaceWidth.SMALL_160],
			siphonSpaceOffsetLeft: [100],
		});

		this.configurationForm.get('frameColour').valueChanges
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe(() => setTimeout(() => this.submitForm(['frameColour'])));

		this.configurationForm.get('matColour').valueChanges
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe(() => setTimeout(() => this.submitForm(['matColour'])));

		this.configurationForm.get('frontType').valueChanges
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe(() => setTimeout(() => this.submitForm(['frontType'])));

		this.configurationForm.get('height').valueChanges
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe(() => setTimeout(() => this.submitForm(['height'])));

		this.configurationForm.get('depth').valueChanges
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe(() => setTimeout(() => this.submitForm(['depth'])));

		this.configurationForm.get('tipOn').valueChanges
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe(() => setTimeout(() => this.submitForm(['tipOn'])));

		this.configurationForm.get('siphonSpace').valueChanges
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe(() => setTimeout(() => this.submitForm(['siphonSpace'])));

		this.configurationForm.get('siphonSpaceWidth').valueChanges
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe(() => setTimeout(() => this.submitForm(['siphonSpace'])));

		this.editorRepository.disableFields$
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe((disabled) => disabled
				? this.configurationForm.disable({ emitEvent: false })
				: this.configurationForm.enable({ emitEvent: false }))

		this.initializeDefaults();
	}

	public submitForm(pickProps?: string[]): void {
		const { value } = this.configurationForm;

		if (value.id === 'new') {
			return this.createItem();
		}

		const body = {
			frameColour: value.frameColour,
			frontType: value.frontType,
			matColour: value.matColour,
			height: value.height,
			depth: value.depth,
			siphonSpace: value.siphonSpace ? {
				width: value.siphonSpaceWidth,
				offsetLeft: value.siphonSpaceOffsetLeft,
			} : null,
			tipOn: value.tipOn,
		};

		this.editorRepository.setLoading(true);
		combineLatest([this.editorRepository.selectedArticles$, this.itemsRepository.activeItem$])
			.pipe(
				take(1),
				switchMap(([[article], item]) => combineLatest([
					this.itemsRepository.updateArticle(item.partId, item.id, article.id, pickProps ? pick(pickProps)(body) : body).pipe(take(1)),
					(this.configurationForm.get('drawerAccess').dirty || this.configurationForm.get('drawerCardinality').dirty) ? this.itemsRepository.updateArticle(item.partId, item.id, article.parentArticleId, {
						...(value.drawerAccess && { drawerAccess: value.drawerAccess }),
						...(value.drawerCardinality && { drawerCardinality: value.drawerCardinality }),
					}).pipe(take(1)) : of(null),
				])),
				catchError((err) => {
					this.editorRepository.setLoading(false)
					return throwError(() => err);
				}),
				switchMap(([updatedItem]) => this.editorRepository.selectedItems$.pipe(take(1), map(([selectedPanel]) => ({ selectedPanel, updatedItem }))))
			)
			.subscribe(({ updatedItem, selectedPanel }) => {
				this.editorRepository.setLoading(false);
				const updatedPanel = findUpdatedPanel(updatedItem, selectedPanel);

				if (updatedPanel) {
					return this.editorRepository.setSelectedItems([updatedPanel]);
				}

				const updatedArticle = findUpdatedPanel(updatedItem, selectedPanel?.article);
				return this.editorRepository.setSelectedItems([{
					...selectedPanel,
					article: updatedArticle,
				}]);
			})
	}

	public populateCardinality(drawerCardinality: number): void {
		this.configurationForm.patchValue({
			drawerCardinality
		});

		this.submitForm();
	}

	public createItem(): void {
		this.configurationForm.markAllAsTouched();
		const { value } = this.configurationForm;

		if (!this.configurationForm.valid) {
			return;
		}

		this.editorRepository.setLoading(true);
		combineLatest([this.itemsRepository.activeItem$, this.editorRepository.selectedArticleZones$])
			.pipe(
				take(1),
				switchMap(([item, [articleZone]]) => this.itemsRepository.createArticleInArticleZone(item.partId, item.id, articleZone?.id || item.articleZones[0].id, 'DRAWERS', {
					drawerCardinality: value.drawerCardinality,
					drawerAccess: value.drawerAccess,
				})),
				catchError((err) => {
					this.editorRepository.setLoading(false);
					return throwError(() => err);
				}),
			)
			.subscribe((item) => {
				this.editorRepository.setLoading(false);
				this.editorRepository.setActiveConfigurationPanel(null);
				this.editorRepository.setSelectedItems([]);
			})
	}

	public toggleItem(itemName: string): void {
		this.openItems[itemName] = !this.openItems[itemName] || false;
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}

	public initializeDefaults(): void {
		combineLatest([this.itemsRepository.activeItem$, this.editorRepository.selectedArticles$, this.editorRepository.selectedItems$])
			.pipe(
				takeUntil(this.componentDestroyed$),
				filter(([_, articles]) => !!articles && !!articles.length)
			)
			.subscribe(([item, [article]]) => {
				const parentArticle = item.articles.find(({ id }) => id === article.parentArticleId);

				this.configurationForm.reset({}, { emitEvent: false });
				this.configurationForm.patchValue({
					id: article.id,
					frameColour: article.frameColour,
					drawerAccess: article.drawerAccess,
					drawerCardinality: parentArticle?.drawerCardinality,
					frontType: article.frontType,
					matColour: article.matColour || null,
					siphonSpace: !!article.siphonSpace,
					tipOn: article.tipOn || false,
					depth: article.depth || null,
					height: article.height || null,
					siphonSpaceOffsetLeft: article.siphonSpace?.offsetLeft || 100,
					siphonSpaceWidth: article.siphonSpace?.width || SiphonSpaceWidth.SMALL_160,
				}, { emitEvent: false });
				this.configurationForm.get('drawerAccess').disable();

				this.frontTypeOptions = article.frontTypeOptions.map((frontTypeOption) => ({ badge: DRAWER_FRONT_TYPES[frontTypeOption] || frontTypeOption, value: frontTypeOption }))
				this.heightOptions = [
					{
						value: null,
						empty: true,
					},
					...article.heightOptions.map((height) => ({ badge: DRAWER_HEIGHT_OPTIONS[height] || height, value: height })),
				];
				this.depthOptions = [
					{
						value: null,
						empty: true,
					},
					...article.depthOptions.map((depth) => ({ badge: depth.toString(), value: Number(depth) }))
				];
			})
	}
}
