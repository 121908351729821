import { BoardColour, BoardGlueType, BoardOverlayAspect, BoardOverlayCategory, BoardOverlayType, BoardType, EdgebandType, RawBoardAspect, RawBoardType } from '../enums';

export interface Edgeband {
	label: string;
	description: string;
	manufacturerCode: string;
	colour: BoardColour;
	glueType: BoardGlueType;
	width: number;
	thickness: number;
	edgebandType: EdgebandType;
	edgebandManufacturer: string;
}

export interface BoardDimension {
	length: number;
	width: number;
	thickness: number;
}

export interface BaseBoard {
	id: string;
	sku: string;
	boardType: BoardType;
	rawBoardType: RawBoardType;
	isDefault: boolean;
	manufacturer: string;
	supplier: string;
	externalName: string;
	externalSku: string;
	imageUrl: string;
	productUrl: string;
	colour: BoardColour;
	grain: boolean;
	priceRange: number;
	deliveryWeeks: number;
	label: string;
	imosLabel: string;
	dimension: BoardDimension;
	edgeband: Edgeband;
	isVoid: boolean;
	description: string;
	dimensionVariants?: DimensionVariant[]
}

export interface RawBoard extends BaseBoard {
	rawBoardAspects: RawBoardAspect[];
}

export interface FinishedBoard extends BaseBoard {
	rawBoardAspects: RawBoardAspect[];
	overlayCategory: BoardOverlayCategory;
	overlayType: BoardOverlayType;
	overlayAspect: BoardOverlayAspect;
	doubleSided: boolean;
}

export interface CustomerBoard {
	id: string;
	accountId: string;
	isFavourite: boolean;
	board: RawBoard | FinishedBoard;
}

export interface DimensionVariant {
	boardSku: string;
	thickness: number;
	dimension: BoardDimension;
	isClientBoard: boolean;
	isFavourite: boolean;
	customerBoardId: string;
}

export interface EnrichedBoard extends FinishedBoard, RawBoard {
	dimensionVariants: DimensionVariant[]
}
