import { selectActiveEntity, selectAllEntities } from '@ngneat/elf-entities';
import { selectRequestStatus } from '@ngneat/elf-requests';
import { map } from 'rxjs/operators';

import { outlineSetStore } from './outline-sets.store';

export const outlineSetsSelector = {
	projects$: outlineSetStore.pipe(selectAllEntities()),
	project$: outlineSetStore.pipe(selectActiveEntity()),
	projectsLoading$: outlineSetStore.pipe(
		selectRequestStatus('outline-sets'),
		map((status) => status.value === 'pending')
	)
};
