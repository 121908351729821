export enum PanelType {
	TOP = 'TOP',
	SIDELEFT = 'SIDELEFT',
	SIDERIGHT = 'SIDERIGHT',
	BOTTOM = 'BOTTOM',
	BACK = 'BACK',
	FRAME = 'FRAME',
	FILLER = 'FILLER',
	GENERIC = 'GENERIC',
	DIVIDER = 'DIVIDER',
	BASE_FRONT = 'BASE_FRONT',
	BASE_BACK = 'BASE_BACK',
	DOOR = 'DOOR',
	SHELF = 'SHELVE',
	FILLER_SUPPORT = 'FILLER_SUPPORT',
	FILLER_FRONT = 'FILLER_FRONT',
	FILLER_RECESSED = 'FILLER_RECESSED',
	FILLER_PANEL = 'FILLER_PANEL',
	FRONT = 'FRONT',
	DRAWER_FRONT = 'DRAWER_FRONT',
}

export enum ShapeType {
	RECTANGULAR = 'RECTANGULAR',
	CUTOUT = 'CUTOUT',
	SLOPED = 'SLOPED',
}

export enum BackPanelType {
    MOUNTED = "MOUNTED",
    FLUSH = "FLUSH",
    RECESSED = "RECESSED"
}

export enum ConstructionParameter {
	BASEHEIGHT = 'BASEHEIGHT',
	FRONTBASE_CARCASEOFFSET = 'FRONTBASE_CARCASEOFFSET',
	BACKBASE_CARCASEOFFSET = 'BACKBASE_CARCASEOFFSET',
	BACK_OFFSET = 'BACK_OFFSET',
}

export enum MeasurementUnit {
    MILLIMETRE = "MILLIMETRE",
    CENTIMETRE = "CENTIMETRE",
    SQ_METER = "SQ_METER",
    CUB_METER = "CUB_METER",
    UNIT = "UNIT",
    METER = "METER",
    BOOLEAN = "BOOLEAN",
    MINUTE = "MINUTE",
    HOUR = "HOUR",
    PAIR = "PAIR",
}

export enum DividerType {
	LOGICAL = 'LOGICAL',
	PANEL = 'PANEL',
}

export enum ArticleType {
    CARCASE = "CARCASE",
    BASE = "BASE",
    DRAWERS = "DRAWERS",
    DRAWER = "DRAWER",
    SHELVES = "SHELVES",
    FILLER = "FILLER",
    DIVIDER = "DIVIDER",
    FRONT = "FRONT",
    ROOT = "ROOT",
    BASERAIL = "BASERAIL",
    BASEFRAME = "BASEFRAME",
    BASEFEET = "BASEFEET",
    PLINTH = "PLINTH",
    FRAME = "FRAME",
    SIDEMOUNTED_ROD = "SIDEMOUNTED_ROD",
    SUSPENDED_ROD = "SUSPENDED_ROD",
}

export enum ItemType {
	STANDING_CABINET = 'STANDING_CABINET',
	HANGING_CABINET = 'HANGING_CABINET',
	MODULAR_CABINET = 'MODULAR_CABINET',
	CABINET = 'CABINET',
	BENCH = 'BENCH',
}

export enum BaseType {
	PLYWOOD = 'PLYWOOD',
}

export enum EdgeLocation {
	BACK = 'BACK',
	FRONT = 'FRONT',
	LEFT = 'LEFT',
	RIGHT = 'RIGHT',
	TOP = 'TOP',
	BOTTOM = 'BOTTOM',
}

export enum ConstructionType {
	INSET = 'INSET',
	ONSET = 'ONSET',
	MITRE = 'MITRE',
}

export enum PlaneAxis {
	X = 'X', // Vertical
	Y = 'Y', // Horizontal
	Z = 'Z', // Depth
}

export enum ConnectionType {
    DOWEL_SCREW = "DOWEL_SCREW",
    EXCENTER = "EXCENTER",
    SINGLE_ELBOW_EXCENTER = "SINGLE_ELBOW_EXCENTER",
    DOUBLE_ELBOW_EXCENTER = "DOUBLE_ELBOW_EXCENTER",
    SCREW = "SCREW",
    DOWEL = "DOWEL",
    DOWEL_SUPPORT = "DOWEL_SUPPORT",
    SHELVE_SUPPORT = "SHELVE_SUPPORT",
    SHELVE_SUPPORT_PIN = "SHELVE_SUPPORT_PIN",
    GROOVE = "GROOVE",
    GROOVE_STROKE = "GROOVE_STROKE",
    GROOVE_SCREW = "GROOVE_SCREW",
    GLUE = "GLUE",
    DOWEL_GLUE = "DOWEL_GLUE",
    HINGE = "HINGE",
    HANDLE = "HANDLE"
}

export enum FrontConnection {
	LEFT_HINGED = 'LEFT_HINGED',
	RIGHT_HINGED = 'RIGHT_HINGED',
	BOTTOM_HINGED = 'BOTTOM_HINGED',
	TOP_HINGED = 'TOP_HINGED',
	DOUBLE = 'DOUBLE',
	FIXED = 'FIXED',
	DRAWER = 'DRAWER'
}

export enum DivisionMode {
	INCLUDE_DIVIDER = 'INCLUDE_DIVIDER',
	EXCLUDE_DIVIDER = 'EXCLUDE_DIVIDER',
}

export enum BoundaryMode {
	OUTSIDE_LAYOUT = 'OUTSIDE_LAYOUT',
	INSIDE_LAYOUT = 'INSIDE_LAYOUT',
}

export enum FrontType {
	PANEL = 'PANEL',
	METAL = 'METAL',
	GLASS = 'GLASS',
}

export enum DrawerFronType {
    PANEL = "PANEL",
    METAL = "METAL",
    GLASS = "GLASS",
    GLASS_LOW = "GLASS_LOW",
    RAIL = "RAIL",
}

export enum DrawerDepth {
	D_270 = 270,
	D_300 = 300,
	D_350 = 250,
	D_400 = 400,
	D_450 = 450,
	D_500 = 500,
	D_550 = 550,
	D_600 = 600,
	D_650 = 650
}

export enum SiphonSpaceWidth {
    SMALL_160 = "SMALL_160",  // Corresponds to 160mm net
    WIDE_300 = "WIDE_300"  // Corresponds to 300mm net
}

export enum DrawerHeight {
    N_80 = "N_80",
    M_106 = "M_106",
    K_144 = "K_144",
    C_193 = "C_193",
    F_257 = "F_257",
}

export enum DrawerType {
	INTERNAL = 'INTERNAL',
	EXTERNAL = 'EXTERNAL',
	MIXED = 'MIXED',
}

export enum HandleLocation {
	VLT = "VLT",
    VLC = "VLC",
    VLB = "VLB",
    HLT = "HLT",
    HCT = "HCT",
    HRT = "HRT",
    HLB = "HLB",
    HCB = "HCB",
    HRB = "HRB",
    VRT = "VRT",
    VRC = "VRC",
    VRB = "VRB",
    HCC = "HCC",
    VCC = "VCC"
}

export enum HingeType {
    HINGE_86 = "HINGE_86",
    HINGE_110 = "HINGE_110",
    HINGE_155 = "HINGE_155",
    HINGE_170 = "HINGE_170",
}

export enum DrawerAccess {
    INTERNAL = "INTERNAL",
    EXTERNAL = "EXTERNAL",
    MIXED = "MIXED"
}

export enum FillerType {
    FRONT = "FRONT",
    RECESSED = "RECESSED",
    PANEL = "PANEL",
    PANEL_RECESSED = "PANEL_RECESSED",
    PANEL_STRIP = "PANEL_STRIP",
    PANEL_STRIP_RECESSED = "PANEL_STRIP_RECESSED",
}

export enum DrawerFrontType {
    PANEL = "PANEL",
    METAL = "METAL",
    GLASS = "GLASS",
    GLASS_LOW = "GLASS_LOW",
    RAIL = "RAIL",
}

export enum Colour {
    WHITE = "WHITE",
    BLACK = "BLACK",
    GREY = "GREY",
    BROWN = "BROWN",
    DARK_BROWN = "DARK_BROWN",
    BEIGE = "BEIGE",
    GREEN = "GREEN",
    RED = "RED",
    OTHER = "OTHER",
}

export enum FiberCover {
    LIGHT_GREY = "LIGHT_GREY",
    DARK_GREY = "DARK_GREY",
    BLACK = "BLACK",
}
