export enum PartStatus {
	IN_ITERATION = 'IN_ITERATION',
	READY_FOR_DESIGN = 'READY_FOR_DESIGN',
	DESIGN_IN_PROGRESS = 'DESIGN_IN_PROGRESS',
	READY_FOR_PRODUCTION = 'READY_FOR_PRODUCTION',
	IN_PRODUCTION = 'IN_PRODUCTION',
	PRODUCED = 'PRODUCED',
	READY_FOR_DELIVERY = 'READY_FOR_DELIVERY',
	DELIVERD = 'DELIVERD',
}
