import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ApplicationHttpClient{
	private header = {};
	constructor(private http: HttpClient) {}

	public get<T>(url: string, params?: any): Observable<T> {
		return this.http.get<T>(url, {
			params,
			headers: this.header
		});
	}

	public post<T>(url: string, body: any): Observable<T> {
		return this.http.post<T>(url, body, {
			headers: this.header
		});
	}

	public delete<T>(url: string): Observable<T> {
		return this.http.delete<T>(url, {
			headers: this.header
		});
	}

	public patch<T>(url: string, body: any): Observable<T> {
		return this.http.patch<T>(url, body, {
			headers: this.header
		});
	}

	public put<T>(url: string, body: any): Observable<T> {
		return this.http.put<T>(url, body, {
			headers: this.header
		});
	}
}
