import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InputDialogComponent } from 'src/app/shared/components/input-dialog/input-dialog.component';

@Component({
	selector: 'app-comments-component',
	styleUrls: ['./comments.component.scss'],
	templateUrl: './comments.component.html'
})

export class CommentsComponent {
	@Input() comments: string[] = [];


	constructor(
		private dialog: MatDialog
	) { }

	// Deletes a comment
	deleteComment(index: number) {
		// TODO Implement save delete comment
		this.comments.splice(index, 1);
	}

	// When clicked on "Nieuwe Opmerking", the user gets a popup where they can write there new comment
	addComment() {
		// TODO Implement save add comment
		const dialogRef = this.dialog.open(InputDialogComponent, {
			data: {
				title: 'Opmerking Toevoegen',
				inputs: ['Opmerking'],
				sendButtonText: 'Toevoegen'
			}
		});

		dialogRef.afterClosed().subscribe(
			(data) => {
				if (!data || !data.opmerking) {
					return;
				}
				this.comments.push(data.opmerking);
			}
		);
	}
}
