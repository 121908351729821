import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { CatalogItem, Item, IPanel } from '~shared/types';
import { FrontConnection } from '~shared/enums';
import { CreateItemDto } from '~modules/projects/store/items/items.types';

import { CreateConnectionHardwareDTO, UpdateConnectionDTO, UpdateConnectionHardwareVariantDTO } from './dto/article.dto'

@Injectable()
export class ArticleService {
	articleUrl: string = environment.articleServiceUrl;
	prototypesUrl: string = environment.articleServiceUrl + 'prototypes';

	constructor(private http: HttpClient) {}

	createItem(partId: string, item: CreateItemDto): Observable<Item> {
		return this.http
			.post<Item>(`${this.articleUrl}part/${partId}/item/cabinet`, item);
	}

	duplicateItem(partId: string, itemId: string): Observable<Item> {
		return this.http
			.post<Item>(`${this.articleUrl}part/${partId}/item/${itemId}`, {});
	}

	updateItemDimension(itemId: string, partId: string, dimension): Observable<Item> {
		return this.http
			.patch<Item>(`${this.articleUrl}part/${partId}/item/${itemId}/dimension`, dimension);
	}

	updateItem(itemId: string, partId: string, body): Observable<Item> {
		return this.http
			.patch<Item>(`${this.articleUrl}part/${partId}/item/${itemId}`, body);
	}

	updateItemName(itemId: string, partId: string, name): Observable<Item> {
		return this.http
			.patch<Item>(`${this.articleUrl}part/${partId}/item/${itemId}`, { name });
	}

	updateItemCoordinates(itemId: string, partId: string, coordinates): Observable<Item> {
		return this.http
			.patch<Item>(`${this.articleUrl}part/${partId}/item/${itemId}/coordinate`, coordinates);
	}

	addItemFiller(itemId: string, partId: string, fillerType: string, baseType: string, outlineFaceIdx: number): Observable<Item> {
		return this.http
			.post<Item>(`${this.articleUrl}part/${partId}/item/${itemId}/filler`, {
				fillerType,
				baseType,
				outlineFaceIdx
			});
	}

	removeItemFiller(itemId: string, partId: string, outlineFaceIdx: number): Observable<Item> {
		return this.http
			.delete<Item>(`${this.articleUrl}part/${partId}/item/${itemId}/filler/${outlineFaceIdx}`);
	}

	getItemsByPartIds(partIds: string[]): Observable<Item[]> {
		return this.http
			.get<Item[]>(this.articleUrl + 'item', {
				params: {
					part_id: partIds
				}
			});
	}

	deleteItem(partId: string, itemId: string): Observable<void> {
		const url = this.articleUrl + 'part/' + partId + '/item/' + itemId;
		return this.http
			.delete<void>(url);
	}

	createCatalogItem(partId: string, itemId: string, values, accountId?: string): Observable<CatalogItem> {
		if (accountId) {
			return this.http
				.post<CatalogItem>(`${this.articleUrl}catalog/account/${accountId}/part/${partId}/item/${itemId}`, values);
		}

		return this.http
			.post<CatalogItem>(`${this.articleUrl}catalog/part/${partId}/item/${itemId}`, values);
	}

	updateCatalogItem(catalogItemId: string, values): Observable<CatalogItem> {
		return this.http
			.patch<CatalogItem>(`${this.articleUrl}catalog/${catalogItemId}`, values);
	}

	toggleCatalogItemFavourite(catalogItemId: string, accountId: string): Observable<CatalogItem> {
		return this.http
			.patch<CatalogItem>(`${this.articleUrl}catalog/${catalogItemId}/account/${accountId}/favourite`, {});
	}

	deleteCatalogItem(catalogItemId: string, accountId?: string): Observable<void> {
		if (accountId) {
			return this.http
				.delete<void>(`${this.articleUrl}catalog/${catalogItemId}/account/${accountId}`);
		}

		return this.http
			.delete<void>(`${this.articleUrl}catalog/${catalogItemId}`);
	}

	addCatalogItemToPart(partId: string, catalogItemId: string): Observable<Item> {
		return this.http
			.post<Item>(`${this.articleUrl}part/${partId}/catalog/${catalogItemId}`, {});
	}

	getCatalogItems(accountId: string): Observable<CatalogItem[]> {
		return combineLatest([
			this.http.get<CatalogItem[]>(`${this.articleUrl}catalog/account/${accountId}/itemtype/CABINET`),
			// this.http.get<CatalogItem[]>(`${this.articleUrl}catalog/itemtype/CABINET`),
			of([]),
		])
			.pipe(
				map(([accountCatalogItem, genericCatalogItems]) => [...accountCatalogItem, ...genericCatalogItems])
			);
	}

	getCatalogItem(catalogItemId: string): Observable<CatalogItem> {
		return this.http.get<CatalogItem>(`${this.articleUrl}catalog/${catalogItemId}`);
	}

	createArticleInArticleZone(
		partId: string,
		itemId: string,
		articleZoneId: string,
		articleType: string,
		body
	): Observable<Item> {
		return this.http
			.post<Item>(`${this.articleUrl}part/${partId}/item/${itemId}/articlezone/${articleZoneId}/article/${articleType}`, body);
	}

	createDivider(
		partId: string,
		itemId: string,
		articleZoneId: string,
		body
	): Observable<Item> {
		return this.http
			.post<Item>(`${this.articleUrl}part/${partId}/item/${itemId}/divider/articlezone/${articleZoneId}`, body);
	}

	createShelf(
		partId: string,
		itemId: string,
		articleZoneId: string,
		body
	): Observable<Item> {
		return this.http
			.post<Item>(`${this.articleUrl}part/${partId}/item/${itemId}/shelves/articlezone/${articleZoneId}`, body);
	}

	createDoor(
		partId: string,
		itemId: string,
		body
	): Observable<Item> {
		return this.http
			.post<Item>(`${this.articleUrl}part/${partId}/item/${itemId}/door`, body);
	}

	createPanelCutout(
		partId: string,
		itemId: string,
		articleId: string,
		panelId: string,
		cutoutType: string
	): Observable<Item> {
		return this.http
			.post<Item>(`${this.articleUrl}part/${partId}/item/${itemId}/article/${articleId}/panel/${panelId}/cutout/${cutoutType}`, {});
	}

	deletePanelCutout(
		partId: string,
		itemId: string,
		articleId: string,
		panelId: string,
		cutoutType: string
	): Observable<Item> {
		return this.http
			.delete<Item>(`${this.articleUrl}part/${partId}/item/${itemId}/article/${articleId}/panel/${panelId}/cutout/${cutoutType}`, {});
	}

	updatePanelCutout(
		partId: string,
		itemId: string,
		articleId: string,
		panelId: string,
		cutoutId: string,
		body
	): Observable<Item> {
		return this.http
			.patch<Item>(`${this.articleUrl}part/${partId}/item/${itemId}/article/${articleId}/panel/${panelId}/cutout/${cutoutId}`, body);
	}

	updatePanel(
		partId: string,
		itemId: string,
		panelId: string,
		body
	): Observable<Item> {
		return this.http
			.patch<Item>(`${this.articleUrl}part/${partId}/item/${itemId}/panel/${panelId}`, body);
	}

	updateArticle(
		partId: string,
		itemId: string,
		articleId: string,
		body
	): Observable<Item> {
		return this.http
			.patch<Item>(`${this.articleUrl}part/${partId}/item/${itemId}/article/${articleId}`, body);
	}

	updateHardware(
		partId: string,
		itemId: string,
		hardwareId: string,
		body
	): Observable<Item> {
		return this.http
			.patch<Item>(`${this.articleUrl}part/${partId}/item/${itemId}/hardware/${hardwareId}`, body);
	}

	createConnectionHardware(
		partId: string,
		itemId: string,
		articleId: string,
		panelId: string,
		connectionType: string,
		body: CreateConnectionHardwareDTO
	): Observable<Item> {
		return this.http
			.post<Item>(`${this.articleUrl}part/${partId}/item/${itemId}/article/${articleId}/panel/${panelId}/connection/${connectionType}/hardware`, body);
	}

	updateConnection(
		partId: string,
		itemId: string,
		articleId: string,
		panelId: string,
		connectionType: string,
		body: UpdateConnectionDTO
	): Observable<Item> {
		return this.http
			.patch<Item>(`${this.articleUrl}part/${partId}/item/${itemId}/article/${articleId}/panel/${panelId}/connection/${connectionType}`, body);
	}

	updateConnectionHardwareVariant(
		partId: string,
		itemId: string,
		articleId: string,
		panelId: string,
		connectionType: string,
		body: UpdateConnectionHardwareVariantDTO
	): Observable<Item> {
		return this.http
			.patch<Item>(`${this.articleUrl}part/${partId}/item/${itemId}/article/${articleId}/panel/${panelId}/connection/${connectionType}/hardware/variant`, body);
	}

	updateDivider(
		partId: string,
		itemId: string,
		articleId: string,
		body
	): Observable<Item> {
		return this.http
			.patch<Item>(`${this.articleUrl}part/${partId}/item/${itemId}/divider/${articleId}`, body);
	}

	updateShelf(
		partId: string,
		itemId: string,
		articleId: string,
		body
	): Observable<Item> {
		return this.http
			.patch<Item>(`${this.articleUrl}part/${partId}/item/${itemId}/shelves/${articleId}`, body);
	}

	updatePanelParameter(
		partId: string,
		itemId: string,
		articleId: string,
		panelId: string,
		body
	): Observable<Item> {
		return this.http
			.patch<Item>(`${this.articleUrl}part/${partId}/item/${itemId}/article/${articleId}/panel/${panelId}/parameter`, body);
	}

	updateArticleParameter(
		partId: string,
		itemId: string,
		articleId: string,
		body
	): Observable<Item> {
		return this.http
			.patch<Item>(`${this.articleUrl}part/${partId}/item/${itemId}/article/${articleId}/parameter`, body);
	}

	updateFillerConstruction(
		partId: string,
		itemId: string,
		outlineFaceIdx: number,
		body
	): Observable<Item> {
		return this.http
			.patch<Item>(`${this.articleUrl}part/${partId}/item/${itemId}/filler/${outlineFaceIdx}`, body);
	}

	deleteArticle(
		partId: string,
		itemId: string,
		articleId: string
	): Observable<Item> {
		return this.http
			.delete<Item>(`${this.articleUrl}part/${partId}/item/${itemId}/article/${articleId}`);
	}

	deletePanel(
		partId: string,
		itemId: string,
		panelId: string
	): Observable<Item> {
		return this.http
			.delete<Item>(`${this.articleUrl}part/${partId}/item/${itemId}/panel/${panelId}`);
	}

	addHardwareToPanel(
		partId: string,
		itemId: string,
		articleId: string,
		panelId: string,
		body
	): Observable<Item> {
		return this.http
			.post<Item>(`${this.articleUrl}part/${partId}/item/${itemId}/article/${articleId}/panel/${panelId}/connection/HANDLE/hardware`, body);
	}

	updatePanelConnection(
		partId: string,
		itemId: string,
		articleId: string,
		panelId: string,
		connectionType: string,
		body
	): Observable<Item> {
		return this.http
			.patch<Item>(`${this.articleUrl}part/${partId}/item/${itemId}/article/${articleId}/panel/${panelId}/connection/${connectionType}`, body);
	}

	deleteHardwareFromPanel(
		partId: string,
		itemId: string,
		articleId: string,
		panelId: string,
	): Observable<Item> {
		return this.http
			.delete<Item>(`${this.articleUrl}part/${partId}/item/${itemId}/article/${articleId}/panel/${panelId}/connection/HANDLE`, {});
	}

	public addCustomisationToPanel(
		partId: string,
		itemId: string,
		panelId: string,
		customisationId: string
	): Observable<Item> {
		return this.http
			.post<Item>(`${this.articleUrl}part/${partId}/item/${itemId}/panel/${panelId}/customisation/${customisationId}`, {});
	}

	public addCustomisationToLayout(
		partId: string,
		itemId: string,
		articleZoneId: string,
		customisationId: string
	): Observable<Item> {
		return this.http
			.post<Item>(`${this.articleUrl}part/${partId}/item/${itemId}/articlezone/${articleZoneId}/customisation/${customisationId}`, {});
	}

	public addCustomisationToItem(
		partId: string,
		itemId: string,
		customisationId: string
	): Observable<Item> {
		return this.http
			.post<Item>(`${this.articleUrl}part/${partId}/item/${itemId}/customisation/${customisationId}`, {});
	}

	public deleteCustomisationFromPanel(
		partId: string,
		itemId: string,
		panelId: string,
		customisationId: string
	): Observable<Item> {
		return this.http
			.delete<Item>(`${this.articleUrl}part/${partId}/item/${itemId}/panel/${panelId}/customisation/${customisationId}`, {});
	}

	public deleteCustomisationFromLayout(
		partId: string,
		itemId: string,
		articleZoneId: string,
		customisationId: string
	): Observable<Item> {
		return this.http
			.delete<Item>(`${this.articleUrl}part/${partId}/item/${itemId}/articlezone/${articleZoneId}/customisation/${customisationId}`, {});
	}

	public deleteCustomisationFromItem(
		partId: string,
		itemId: string,
		customisationId: string
	): Observable<Item> {
		return this.http
			.delete<Item>(`${this.articleUrl}part/${partId}/item/${itemId}/customisation/${customisationId}`, {});
	}

	addDoor(
		partId: string,
		itemId: string,
		frontConnection: FrontConnection,
		articleZoneIds: Array<string>
	): Observable<Item> {
		const url =
			this.articleUrl + 'part/' + partId + '/item/' + itemId + '/door';
		const body = {
			articleZoneIds,
			frontConnection,
		};
		return this.http
			.post<Item>(url, body);
	}

	editItem(item: Item): Observable<Item> {
		const body = {
			id: item.id,
			name: item.name,
			partId: item.partId,
			// prototypeId: item.prototypeLabel,
			// dimension: item.dimension,
		};

		return this.http
			.put<Item>(this.articleUrl + '/' + item.id, body);
	}

	public updatePanelGroupBoard(partId: string, itemId: string, panelGroup: string, body, panelLabel?: string): Observable<Item> {
		return this.http
			.patch<Item>(panelLabel ? `${this.articleUrl}part/${partId}/item/${itemId}/board/panelgroup/${panelGroup}/${panelLabel}` : `${this.articleUrl}part/${partId}/item/${itemId}/board/panelgroup/${panelGroup}`, body);
	}

	public recreateBack(partId: string, itemId: string, articleZoneId: string, faceIdx: number): Observable<Item> {
		return this.http
			.patch<Item>(`${this.articleUrl}part/${partId}/item/${itemId}/articlezone/${articleZoneId}/faceidx/${faceIdx}/recreate`, {});
	}

	public updatePartPanelGroupBoard(partId: string, panelGroup: string, body, panelLabel?: string): Observable<Item[]> {
		return this.http
			.patch<Item[]>(panelLabel ? `${this.articleUrl}part/${partId}/board/panelgroup/${panelGroup}/${panelLabel}` : `${this.articleUrl}part/${partId}/board/panelgroup/${panelGroup}`, body);
	}

	public updatePanelBoard(partId: string, itemId: string, articleId: string, panelId: string, body): Observable<Item> {
		return this.http
			.patch<Item>(`${this.articleUrl}part/${partId}/item/${itemId}/article/${articleId}/panel/${panelId}/board`, body);
	}
}
