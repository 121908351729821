import { OutlineType } from '../enums/outline-type.enum'

import { DimensionField } from './dimension.types';

export class Outline {
	dataType?: string;
	label: string;
	outlineType?: OutlineType;
	name?: string;
	iconFileName?: string;
	description?: string;
	dimensions?: DimensionField[];
	available?: boolean;
}

export class OutlineDimension {
	label: string
}

export class OutlineSet{
	dataType: string;
	label: string;
	outlineSetType: string;
	frontOutline: Outline | string;
	sideOutline: Outline | string;
	topOutline: Outline | string;
}
