import { createStore, withProps } from '@ngneat/elf';
import { withActiveId, withEntities } from '@ngneat/elf-entities';
import { withRequestsStatus } from '@ngneat/elf-requests';

import { EnrichedBoard, FinishedBoard, Item, RawBoard } from '~shared/types';

export const boardStore = createStore(
	{ name: 'boards' },
	withEntities<EnrichedBoard | RawBoard | FinishedBoard>(),
	withActiveId(),
	withRequestsStatus<string>()
);
