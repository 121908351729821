import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JsonReaderService {
  startPrData;
  projectData;
  storageData;

  constructor(private http: HttpClient) {
  }
  public getMaterialsJson(): Observable<any> {
    return this.http.get('./assets/json/material-test.json');
  }
}
