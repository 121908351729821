import { select } from '@ngneat/elf';

import { editorStore } from './editor.store';

export const editorSelector = {
	allConfig$: editorStore.pipe(select((state) => state)),
	activeConfigurationPanel$: editorStore.pipe(select((state) => state.activeConfigurationPanel)),
	selectedItems$: editorStore.pipe(select((state) => state.selectedItems)),
	outlineMap$: editorStore.pipe(select((state) => state.outlineMap)),
	dimensions$: editorStore.pipe(select((state) => state.dimensions)),
	loading$: editorStore.pipe(select((state) => state.loading)),
	locked$: editorStore.pipe(select((state) => state.locked)),
	isolatedItem$: editorStore.pipe(select((state) => state.isolatedItem)),
	transparentPanelTypes$: editorStore.pipe(select((state) => state.transparentPanelTypes)),
	designerMode$: editorStore.pipe(select((state) => state.designerMode)),
	partEditorFullscreen$: editorStore.pipe(select((state) => state.partEditorFullscreen)),
	partEditorSelectedItem$: editorStore.pipe(select((state) => state.partEditorSelectedItem)),
	fillerHighlight$: editorStore.pipe(select((state) => state.fillerHighlight)),
	openDoorIds$: editorStore.pipe(select((state) => state.openDoorIds)),
	selectedFaceIdx$: editorStore.pipe(select((state) => state.selectedFaceIdx)),
	mode$: editorStore.pipe(select((state) => state.mode)),
};
