export const CountryOptions = [
	{
		label: 'België',
		value: 'BE'
	},
	{
		label: 'Nederland',
		value: 'NL'
	},
	{
		label: 'Frankrijk',
		value: 'FR'
	},
	{
		label: 'Luxemburg',
		value: 'BELUX'
	},
	{
		label: 'Duitsland',
		value: 'DE'
	},
	{
		label: 'Verenigd Koninkrijk',
		value: 'UK'
	},
];

export const CompanyClassOptions = [
	{
		label: 'Besloten vennootschap',
		value: 'BV'
	},
	{
		label: 'Naamloze vennootschap',
		value: 'NV'
	},
	{
		label: 'Vennootschap onder firma',
		value: 'VOF'
	},
	{
		label: 'Eenmanszaak',
		value: 'EZ'
	},
	{
		label: 'Vereniging zonder winstoogmerk',
		value: 'VZW'
	},
];

export const ExternalUserRoleOptions = [
	{
		label: 'Administrator',
		value: 'ACCOUNT_ADMIN',
		icon: 'admin'
	},
	{
		label: 'Medewerker',
		value: 'ACCOUNT_USER',
		icon: 'worker'
	},
];

export const InternalUserRoleOptions = [
	{
		label: 'Sales',
		value: 'SALES',
		icon: 'worker'
	},
	{
		label: 'Designer',
		value: 'DESIGNER',
		icon: 'designer'
	},
	{
		label: 'Administrator',
		value: 'ADMIN',
		icon: 'admin'
	},
];

export const ColourMap = {
	'white': 'FFFFFF',
	'black': '171717',
	'grey': '808080',
	'brown': '964B00',
	'dark_brown': '5C4033',
	'beige': 'E1C699',
	'green': '00FF00',
	'red': 'FF0000'
}

export const EDITOR_STATE_COLOURS = {
	ARTICLE_ZONE_SELECTION_CLICKABLE_ZONES: '#FF0000',
	ARTICLE_ZONE_SELECTION_SELECTED_ZONES: '#8800ff'
}
