import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Amplify } from 'aws-amplify';

import { environment } from "../../../environments/environment";

@Injectable()
export class ConfigService {
	private _config: Object;

	constructor(private http: HttpClient) {
	}

	public load() {
		Amplify.configure({
			Auth: {
				mandatorySignIn: true,
				region: environment.cognito.REGION,
				userPoolId: environment.cognito.USER_POOL_ID,
				identityPoolId: environment.cognito.IDENTITY_POOL_ID,
				userPoolWebClientId: environment.cognito.APP_CLIENT_ID
			},
			API: {
				endpoints: [
				]
			}
		});
	}

}
