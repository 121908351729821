import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, map, takeUntil } from 'rxjs';

import { EditorRepository } from '~modules/projects/store/editor/editor.repository';

import { GenericItem } from '../cabinet-builder/engine-render.service';

@Component({
	selector: 'app-shared-construction-parameters',
	templateUrl: './shared-construction-parameters.component.html'
})
export class SharedConstructionParametersComponent implements OnInit, OnDestroy {
	private componentDestroyed$: Subject<boolean> = new Subject<boolean>();

	public selectedItem$: Observable<GenericItem>;

	constructor (
		private readonly editorRepository: EditorRepository,
	) {}

	public ngOnInit(): void {
		this.selectedItem$ = this.editorRepository.selectedItems$
			.pipe(
				takeUntil(this.componentDestroyed$),
				map(([item]) => item)
			)
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}
}
