import { Injectable } from '@angular/core';
import { setProps } from '@ngneat/elf';

import { PartPosition } from '~shared/shared.types';

import { partEditorStore, partEditorStoreHistory } from './part-editor.store';
import { partEditorSelector } from './part-editor.selectors';

@Injectable()
export class PartEditorRepository {
	public itemPositions$ = partEditorSelector.itemPositions$;
	public hasFuture$ = partEditorStoreHistory.hasFuture$;
	public hasPast$ = partEditorStoreHistory.hasPast$;

	public setItemPositions(itemPositions: PartPosition[]): void {
		partEditorStore.update(
			setProps({
				itemPositions
			})
		);
	}

	public undo(): void {
		partEditorStoreHistory.undo();
	}

	public redo(): void {
		partEditorStoreHistory.redo();
	}

	public clearHistory(): void {
		partEditorStoreHistory.clear();
	}
}
