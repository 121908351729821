import {
	Component,
	OnInit,
} from '@angular/core';
import { map, Observable, switchMap } from 'rxjs';

import { OutlineType } from '~shared/enums';
import { Outline } from '~shared/types';
import { OutlineSetsRepository } from '~modules/projects/store/outline-sets/outline-sets.repository';
import { EditorRepository } from '~modules/projects/store/editor/editor.repository';

@Component({
	selector: 'app-outline-top',
	templateUrl: './outline-top.component.html'
})
export class OutlineTopComponent implements OnInit {
	public filteredOutlineMap$: Observable<Outline[]>;

	constructor(
		private outlineSetsRepository: OutlineSetsRepository,
		private editorRepository: EditorRepository
	) {}

	ngOnInit(): void {
		this.filteredOutlineMap$ = this.editorRepository.outlineMap$
			.pipe(
				switchMap((outlineMap) => {
					return this.outlineSetsRepository.getFilteredOutlineMap(
						outlineMap[OutlineType.FRONT],
						outlineMap[OutlineType.SIDE],
						outlineMap[OutlineType.TOP]
					)
				}),
				map((outlineMap) => outlineMap.outlinesetsMap[OutlineType.TOP])
			)
	}

	public selectOutline(outline: Outline): void {
		this.editorRepository.setOutline(OutlineType.TOP, outline)
	}
}
