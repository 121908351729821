import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { filter, Subject, take, takeUntil } from "rxjs";

import { AuthService } from "~core/services/auth.service";
import { AccountType } from "~modules/auth/types/account.types";

@Directive({
	selector: '[appAccountType]',
})
export class AccountTypeDirective implements OnInit, OnDestroy {
	@Input() appAccountType: AccountType;

	private directiveDestroyed$: Subject<boolean> = new Subject();

	constructor(
		private readonly templateRef: TemplateRef<unknown>,
        private readonly viewContainer: ViewContainerRef,
		private readonly authService: AuthService
	) {}

	public ngOnInit(): void {
		this.viewContainer.clear();

		this.authService.currentUser$
			.pipe(
				filter((user) => !!user),
				take(1)
			)
			.subscribe((user) => {
				this.viewContainer.clear();

				if (this.appAccountType === (user?.account?.company ? AccountType.PROFESSIONAL : AccountType.INDIVIDUAL)) {
					return this.viewContainer.createEmbeddedView(this.templateRef);
				}

				this.viewContainer.clear();
			})
	}

	public ngOnDestroy(): void {
		this.directiveDestroyed$.next(true);
		this.directiveDestroyed$.complete()
	}
}
