import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { combineLatest, filter, fromEvent, Observable, startWith, tap, debounceTime } from 'rxjs';

import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { AppRepository } from '~shared/store';

import { AuthService } from '../../services/auth.service';
import { ExternalUser, InternalUser, UserType } from '../../../modules/auth/types/user.types';


@Component({
	templateUrl: './wrapper.component.html',
})
export class WrapperComponent implements OnInit {
	public loading$: Observable<boolean>;
	public loading: boolean;
	public menuItems = [];

	public disableNavigation = false;

	constructor(
		private readonly authService: AuthService,
		private readonly editorRepository: EditorRepository,
		private readonly appRepository: AppRepository,
		private readonly router: Router,
		private readonly route: ActivatedRoute,
	) {}

	async ngOnInit(): Promise<void> {
		this.authService.loading$
			.subscribe((loading) => this.editorRepository.setLoading(loading));

		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd)
			)
			.subscribe(() => {
				this.disableNavigation = !!this.route.snapshot.firstChild?.data?.disableNavigation;
			})

		this.loading$ = this.appRepository.loading$;

		this.loading$
			.pipe(
				filter((isLoading) => !!isLoading),
				debounceTime(10_000),
				tap(() => this.appRepository.setLoading(false))
			)
			.subscribe()

		combineLatest([
			this.authService.currentUser$,
			// this.router.events.pipe(
			// 	startWith(null),
			// 	filter((router) => !router || router instanceof NavigationEnd)
			// ),
			fromEvent(window, 'storage').pipe(
				startWith(null)
			)
		])
			.pipe(
				tap(([user, _]: [InternalUser | ExternalUser, any]) => {
					if (!user || !user.registered) {
						return;
					}

					if (user?.userType === UserType.EXTERNAL) {
						return this.menuItems = [
							{
								icon: 'home',
								to: '/projects'
							},
							!localStorage.getItem('lastVisitedProject') ? {
								icon: 'configurator',
								to: '/projects/create',
								queryParams: {
									autoCreate: true
								}
							} : {
								icon: 'configurator',
								to: `/projects/${localStorage.getItem('lastVisitedProject')}/editor`,
							},
							{
								icon: 'wood',
								to: '/materials',
							}
						]
					}

					this.menuItems = [
						{
							icon: 'home',
							to: '/projects'
						},
						{
							icon: 'wood',
							to: '/materials',
						},
					]
				}))
			.subscribe();
	}
}
