import { Component, Inject, EventEmitter } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface StandardDialogData {
    title: string;
    body: string;
	type: string;
	icon?: string;
    buttons?: Button[]
}

export class Button {
	name: string;
	type?: string;
	action?: string;

	constructor(name: string, action?: string, type = 'default') {
		this.name = name;
		this.type = type;
		this.action = action;
	}
}

@Component({
	templateUrl: "./standard-dialog.component.html"
})
export class StandardDialogComponent{
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: StandardDialogData,
		public dialogRef: MatDialogRef<StandardDialogComponent>
	){}
}
