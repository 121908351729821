import { Injectable } from '@angular/core';
import { updateRequestStatus } from '@ngneat/elf-requests';
import { map, take } from 'rxjs/operators';
import { upsertEntities} from '@ngneat/elf-entities';
import { Observable } from 'rxjs';

import { OutlineService } from '~core/services/outline.service';
import { Outline, OutlineSet } from '~shared/types';
import { OutlineType } from '~shared/enums';

import { outlineSetStore } from './outline-sets.store';
import { outlineSetsSelector } from './outline-sets.selectors';

interface FilteredOutlineSet {
	prototypeLabel: string;
	outlinesetsMap: Record<OutlineType, (Outline)[]>;
}

@Injectable()
export class OutlineSetsRepository {
	public outlineSets$ = outlineSetsSelector.projects$;
	public project$ = outlineSetsSelector.project$;
	public projectsLoading$ = outlineSetsSelector.projectsLoading$;

	constructor(private readonly outlineService: OutlineService) {}

	public fetchOutlineSets(): void {
		outlineSetStore.update(updateRequestStatus('outline-sets', 'pending'));

		if (outlineSetStore.getValue().ids.length) {
			return;
		}

		this.outlineService
			.getOutlineSets()
			.pipe(take(1))
			.subscribe((outlineSets) =>
				outlineSetStore.update(
					upsertEntities(outlineSets),
					updateRequestStatus('outline-sets', 'success')
				)
			);
	}

	public getFilteredOutlines(
		frontOutline: Outline = null,
		sideOutline: Outline = null,
		topOutline: Outline = null
	): Observable<OutlineSet[]> {
		return this.outlineSets$.pipe(
			map((outlineSets) => {
				// if (frontOutline) {
				// 	outlineSets.splice(
				// 		0,
				// 		outlineSets.length,
				// 		...outlineSets.filter(
				// 			(outlineSet) =>
				// 				outlineSet.frontOutline.label ===
				// 				frontOutline.label
				// 		)
				// 	);
				// }

				// if (sideOutline) {
				// 	outlineSets.splice(
				// 		0,
				// 		outlineSets.length,
				// 		...outlineSets.filter(
				// 			(outlineSet) =>
				// 				outlineSet.sideOutline.label ===
				// 				sideOutline.label
				// 		)
				// 	);
				// }

				// if (topOutline) {
				// 	outlineSets.splice(
				// 		0,
				// 		outlineSets.length,
				// 		...outlineSets.filter(
				// 			(outlineSet) =>
				// 				outlineSet.topOutline.label === topOutline.label
				// 		)
				// 	);
				// }

				return outlineSets.filter((outlineSet) => {
					if (frontOutline && frontOutline.label === (outlineSet.frontOutline as Outline).label) {
						return true
					}

					if (sideOutline && sideOutline.label === (outlineSet.sideOutline as Outline).label) {
						return true
					}

					if (topOutline && topOutline.label === (outlineSet.topOutline as Outline).label) {
						return true
					}

					return false;
				});
			})
		);
	}

	public getFilteredOutlineMap(
		frontOutline: Outline = null,
		sideOutline: Outline = null,
		topOutline: Outline = null
	): Observable<FilteredOutlineSet> {
		return this.getFilteredOutlines(
			frontOutline,
			sideOutline,
			topOutline
		).pipe(
			map((outlinesets) => {
				const frontOutlines: Outline[] = outlinesets
					.map((outlineSet) => this.mapOutlineSet(outlineSet, 'frontOutline'))
					.reduce((acc, outlineSet) => this.filterDuplicateOutlineSet(acc, outlineSet), []);
				const sideOutlines: Outline[] = outlinesets
					.map((outlineSet) => this.mapOutlineSet(outlineSet, 'sideOutline'))
					.reduce((acc, outlineSet) => this.filterDuplicateOutlineSet(acc, outlineSet), []);
				const topOutlines: Outline[] = outlinesets
					.map((outlineSet) => this.mapOutlineSet(outlineSet, 'topOutline'))
					.reduce((acc, outlineSet) => this.filterDuplicateOutlineSet(acc, outlineSet), []);

				// const frontOutlineLabels: Set<string> = new Set(
				// 	outlinesets.map(
				// 		// (outlineSet) => outlineSet.frontOutline.label
				// 		(outlineSet) => outlineSet.frontOutline
				// 	)
				// );

				// const sideOutlineLabels: Set<string> = new Set(
				// 	outlinesets.map(
				// 		(outlineSet) => outlineSet.sideOutline.label
				// 	)
				// );

				// const topOutlineLabels: Set<string> = new Set(
				// 	outlinesets.map((outlineSet) => outlineSet.topOutline.label)
				// );

				return {
					prototypeLabel: outlinesets.length === 1 ? outlinesets[0].label : null	,
					outlinesetsMap: {
						// [OutlineType.FRONT]: [...frontOutlineLabels].map((label) => frontOutlines.find((outline) => outline.label === label)),
						// [OutlineType.SIDE]: [...sideOutlineLabels].map((label) => sideOutlines.find((outline) => outline.label === label)),
						// [OutlineType.TOP]: [...topOutlineLabels].map((label) => topOutlines.find((outline) => outline.label === label))
						[OutlineType.FRONT]: [...frontOutlines],
						[OutlineType.SIDE]: [...sideOutlines],
						[OutlineType.TOP]: [...topOutlines]
					}
				};
			})
		);
	}

	public getOutlineSetByPrototypeLabel(
		frontOutline: string,
		sideOutline: string,
		topOutline: string,
	): Observable<OutlineSet> {
		if (!frontOutline || !sideOutline || !topOutline) {
			throw new Error(
				'prototypeLabel is undefined when selecting the corresponding OutlineSet.'
			);
		}

		return this.outlineSets$.pipe(
			map((outlineSets) => {
				return outlineSets.find(
					(outlineSet) => outlineSet.label === `${frontOutline}_${topOutline}_${sideOutline}`
				)
			})
		);
	}

	private mapOutlineSet(outlineSet: OutlineSet, key: string): Outline {
		if (typeof outlineSet[key] === 'string') {
			return {
				label: outlineSet[key],
				available: false,
			}
		}

		return {
			...outlineSet[key],
			available: true,
		}
	}

	private filterDuplicateOutlineSet(acc, outlineSet: Outline): Outline[] {
		if (acc.find((accOutlineSet) => accOutlineSet.label === outlineSet.label)) {
			return acc;
		}

		return [
			...acc,
			outlineSet
		]
	}
}
