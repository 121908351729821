import { HeaderComponent } from './header/header.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { AccountSwitcherComponent } from './account-switcher/account-switcher.component';
import { PdfPageComponent } from './pdf-page/pdf-page.component';

export const Components = [
	HeaderComponent,
	UserInfoComponent,
	PdfPageComponent,
	AccountSwitcherComponent,
];
