import { HardwareType } from "~shared/enums";
import { IPolyhedron } from "~shared/types/polyhedron.types";

import { ArticleType, BaseType, Colour, ConstructionType, DividerType, DrawerAccess, DrawerDepth, DrawerFrontType, DrawerHeight, FiberCover, FillerType, SiphonSpaceWidth } from "../enums/item.enum";

import { IParameterSet } from "./parameter.types";
import { IConstraint } from "./constraint.types";
import { IPanel } from "./panel.types";
import { LinearDivision } from "./formula.types";
import { HardwareItemObject, HardwareItemVariant } from "./hardware.types";

interface ArticleHardwareCatalogItem {
	hardwareType: HardwareType;
	name: string;
	label: string;
	description: string;
	variantList: HardwareItemVariant[];
	obj: HardwareItemObject;
}

interface ArticleHardObjPosition {
	objLabel: string;
	rotation: {
		x: string;
		y: string;
		z: string;
	};
	position: {
		x: string;
		y: string;
		z: string;
	};
	scale: {
		x: string;
		y: string;
		z: string;
	};
}

export interface IArticleHardware {
	sku: string;
	catalogItem: ArticleHardwareCatalogItem;
	variant: HardwareItemVariant;
	objPositions: ArticleHardObjPosition[];
}

export interface IConstructionTypeLocation {
	topConstructionType?: ConstructionType;
	topConstructionTypeOptions?: ConstructionType[];
	bottomConstructionType?: ConstructionType;
	bottomConstructionTypeOptions?: ConstructionType[];
	leftConstructionType?: ConstructionType;
	leftConstructionTypeOptions?: ConstructionType[];
	rightConstructionType?: ConstructionType;
	rightConstructionTypeOptions?: ConstructionType[];
}

export interface ISiphonSpace {
	width: SiphonSpaceWidth;
	offsetLeft: number;
}

export interface IArticle {
	id?: string;
	removable?: boolean;
	articleType: ArticleType;
	parameterSet?: IParameterSet;
	constraint?: IConstraint;
	panels?: IPanel[];
	polyhedra?: IPolyhedron[];
	dividerType?: DividerType;
	linearDivision?: LinearDivision;
	customisations?: string[];
	lineBoring?: boolean;
	adjustmentLevels?: number;
	fillerType?: FillerType;
	outlineFaceIdx?: number;
	outlineFaceNormal?: boolean;
	base?: IArticle;
	frameColour?: Colour;
	parentArticleId?: string;
	baseType?: BaseType;
	construction?: IConstructionTypeLocation;
	hardware?: IArticleHardware[];
	frontTypeOptions?: DrawerFrontType[];
	frontType?: DrawerFrontType;
	matColour?: FiberCover;
	siphonSpace?: ISiphonSpace;
	tipOn?: boolean;
	heightOptions?: DrawerHeight[];
	depthOptions?: DrawerDepth[];
	weightClassOptions?: unknown[];
	stackHeight?: number;
	drawerAccess?: DrawerAccess;
	drawerCardinality?: number;
	depth?: DrawerDepth;
	height?: DrawerHeight;
}
