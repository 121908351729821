import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class ExpressionValidators {
	public static checkExpression(): (AbstractControl) => ValidationErrors | null {
		return (control: AbstractControl): ValidationErrors | null => {
			const regex = /(?=(^([0-9]{1,2}|[0-9]{1,4}mm):))|(?=(:([0-9]{1,2}|[0-9]{1,4}mm):))|(?=(:([0-9]{1,2}|[0-9]{1,4}mm)$))/g;

			const matchedAmount = control.value?.match(regex)?.length || 0;
			const totalAmount = control.value?.split(':')?.length || 0;

			return matchedAmount !== totalAmount
				? { expressionIncorrect: true }
				: null;
		};
	}
}
