import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';

@Component({
	selector: 'app-tel-input',
	templateUrl: './tel-input.component.html',
	providers: [
		{
			provide: [],
			useExisting: forwardRef(() => TelInputComponent),
			multi: true,
		},
	],
})
export class TelInputComponent implements OnInit, OnDestroy, ControlValueAccessor {
	@Input() label?: string;
	@Input() placeholder = '';
	@Input() type = 'text';
	@Input() disabled = false;
	@Input() icon?: string;
	@Input() name?: string;

	private componentDestroyed$: Subject<boolean> = new Subject<boolean>();

	public control: UntypedFormControl = new UntypedFormControl('');
	public updateValue = (_: any) => {};
	public onTouched = () => {};

	constructor(public ngControl: NgControl) {
		ngControl.valueAccessor = this;
	}

	private propagateChange(value: any): void {
		if (this.updateValue) {
			return this.updateValue(value);
		}

		if (this.control) {
			this.control.setValue(value);
		}
	}

	public ngOnInit(): void {
		if (this.disabled) {
			this.control.disable();
		}

		this.control.valueChanges.pipe(
			takeUntil(this.componentDestroyed$),
		).subscribe((value) => {
			this.propagateChange(value);
		});
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}

	public writeValue(value: any): void {
		setTimeout(() => this.control.setValue(value));
	}

	public registerOnChange(fn: any): void {
		this.updateValue = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}
}
