import { CreateComponent } from './create/create.component';
import { ProjectsComponent } from './projects/projects.component';
import { EditorComponent } from './editor/editor.component';
import { DetailComponent } from './detail/detail.component';
import { CabinetBuilderComponent } from './cabinet-builder/cabinet-builder.page';

export { CreateComponent } from './create/create.component';
export { ProjectsComponent } from './projects/projects.component';
export { EditorComponent } from './editor/editor.component';
export { DetailComponent } from './detail/detail.component';
export { CabinetBuilderComponent } from './cabinet-builder/cabinet-builder.page';

export const Pages = [
	ProjectsComponent,
	CreateComponent,
	EditorComponent,
	DetailComponent,
	CabinetBuilderComponent
];
