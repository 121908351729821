import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { SharedModule } from '../shared/shared.module';

import { ArticleService } from './services/article.service';
import { OutlineService } from './services/outline.service';
import { ProjectService } from './services/project.service';
import { CuttingListService } from './services/cutting-list.service';
import { StaticDataService } from './services/static-data.service';
import { HardwareService } from './services/hardware.service';
import { CustomisationService } from './services/customisation.service';
import { CoreRoutingModule } from './core-routing.module';
import { ApplicationHttpClient } from './services/application.httpClient';
import { Components } from './components';
import { Pages } from './pages';

@NgModule({
	declarations: [Components, Pages],
	imports: [
		CommonModule,
		SharedModule,
		CoreRoutingModule,
		ReactiveFormsModule,
	],
	exports: [Components],
	providers: [
		ApplicationHttpClient,
		ArticleService,
		OutlineService,
		ProjectService,
		CustomisationService,
		CuttingListService,
		StaticDataService,
		HardwareService,
		// {
		// 	provide: HTTP_INTERCEPTORS,
		// 	useClass: AddHeaderInterceptor,
		// 	multi: true,
		// }
	],
})
export class CoreModule {}
