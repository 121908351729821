import { Injectable } from '@angular/core';
import { updateRequestStatus } from '@ngneat/elf-requests';
import { map, switchMap, take, tap } from 'rxjs/operators';
import {deleteEntities, setActiveId, setEntities, upsertEntities } from '@ngneat/elf-entities';
import { combineLatest, Observable, of } from 'rxjs';

import {CuttingList } from '~shared/types';
import { CuttingListService } from '~core/services/cutting-list.service';

import { cuttingListStore } from './cutting-lists.store';
import { cuttingListsSelector } from './cutting-lists.selectors';

@Injectable()
export class CuttingListsRepository {
	public cuttingLists$ = cuttingListsSelector.cuttingLists$;
	public cuttingListsLoading$ = cuttingListsSelector.cuttingListsLoading$;
	public activeCuttingList$ = cuttingListsSelector.activeCuttingList$;

	constructor(
		private readonly cuttingListService: CuttingListService
	) {}

	public getAll(projectId: string): Observable<CuttingList[]> {
		cuttingListStore.update(updateRequestStatus('get-cutting-lists', 'pending'));
		return this.cuttingListService
			.getCuttingLists(projectId)
			.pipe(
				take(1),
				switchMap((cuttingLists: CuttingList[]) => {
					if (cuttingLists.length === 0) {
						return of([{
							id: 'temp',
							projectId: projectId,
							name: 'Zaaglijst 001',
							amountOfLines: 0,
							project: null,
						}]);
					}

					return combineLatest(cuttingLists.map((cuttingList) => {
						return this.cuttingListService.getCuttingListLines(cuttingList.id).pipe(take(1), map((cuttingLists) => ({
							...cuttingList,
							amountOfLines: cuttingLists.length,
						})))
					}))
				}),
				tap((cuttingLists) => {
					cuttingListStore.update(
						setEntities(cuttingLists),
						setActiveId(cuttingLists?.[0]?.id),
						updateRequestStatus('get-cutting-lists', 'success')
					)
				})
			)
	}

	public create(projectId: string, body): Observable<CuttingList> {
		cuttingListStore.update(updateRequestStatus('create-cutting-list', 'pending'));
		return this.cuttingListService
			.createCuttingList(projectId, body)
			.pipe(
				take(1),
				tap((cuttingList) => {
					cuttingListStore.update(
						deleteEntities('temp'),
						upsertEntities(cuttingList),
						updateRequestStatus('create-cutting-list', 'success')
					)
				})
			)
	}

	public update(projectId: string, cuttingListId: string, body): Observable<CuttingList> {
		cuttingListStore.update(updateRequestStatus('update-cutting-list', 'pending'));
		return this.cuttingListService
			.updateCuttingList(projectId, cuttingListId, body)
			.pipe(
				take(1),
				tap((cuttingList) => {
					cuttingListStore.update(
						upsertEntities(cuttingList),
						updateRequestStatus('update-cutting-list', 'success')
					)
				})
			)
	}

	public duplicate(projectId: string, cuttingListId: string): Observable<CuttingList> {
		cuttingListStore.update(updateRequestStatus('duplicate-cutting-list', 'pending'));
		return this.cuttingListService
			.duplicateCuttingList(projectId, cuttingListId)
			.pipe(
				take(1),
				tap((cuttingList) => {
					cuttingListStore.update(
						upsertEntities(cuttingList),
						updateRequestStatus('duplicate-cutting-list', 'success')
					)
				})
			)
	}

	public delete(projectId: string, cuttingListId: string): Observable<void> {
		cuttingListStore.update(updateRequestStatus('delete-cutting-list', 'pending'));
		return this.cuttingListService
			.deleteCuttingList(projectId, cuttingListId)
			.pipe(
				take(1),
				tap(() => {
					cuttingListStore.update(
						deleteEntities(cuttingListId),
						updateRequestStatus('delete-cutting-list', 'success')
					)
				})
			)
	}

	public activateCuttingList(cuttingListId: string): void {
		cuttingListStore.update(
			setActiveId(cuttingListId)
		)
	}
}
