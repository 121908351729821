export const PANEL_GROUP_TRANSLATIONS = {
	CARCASE: 'Corpus',
	KASTENGROUP: 'Kastengroep',
	DIVIDER: 'Middenstijl',
	FIXED_SHELVE: 'Vaste legplank',
	FRONT: 'Fronten',
	SHELVES: 'Verstelbare legplank',
	BACK: 'Rug',
	FRAME: 'Frame'
}
