import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, skip, Subject, take, takeUntil } from 'rxjs';

import { AccountService } from '~core/services/account.service';
import { AuthService } from '~core/services/auth.service';
import { ExternalUser, InternalUser } from '~modules/auth/types/user.types';

@Component({
	selector: 'app-account-switcher',
	templateUrl: './account-switcher.component.html',
})
export class AccountSwitcherComponent implements OnInit, OnDestroy {
	public isUserInfoOpen = false;
	public currentUser$: Observable<InternalUser | ExternalUser>;
	public isUserInfoClicked: boolean;
	public accountOptions= [];
	public accountIdFormControl = new FormControl(null);
	public accountOptionsLoading$: Subject<boolean> = new Subject();

	private componentDestroyed$: Subject<boolean> = new Subject();

	constructor(
		private readonly accountService: AccountService,
		private readonly authService: AuthService,
		private readonly router: Router
	) {}

	async ngOnInit(): Promise<void> {
		this.accountOptionsLoading$.next(true);
		this.accountService.getAccounts()
			.pipe(take(1))
			.subscribe((accounts) => {
				this.accountOptions = accounts.map((account) => ({
					label: account.name || account.generalEmail,
					value: account.id
				}));
				this.accountOptionsLoading$.next(false);
			});

		this.accountIdFormControl.valueChanges
			.pipe(
				takeUntil(this.componentDestroyed$)
			).subscribe((accountId) => {
				this.authService.overwriteAccountId(accountId);
			});

		this.authService.currentUser$
			.pipe(take(1))
			.subscribe((currentUser) => this.accountIdFormControl.patchValue(currentUser?.account?.id, {
				emitEvent: false,
			}));

		this.authService.accountIdOverwrite$
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe((accountId) => this.accountIdFormControl.patchValue(accountId, {
				emitEvent: false,
			}))
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}
}
