import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { first, take } from 'rxjs';

import { DesignerMode } from '~shared/enums';
import { Item, Part } from '~shared/types';
import { ItemsRepository } from '~modules/projects/store/items/items.repository';
import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { aggregateDimensions } from '~modules/projects/helpers/aggregateDimensions';
import { FillerControllerComponent } from '~modules/projects/ribbon-components/filler-controller/filler-controller.component';

import { ButtonControllerComponent } from '../../ribbon-components/button-controller/button-controller.component';
import { InfoControllerComponent } from '../../ribbon-components/info-controller/info-controller.component';
import { OutlineControllerComponent } from '../../ribbon-components/outline-controller/outline-controller.component';
import { ControllerItem } from '../item-details-ribbon/item-details-ribbon.component';

@Component({
	selector: 'app-editor-wrapper',
	templateUrl: './editor-wrapper.component.html'
})
export class EditorWrapperComponent implements OnChanges {
	// Configurator component groups all item data together to be used by child components
	@Input() public item: Item;
	@Input() public mode: DesignerMode;
	@Input() public part: Part;

	public ribbonControllers: ControllerItem[] = [];
	public designerPanelForm: UntypedFormGroup;

	constructor(
		private itemRepository: ItemsRepository,
		private editorRepository: EditorRepository
	) {}

	public ngOnChanges(changes: SimpleChanges): void {
		if (this.item) {
			this.addControlsToRibbon();
		}
	}

	public addControlsToRibbon () {
		if (this.item.id !== 'new') {
			return this.ribbonControllers = [
				new ControllerItem(InfoControllerComponent),
				new ControllerItem(OutlineControllerComponent),
				new ControllerItem(FillerControllerComponent)
			];
		}

		const buildButtonEvent = new EventEmitter<any>();
		buildButtonEvent.subscribe(() => {
			this.editorRepository.allConfig$
				.pipe(take(1))
				.subscribe((state) => {
					this.itemRepository.saveTempNewItem(aggregateDimensions(state))
				})
		});

		return this.ribbonControllers = [
			new ControllerItem(InfoControllerComponent),
			new ControllerItem(OutlineControllerComponent),
			new ControllerItem(ButtonControllerComponent, { icon: 'build.svg', tooltip: 'Bouw kast' }, buildButtonEvent)
		];
	}
}
