import { ProjectStatus } from '../enums/project.enum';
import { Address, BasicUserDetails } from '../shared.types';

export interface Project {
	id: string;
	name: string;
	accountId: string;
	status: ProjectStatus;
	locked: boolean;
	price?: string;
	deliveryDetails?: Address & BasicUserDetails;
}
