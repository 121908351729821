import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { OutlineType } from '~shared/enums';
import { Outline, OutlineSet } from '~shared/types';

@Injectable({
	providedIn: 'root',
})
export class OutlineService {
	private outlineUrl = environment.outlineServiceUrl + 'outline';
	private outlineSetUrl = environment.outlineServiceUrl + 'outlineset';

	constructor(private http: HttpClient) {}

	public getSpecificOutlines(
		outlines: Outline[],
		outlineType: OutlineType
	): Outline[] {
		return outlines.filter(
			(outline) => outline.outlineType === outlineType
		);
	}

	public getOutlinesByType(type): Observable<any> {
		const params = new HttpParams().set('outlineType', type);
		return this.http.get(this.outlineUrl, { params });
	}

	public getOutlines(): Observable<Outline[]> {
		return this.http.get<Outline[]>(this.outlineUrl);
	}

	public getSelectedIcon(label): Observable<any> {
		const params = new HttpParams().set('label', label);
		return this.http.get(this.outlineUrl, { params });
	}

	public getOutlineSets(): Observable<OutlineSet[]> {
		return this.http.get<OutlineSet[]>(this.outlineSetUrl);
	}
}
