import { UserTypeDirective } from "./user-type/user-type.directive";
import { AccountTypeDirective } from "./account-type/account-type.directive";
import { UserRoleDirective } from "./user-role/user-role.directive";
import { TooltipDirective } from "./tooltip/tooltip.directive";

export const Directives = [
	UserTypeDirective,
	UserRoleDirective,
	TooltipDirective,
	AccountTypeDirective
]
