import { select } from '@ngneat/elf';
import { selectActiveEntity, selectAllEntities } from '@ngneat/elf-entities';
import { selectRequestStatus } from '@ngneat/elf-requests';
import { map } from 'rxjs/operators';

import { itemStore } from './items.store';

export const itemsSelector = {
	items$: itemStore.pipe(selectAllEntities()),
	itemsLoading$: itemStore.pipe(
		selectRequestStatus('items'),
		map((status) => status.value === 'pending')
	),
	deletePanelLoading$: itemStore.pipe(
		selectRequestStatus('delete-panel'),
		map((status) => status.value === 'pending')
	),
	deleteArticleLoading$: itemStore.pipe(
		selectRequestStatus('delete-article'),
		map((status) => status.value === 'pending')
	),
	recreateBackLoading$: itemStore.pipe(
		selectRequestStatus('recreate-back'),
		map((status) => status.value === 'pending')
	),
	updateItemDimensionLoading$: itemStore.pipe(
		selectRequestStatus('update-item-dimension'),
		map((status) => status.value === 'pending')
	),
	activeItem$: itemStore.pipe(selectActiveEntity()),
	tempNewItem$: itemStore.pipe(select((state) => state.tempNewItem)),
	partItems$: itemStore.pipe(select((state) => state.partItems)),
};
