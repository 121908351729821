import {
	Component, OnInit,
} from '@angular/core';
import { map, Observable, switchMap, take } from 'rxjs';

import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { ItemsRepository } from '~modules/projects/store/items/items.repository';
import { Item } from '~shared/types';
import { findUpdatedPanel } from '~shared/helpers';

@Component({
	selector: 'app-filler-l',
	templateUrl: './filler-l.component.html'
})
export class FillerLComponent implements OnInit {
	public openItems: Record<string, boolean> = {
		LEFT_FRONT: true,
		LEFT_STRIP: true,
		LEFT_PANEL: true,
		NONE: true
	};
	public loadingFillerId = null;
	public activeItem$: Observable<Item>;
	public selectedFaceIdx$: Observable<number>;

	constructor(
		private readonly itemsRepository: ItemsRepository,
		private readonly editorRepository: EditorRepository,
	) {}

	public ngOnInit(): void {
		this.activeItem$ = this.itemsRepository.activeItem$;
		this.selectedFaceIdx$ = this.editorRepository.selectedFaceIdx$;
	}

	public toggleItem(itemName: string): void {
		this.openItems[itemName] = !this.openItems[itemName] || false;
	}

	public selectFiller(fillerType: string, baseType: string): void {
		this.loadingFillerId = fillerType;
		this.itemsRepository.activeItem$
			.pipe(
				take(1),
				switchMap((item) => {
					return this.selectedFaceIdx$
						.pipe(
							take(1),
							map((faceIdx) => ({ faceIdx, item }))
						)
				}),
				switchMap(({ item, faceIdx }) => this.itemsRepository.addItemFiller(item.id, item.partId, fillerType, baseType, faceIdx)),
				switchMap((item) => this.editorRepository.selectedItems$.pipe(map((selectedItems) => ({ item, selectedItems }))))
			)
			.subscribe(({ item, selectedItems }) => {
				this.loadingFillerId = null;
			})
	}

	public removeFiller(): void {
		this.itemsRepository.activeItem$
			.pipe(
				take(1),
				switchMap((item) => {
					return this.selectedFaceIdx$
						.pipe(
							take(1),
							map((faceIdx) => ({ faceIdx, item }))
						)
				}),
				switchMap(({ item, faceIdx }) => this.itemsRepository.removeItemFiller(item.id, item.partId, faceIdx)),
				switchMap((item) => this.editorRepository.selectedItems$.pipe(map((selectedItems) => ({ item, selectedItems }))))
			)
			.subscribe(({ item, selectedItems }) => {
				this.loadingFillerId = null;
			})
	}
}
