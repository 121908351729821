import {
	Box3,
	BufferGeometry,
	Line,
	LineBasicMaterial,
	LineDashedMaterial,
	Scene,
	Vector3,
} from 'three';
import { Text } from 'troika-three-text';

export class Measurements {
	scene: Scene;

	lineMaterial: LineBasicMaterial = new LineBasicMaterial({
		color: 0x000000,
		linewidth: 3,
		depthTest: false,
	});
	guideLineMaterial: LineDashedMaterial = new LineDashedMaterial({
		color: 0x000000,
		linewidth: 1,
		depthTest: false,
		scale: 2,
		dashSize: 0.1,
		gapSize: 0.1,
	});

	lines: Array<Line> = [];
	texts: Array<Text> = [];

	constructor(scene: Scene) {
		if (!scene) {
			return;
		}

		this.scene = scene;
	}

	addLine(start: Vector3, end: Vector3, guideline?: boolean) {
		let line;
		if (guideline) {
			line = this.drawLine(start, end, this.guideLineMaterial);
			line.computeLineDistances();
		} else {
			line = this.drawLine(start, end);
		}
		this.scene.add(line);
		this.lines.push(line);
	}

	addText(x: number, y: number, z: number, info: string, f: number = 0.5) {
		const text = new Text();
		this.scene.add(text);

		// Set properties to configure:
		text.text = info;
		text.fontSize = f;
		text.position.x = x;
		text.position.y = y;
		text.position.z = z;
		text.color = 0x000000;
		text.material.depthTest = false;
		// Update the rendering:
		text.sync();
		this.texts.push(text);
	}

	drawLine(start: Vector3, end: Vector3, material = this.lineMaterial) {
		const geometry = new BufferGeometry().setFromPoints([start, end]);

		const line = new Line(geometry, material);
		line.renderOrder = 2;
		return line;
	}

	rotateTextToCamera(camera) {
		//rotate texts to face camera
		for (let i = 0; i < this.texts.length; i++) {
			this.texts[i].rotation.y = Math.atan2(
				camera.position.x - this.texts[i].position.x,
				camera.position.z - this.texts[i].position.z
			);
		}
	}

	dispose() {
		this.lineMaterial.dispose();
		this.guideLineMaterial.dispose();
		for (let i = this.lines.length - 1; i >= 0; i--) {
			const line = this.lines.pop();
			this.scene.remove(line);
			line.geometry.dispose();
		}
		for (let i = this.texts.length - 1; i >= 0; i--) {
			const text = this.texts.pop();
			this.scene.remove(text);
			text.material.dispose();
			text.geometry.dispose();
			text.dispose();
		}
	}

	showCombinedMeasurements(
		objectBBX: Box3,
		x?: string,
		y?: string,
		z?: string
	) {
		//x axis
		if (x) {
			const startWidth = new Vector3(
				objectBBX.max.x,
				objectBBX.max.y,
				objectBBX.max.z + 1
			);
			const endWidth = new Vector3(
				objectBBX.min.x,
				objectBBX.max.y,
				objectBBX.max.z + 1
			);
			this.addLine(startWidth, endWidth);
			this.addText(
				startWidth.x / 2,
				startWidth.y,
				startWidth.z,
				Math.round(startWidth.distanceTo(endWidth)).toString()
			);
			//guide lines
			this.addLine(
				new Vector3(startWidth.x, startWidth.y, startWidth.z - 1),
				new Vector3(startWidth.x, startWidth.y, startWidth.z + 1),
				true
			);
			this.addLine(
				new Vector3(endWidth.x, endWidth.y, endWidth.z - 1),
				new Vector3(endWidth.x, endWidth.y, endWidth.z + 1),
				true
			);
		}
		// y axis
		if (y) {
			const startHeight = new Vector3(
				objectBBX.max.x + 1,
				objectBBX.max.y,
				objectBBX.max.z
			);
			const endHeight = new Vector3(
				objectBBX.max.x + 1,
				objectBBX.min.y,
				objectBBX.max.z
			);
			//main line
			this.addLine(startHeight, endHeight);
			//guide lines
			this.addLine(
				new Vector3(startHeight.x - 1, startHeight.y, startHeight.z),
				new Vector3(startHeight.x + 1, startHeight.y, startHeight.z),
				true
			);
			this.addLine(
				new Vector3(endHeight.x - 1, endHeight.y, startHeight.z),
				new Vector3(endHeight.x + 1, endHeight.y, startHeight.z),
				true
			);
			this.addText(
				startHeight.x + 0.1,
				startHeight.y / 2,
				startHeight.z,
				Math.round(startHeight.distanceTo(endHeight)).toString()
			);
		}
		// z axis
		if (z) {
			const startDepth = new Vector3(
				objectBBX.max.x + 1,
				objectBBX.max.y,
				objectBBX.max.z
			);
			const endDepth = new Vector3(
				objectBBX.max.x + 1,
				objectBBX.max.y,
				objectBBX.min.z
			);
			this.addLine(startDepth, endDepth);
			this.addText(
				startDepth.x + 0.1,
				startDepth.y + 0.2,
				endDepth.z / 2,
				Math.round(startDepth.distanceTo(endDepth)).toString()
			);
			//guide lines
			this.addLine(
				new Vector3(startDepth.x - 1, startDepth.y, startDepth.z),
				new Vector3(startDepth.x + 1, startDepth.y, startDepth.z),
				true
			);
			this.addLine(
				new Vector3(endDepth.x - 1, endDepth.y, endDepth.z),
				new Vector3(endDepth.x + 1, endDepth.y, endDepth.z),
				true
			);
		}
	}

	articleZoneMeasurements(objectBBX: Box3, x?: string, y?: string, z?: string) {
		// x axis
		if (x) {
			const startWidth = new Vector3(
				objectBBX.max.x,
				objectBBX.max.y - (objectBBX.max.y - objectBBX.min.y) / 2,
				objectBBX.max.z
			);
			const endWidth = new Vector3(
				objectBBX.min.x,
				objectBBX.max.y - (objectBBX.max.y - objectBBX.min.y) / 2,
				objectBBX.max.z
			);
			this.addLine(startWidth, endWidth);
			this.addText(
				startWidth.x - 0.5,
				objectBBX.max.y - (objectBBX.max.y - objectBBX.min.y) / 2,
				startWidth.z + 0.25,
				Math.round(startWidth.distanceTo(endWidth)).toString()
			);
			//guide lines
			this.addLine(
				new Vector3(startWidth.x, startWidth.y - 0.25, startWidth.z),
				new Vector3(startWidth.x, startWidth.y + 0.25, startWidth.z),
				true
			);
			this.addLine(
				new Vector3(endWidth.x, endWidth.y - 0.25, endWidth.z),
				new Vector3(endWidth.x, endWidth.y + 0.25, endWidth.z),
				true
			);
		}
		// y axis
		if (y) {
			const startHeight = new Vector3(
				objectBBX.max.x - (objectBBX.max.x - objectBBX.min.x) / 1.5,
				objectBBX.max.y,
				objectBBX.max.z
			);
			const endHeight = new Vector3(
				objectBBX.max.x - (objectBBX.max.x - objectBBX.min.x) / 1.5,
				objectBBX.min.y,
				objectBBX.max.z
			);
			this.addLine(startHeight, endHeight);
			this.addText(
				objectBBX.max.x - (objectBBX.max.x - objectBBX.min.x) / 1.5,
				startHeight.y,
				startHeight.z + 0.25,
				Math.round(startHeight.distanceTo(endHeight)).toString()
			);
			//guide lines
			this.addLine(
				new Vector3(startHeight.x - 0.25, startHeight.y, startHeight.z),
				new Vector3(startHeight.x + 0.25, startHeight.y, startHeight.z),
				true
			);
			this.addLine(
				new Vector3(endHeight.x - 0.25, endHeight.y, startHeight.z),
				new Vector3(endHeight.x + 0.25, endHeight.y, startHeight.z),
				true
			);
		}
		// z axis
		if (z) {
			const startDepth = new Vector3(
				objectBBX.max.x - (objectBBX.max.x - objectBBX.min.x) / 5,
				objectBBX.min.y,
				objectBBX.max.z
			);
			const endDepth = new Vector3(
				objectBBX.max.x - (objectBBX.max.x - objectBBX.min.x) / 5,
				objectBBX.min.y,
				objectBBX.min.z
			);
			this.addLine(startDepth, endDepth);
			this.addText(
				startDepth.x,
				startDepth.y + 0.5,
				startDepth.z + 0.25,
				Math.round(startDepth.distanceTo(endDepth)).toString()
			);
			//guide lines
			this.addLine(
				new Vector3(startDepth.x - 0.25, startDepth.y, startDepth.z),
				new Vector3(startDepth.x + 0.25, startDepth.y, startDepth.z),
				true
			);
			this.addLine(
				new Vector3(endDepth.x - 0.25, endDepth.y, endDepth.z),
				new Vector3(endDepth.x + 0.25, endDepth.y, endDepth.z),
				true
			);
		}
	}

	panelMeasurements(objectBBX: Box3, x?: string, y?: string, z?: string) {
		// x axis
		if (x) {
			const startWidth = new Vector3(
				objectBBX.max.x,
				objectBBX.max.y,
				objectBBX.max.z
			);
			const endWidth = new Vector3(
				objectBBX.min.x,
				objectBBX.max.y,
				objectBBX.max.z
			);
			this.addLine(startWidth, endWidth);
			this.addText(
				objectBBX.max.x - (objectBBX.max.x - objectBBX.min.x) / 2,
				endWidth.y,
				endWidth.z,
				Math.round(startWidth.distanceTo(endWidth)).toString()
			);
			//guide lines
			this.addLine(
				new Vector3(startWidth.x, startWidth.y - 0.25, startWidth.z),
				new Vector3(startWidth.x, startWidth.y + 0.25, startWidth.z),
				true
			);
			this.addLine(
				new Vector3(endWidth.x, endWidth.y - 0.25, endWidth.z),
				new Vector3(endWidth.x, endWidth.y + 0.25, endWidth.z),
				true
			);
		}
		// y axis
		if (y) {
			const startHeight = new Vector3(
				objectBBX.max.x,
				objectBBX.max.y,
				objectBBX.max.z
			);
			const endHeight = new Vector3(
				objectBBX.max.x,
				objectBBX.min.y,
				objectBBX.max.z
			);
			this.addLine(startHeight, endHeight);
			this.addText(
				startHeight.x,
				objectBBX.max.y - (objectBBX.max.y - objectBBX.min.y) / 2,
				startHeight.z,
				Math.round(startHeight.distanceTo(endHeight)).toString()
			);
			//guide lines
			this.addLine(
				new Vector3(startHeight.x - 0.25, startHeight.y, startHeight.z),
				new Vector3(startHeight.x + 0.25, startHeight.y, startHeight.z),
				true
			);
			this.addLine(
				new Vector3(endHeight.x - 0.25, endHeight.y, startHeight.z),
				new Vector3(endHeight.x + 0.25, endHeight.y, startHeight.z),
				true
			);
		}
		// z axis
		if (z) {
			const startDepth = new Vector3(
				objectBBX.max.x,
				objectBBX.min.y,
				objectBBX.max.z
			);
			const endDepth = new Vector3(
				objectBBX.max.x,
				objectBBX.min.y,
				objectBBX.min.z
			);
			this.addLine(startDepth, endDepth);
			this.addText(
				startDepth.x,
				startDepth.y,
				objectBBX.max.z - (objectBBX.max.z - objectBBX.min.z) / 2,
				Math.round(startDepth.distanceTo(endDepth)).toString()
			);
			//guide lines
			this.addLine(
				new Vector3(startDepth.x - 0.25, startDepth.y, startDepth.z),
				new Vector3(startDepth.x + 0.25, startDepth.y, startDepth.z),
				true
			);
			this.addLine(
				new Vector3(endDepth.x - 0.25, endDepth.y, endDepth.z),
				new Vector3(endDepth.x + 0.25, endDepth.y, endDepth.z),
				true
			);
		}
	}
}
