export enum HardwareType {
	CABLE_MANAGER = 'CABLE_MANAGER',
	CONNECTOR = 'CONNECTOR',
	HANDLE = 'HANDLE',
	HINGE = 'HINGE',
	ROD = 'ROD'
}

export enum HardwareMount {
	SURFACE = 'SURFACE',
	MILLED = 'MILLED',
	EDGE = 'EDGE',
	CARCASE = 'CARCASE',
}

export enum HardwareColour {
	WHITE = 'WHITE',
	BLACK = 'BLACK',
	GREY = 'GREY',
	BROWN = 'BROWN',
	DARK_BROWN = 'DARK_BROWN',
	BEIGE = 'BEIGE',
	GREEN = 'GREEN',
	RED = 'RED',
	OTHER = 'OTHER',
}

export enum HardwareFinish {
	ALUMINIUM = 'ALUMINIUM',
	MESSING = 'MESSING',
	COPPER = 'COPPER',
	BRONZE = 'BRONZE',
	GOLD = 'GOLD',
	SILVER = 'SILVER',
	NICKEL = 'NICKEL',
	METAL = 'METAL',
	INOX = 'INOX',
	OTHER = 'OTHER',
}

export enum HardwareHandleType {
	KNOB = 'KNOB',
	BOW = 'BOW',
	GOLA = 'GOLA',
	LONG = 'LONG',
	BOWL = 'BOWL',
	PROFILE = 'PROFILE',
	TIPON = 'TIPON',
}

export enum HardwareStyle {
	MODERN = 'MODERN',
	RETRO = 'RETRO',
	CLASSIC = 'CLASSIC',
	INVISIBLE = 'INVISIBLE',
}
