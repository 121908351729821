// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message-board {
  margin-top: 50px;
  height: 150px;
  width: 450px;
  border: solid 3px #e8e8e8;
  margin-left: 50px;
}

.text-part {
  padding-top: 20px;
  margin: auto;
  width: 80%;
  margin-bottom: 40px;
}

.buttons {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/projects/components/notification-board/notification-board.component.scss"],"names":[],"mappings":"AAGA;EACE,gBAAA;EACA,aAAA;EACA,YAAA;EACA,yBAAA;EACA,iBAAA;AAFF;;AAKA;EACE,iBAAA;EACA,YAAA;EACA,UAAA;EACA,mBAAA;AAFF;;AAKA;EACE,UAAA;EACA,YAAA;EACA,aAAA;EACA,8BAAA;AAFF","sourcesContent":[".container {\n}\n\n.message-board {\n  margin-top: 50px;\n  height: 150px;\n  width: 450px;\n  border: solid 3px #e8e8e8;\n  margin-left: 50px;\n}\n\n.text-part {\n  padding-top: 20px;\n  margin: auto;\n  width: 80%;\n  margin-bottom: 40px;\n}\n\n.buttons {\n  width: 80%;\n  margin: auto;\n  display: flex;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
