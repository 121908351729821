import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BoardOverlayType, BoardType } from '~shared/enums';
import { CustomerBoard, EnrichedBoard, FinishedBoard, RawBoard } from '~shared/types';

import { environment } from '../../../../environments/environment';

@Injectable()
export class BoardsService {
	constructor(private http: HttpClient) {}

	getEnrichedBoards(accountId: string, boardType: BoardType, overlayType?: BoardOverlayType): Observable<EnrichedBoard[]> {
		return this.http.get<EnrichedBoard[]>(`${environment.boardServiceUrl}/enrichedboard`, {
			params: {
				accountId,
				boardType,
				...(overlayType && { overlayType })
			}
		})
			.pipe(
				map((boards) => boards.map((board) => ({
					...board,
					id: `${board.externalSku}_${board.dimensionVariants.map((tm) => tm.boardSku).join('_')}`
				})))
			);
	}

	getCustomerBoards(accountId: string): Observable<CustomerBoard[]> {
		return this.http.get<CustomerBoard[]> (`${environment.boardServiceUrl}/customerboard`, {
			params: {
				accountId,
			}
		})
			.pipe(
				map((boards) => boards.map((board) => ({
					...board,
					board: {
						...board.board,
						id: board.board.sku
					}
				})))
			);
	}

	createCustomerBoard(accountId: string, boardSku: string): Observable<{ id: string; board: RawBoard | FinishedBoard }> {
		return this.http.post<any>(`${environment.boardServiceUrl}/customerboard/account/${accountId}/board/${boardSku}`, {});
	}

	deleteCustomerBoard(accountId: string, boardSku: string): Observable<void> {
		return this.http.delete<any>(`${environment.boardServiceUrl}/customerboard/account/${accountId}/board/${boardSku}`);
	}

	createRawBoard(board: RawBoard): Observable<RawBoard> {
		return this.http.post<RawBoard>(`${environment.boardServiceUrl}/catalogboard/raw`, {
			...board,
			boardType: BoardType.RAW
		});
	}

	createFinishedBoard(board: FinishedBoard): Observable<FinishedBoard> {
		return this.http.post<FinishedBoard>(`${environment.boardServiceUrl}/catalogboard/finished`, {
			...board,
			boardType: BoardType.FINISHED
		});
	}

	deleteCatalogBoard(boardSku: string): Observable<void> {
		return this.http.delete<void>(`${environment.boardServiceUrl}/catalogboard/${boardSku}`);
	}

	updateRawBoard(boardSku: string, board: RawBoard): Observable<RawBoard> {
		return this.http.put<RawBoard>(`${environment.boardServiceUrl}/catalogboard/raw/${boardSku}`, {
			...board,
			boardType: BoardType.RAW
		});
	}

	updateFinishedBoard(boardSku: string, board: FinishedBoard): Observable<FinishedBoard> {
		return this.http.put<FinishedBoard>(`${environment.boardServiceUrl}/catalogboard/finished/${boardSku}`, {
			...board,
			boardType: BoardType.FINISHED
		});
	}

	favouriteCustomerBoard(accountId: string, boardSku: string): Observable<{ id: string; isFavourite: boolean; board: RawBoard | FinishedBoard }> {
		return this.http.patch<any>(`${environment.boardServiceUrl}/customerboard/account/${accountId}/board/${boardSku}/favourite`, {})
			.pipe(
				map((board) => ({
					...board,
					board: {
						...board.board,
						id: board.board.label
					}
				}))
			);
	}

	getCatalogBoard(catalogBoardId: string): Observable<RawBoard | FinishedBoard | EnrichedBoard> {
		return this.http.get<any>(`${environment.boardServiceUrl}/catalogboard`, {
			params: {
				sku: catalogBoardId
			}
		}).pipe(
			map((board) => ({
				...board?.[0],
				id: board?.[0]?.sku
			}))
		);
	}
}
