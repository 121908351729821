import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidators {
	public static oneOf(options: unknown[]): (AbstractControl) => ValidationErrors | null {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control.value) {
				return null;
			}

			return !options.includes(control.value)
				? { oneOf: options }
				: null;
		};
	}

	public static numeric(control: AbstractControl) {
		if (control.value === null || control.value === '') {
			return null
		}

		if (!/^\d+\.?\d*$/.test(control.value.toString())) {
			return { 'invalidNumber': true }
		}

		return null;
	}

	public static validPhoneNumber(control: AbstractControl) {
		if (control.value === null || control.value === '') {
			return null
		}

		if (!/^[\+][(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(control.value.toString())) {
			return { 'invalidPhoneNumber': true }
		}

		return null;
	}
}
