import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Editor, toHTML, Toolbar } from 'ngx-editor';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import * as mime from 'mime-types'

import { Customisation, CustomisationType, Project } from '~shared/types';
import { ItemsRepository } from '~modules/projects/store/items/items.repository';
import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { CustomisationsRepository } from '~modules/projects/store/customisations/customisations.repository';
import { CustomisationStatusModalComponent } from '~modules/projects/modals/customisation-status/customisation-status.modal';
import { AuthService } from '~core/services/auth.service';
import { UserType } from '~modules/auth/types/user.types';
import { ProjectsRepository } from '~modules/projects/store/projects/projects.repository';
import { PartsRepository } from '~modules/projects/store/parts/parts.repository';
import { UsersRepository } from '~modules/users/store/users/users.repository';

import { CustomisationStatuses } from './customisation.constant';

@Component({
	selector: 'app-customisation',
	templateUrl: './customisation.component.html',
})
export class CustomisationComponent implements OnInit, OnDestroy, OnChanges {
	@Input() partId: string;
	@Input() itemId?: string;
	@Input() panelId?: string;
	@Input() articleZoneId?: string;
	@Input() customisations: string[];

	public customisations$: Observable<Customisation[]>
	public activeCustomisation$: Observable<Customisation>
	public disabled = false;
	public show = true;
	public isOpen = true;
	public fileUploadLoading = false;
	public fileDownloadLoadingId = null;
	public allowResponse = false;
	public allCustomisationValid$: Observable<boolean>;
	public updateLoading$: Observable<boolean>;
	public createFileLoading$: Observable<boolean>;
	public createLoading$: Observable<boolean>;
	public project$: Observable<Project>;
	public customisationStatuses = CustomisationStatuses
	public formGroup: FormGroup;
	public descriptionEditor: Editor;
	public responseEditor: Editor;
	public toolbar: Toolbar = [
		['bold', 'italic'],
		['underline', 'strike'],
		['link'],
	];

	private componentDestroyed$: Subject<boolean> = new Subject<boolean>();

	constructor(
		private readonly itemsRepository: ItemsRepository,
		private readonly projectsRepository: ProjectsRepository,
		private readonly editorRepository: EditorRepository,
		private readonly partsRepository: PartsRepository,
		private readonly customisationsRepository: CustomisationsRepository,
		private readonly fb: FormBuilder,
		private readonly dialog: MatDialog,
		private readonly authService: AuthService,
		private readonly toastr: ToastrService
	) {}

	public ngOnInit(): void {
		this.formGroup = this.fb.group({
			id: [],
			description: [''],
			response: [''],
			price: [null]
		});

		if (this.customisations?.length) {
			this.customisationsRepository.getCustomisations(this.customisations)
				.pipe(take(1))
				.subscribe()
		}

		this.project$ = this.projectsRepository.project$;
		this.descriptionEditor = new Editor();
		this.responseEditor = new Editor();
		this.createLoading$ = this.customisationsRepository.createLoading$;
		this.updateLoading$ = this.customisationsRepository.updateLoading$;
		this.createFileLoading$ = this.customisationsRepository.createFileLoading$;
		this.customisations$ = this.customisationsRepository.customisations$

		this.activeCustomisation$ = this.customisationsRepository.activeCustomisation$
			.pipe(tap((customisation) => {
				this.formGroup.reset();
				this.formGroup.patchValue(customisation)
			}));

		this.allCustomisationValid$ = this.customisations$
			.pipe(map((customisations) => {
				return customisations.every((customisation) => !!customisation.description);
			}))

		this.editorRepository.disableFields$
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe((disabled) => this.disabled = disabled);

		this.authService.currentUser$
			.pipe(
				takeUntil(this.componentDestroyed$),
				filter((user) => !!user)
			)
			.subscribe((user) => {
				if (user.userType === UserType.EXTERNAL && !user?.account?.overwritten) {
					this.allowResponse = false;
					this.formGroup.get('response').disable();
					return this.formGroup.get('description').setValidators([Validators.required]);
				}

				this.allowResponse = true;
			})
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (this.customisations?.length) {
			this.customisationsRepository.getCustomisations(this.customisations, true)
				.pipe(take(1))
				.subscribe()
		} else {
			this.customisationsRepository.clearCustomisations()
		}
	}

	public createCustomisation(): void {
		this.createItemCustomisationEntry()
			.pipe(
				take(1),
				switchMap((customisation) => {
					if (this.panelId) {
						return this.itemsRepository.addCustomisationToPanel(this.partId, this.itemId, this.panelId, customisation.id)
					}

					if (this.articleZoneId) {
						return this.itemsRepository.addCustomisationToLayout(this.partId, this.itemId, this.articleZoneId, customisation.id)
					}

					if (this.itemId) {
						return this.itemsRepository.addCustomisationToItem(this.partId, this.itemId, customisation.id)
					}

					return this.partsRepository.addCustomisationToPart(this.partId, customisation.id);
				}),
				take(1),
				switchMap((customisation) => combineLatest([this.editorRepository.selectedItems$, this.customisationsRepository.customisations$])
					.pipe(map(([[item], customisations]) => ({
						item,
						customisations,
						customisation
					})))
				),
				take(1),
				tap(({ item, customisations }) => this.editorRepository.setSelectedItems([{ ...item, customisations: customisations.map(({ id }) => id) }]))
			)
			.subscribe()
	}

	public deleteCustomisation(customisationId: string): void {
		this.deleteItemCustomisationEntry(customisationId)
			.pipe(
				take(1),
				switchMap(() => this.customisationsRepository.deleteCustomisation(customisationId)),
				take(1),
				switchMap(() => combineLatest([this.editorRepository.selectedItems$, this.customisationsRepository.customisations$])),
				take(1),
				tap(([[item], customisations]) => this.editorRepository.setSelectedItems([{ ...item, customisations: customisations.map(({ id }) => id) }]))
			)
			.subscribe()
	}

	private deleteItemCustomisationEntry(customisationId: string): Observable<any> {
		if (this.panelId) {
			return this.itemsRepository.deleteCustomisationFromPanel(this.partId, this.itemId, this.panelId, customisationId)
		}

		if (this.articleZoneId) {
			return this.itemsRepository.deleteCustomisationFromLayout(this.partId, this.itemId, this.articleZoneId, customisationId)
		}

		if (this.itemId) {
			return this.itemsRepository.deleteCustomisationFromItem(this.partId, this.itemId, customisationId)
		}

		return this.partsRepository.deleteCustomisationFromPart(this.partId, customisationId);
	}

	private createItemCustomisationEntry(): Observable<Customisation> {
		if (this.panelId) {
			return this.customisationsRepository.createPanelCustomisation(this.partId, this.itemId, this.panelId, {
				customisationType: CustomisationType.GENERIC
			})
		}

		if (this.articleZoneId) {
			return this.customisationsRepository.createLayoutCustomisation(this.partId, this.itemId, this.articleZoneId, {
				customisationType: CustomisationType.GENERIC
			})
		}

		if (this.itemId) {
			return this.customisationsRepository.createItemCustomisation(this.partId, this.itemId, {
				customisationType: CustomisationType.GENERIC
			})
		}

		return this.customisationsRepository.createPartCustomisation(this.partId, {
			customisationType: CustomisationType.GENERIC
		})
	}

	public submitForm(): void {
		this.formGroup.markAllAsTouched();

		if (!this.formGroup.valid) {
			return;
		}

		const { value } = this.formGroup;

		let descriptionHtml;
		try {
			descriptionHtml = toHTML(value.description);
		} catch (e) {}

		let responseHtml;
		try {
			responseHtml = toHTML(value.response);
		} catch (e) {}

		this.activeCustomisation$
			.pipe(
				take(1),
				switchMap((customisation) => {
					return this.customisationsRepository.updateCustomisation(value.id, {
						customisationType: CustomisationType.GENERIC,
						description: typeof value.description === 'object' ? descriptionHtml : value.description,
						response: typeof value.response === 'object' ? responseHtml : value.response,
						files: customisation.files,
						price: value.price || customisation.price,
						status: customisation.status
					})
						.pipe(take(1))
				})
			)
			.subscribe(() => this.toastr.success('Customisatie opgeslagen'))
	}

	public handleStatusUpdate(): void {
		const { value } = this.formGroup;

		const dialog = this.dialog.open(CustomisationStatusModalComponent);

		dialog.afterClosed()
			.pipe(
				take(1),
				filter((status) => !!status),
				switchMap((status) => this.activeCustomisation$.pipe(take(1), map((customisation) => ({ customisation, status })))),
				switchMap(({ customisation, status }) => this.customisationsRepository.updateCustomisation(value.id, {
					customisationType: CustomisationType.GENERIC,
					description: typeof value.description === 'string' ? value.description : toHTML(value.description),
					response: typeof value.response === 'string' ? value.response : toHTML(value.response),
					files: customisation.files,
					status: status,
				})
					.pipe(take(1)))
			)
			.subscribe()
	}

	public onFileSelected(e: Event): void {
		const file: File = (e.target as HTMLInputElement).files[0];

		const formData = new FormData();
		formData.append('file', file);

		this.fileUploadLoading = true;
		this.activeCustomisation$
			.pipe(
				take(1),
				switchMap((customisation) => this.customisationsRepository.createFile(customisation.id, formData)
					.pipe(take(1)))
			)
			.subscribe(() => {
				this.fileUploadLoading = false;
			})
	}

	public handleDownloadFile(customisationId: string, fileKey: string, fileName: string): void {
		this.fileDownloadLoadingId = fileName;
		this.customisationsRepository.downloadFile(customisationId, fileKey)
			.pipe(take(1))
			.subscribe((data) => {
				this.fileDownloadLoadingId = null;
				var windowUrl = window.URL || window.webkitURL;

				const byteCharacters = atob(data);
				const byteNumbers = new Array(byteCharacters.length);
				for (let i = 0; i < byteCharacters.length; i++) {
					byteNumbers[i] = byteCharacters.charCodeAt(i);
				}

				const byteArray = new Uint8Array(byteNumbers);

				const url = windowUrl.createObjectURL(new Blob([byteArray], { type: mime.lookup(fileName) || 'application/octet-stream' }));
				const anchor = document.createElement('a');
				anchor.href = url;
				anchor.download = fileName;
				anchor.click();
				anchor.parentNode?.removeChild(anchor);
				windowUrl.revokeObjectURL(url);
			})
	}

	public handleRemoveFile(customisationId: string, fileKey: string): void {
		this.customisationsRepository.deleteFile(customisationId, fileKey)
			.pipe(take(1))
			.subscribe()
	}

	public handleCustomisationSelect(customisationId: string): void {
		this.customisationsRepository.activateCustomisation(customisationId)
	}

	public toggleItem(): void {
		this.isOpen = !this.isOpen
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}
}
