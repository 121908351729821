import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { pick } from 'ramda';
import { BehaviorSubject, catchError, combineLatest, distinctUntilChanged, filter, map, Observable, Subject, switchMap, take, tap, throwError } from 'rxjs';

import { BoardsService } from '~modules/boards/services/boards.service';
import { BoardSelectionModalComponent } from '~modules/projects/modals/board-selection/board-selection.modal';
import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { ItemsRepository } from '~modules/projects/store/items/items.repository';
import { GenericItem } from '~shared/components/cabinet-builder/engine-render.service';
import { findUpdatedPanel } from '~shared/helpers';
import { EnrichedBoard, FinishedBoard, RawBoard } from '~shared/types';

@Component({
	selector: 'app-board-selector',
	templateUrl: './board-selector.component.html'
})

export class BoardSelectorComponent implements OnInit {
	@Input() partId: string;
	@Input() disabled: boolean = false;

	public disableFields$: Observable<boolean>;
	public board$: BehaviorSubject<RawBoard | FinishedBoard | EnrichedBoard> = new BehaviorSubject(null);
	public selectedItem$: Observable<GenericItem>
	public isOpen = true;
	public loading = false;

	constructor(
		private editorRepository: EditorRepository,
		private itemsRepository: ItemsRepository,
		private boardsService: BoardsService,
		private dialog: MatDialog,
	) { }

	public ngOnInit(): void {
		this.selectedItem$ = this.editorRepository.selectedItems$
			.pipe(
				map(([item]) => item),
				filter((item) => !!item && !!item.board?.sku),
				distinctUntilChanged((previous, current) => previous.board.sku === current.board.sku),
				tap((item) => {
					this.loading = true;
					this.boardsService.getCatalogBoard(item.board.sku)
						.pipe(take(1))
						.subscribe((result) => {
							this.loading = false;
							this.board$.next(result);
						})
				})
			);

		this.disableFields$ = this.editorRepository.disableFields$
	}

	public handleOpenMaterial(e: Event, selectedBoardLabels: string[]): void {
		e.preventDefault();
		e.stopImmediatePropagation();

		const dialogRef = this.dialog.open(BoardSelectionModalComponent, {
			data: { selectedBoardLabels }
		});

		combineLatest([dialogRef.afterClosed(), this.itemsRepository.activeItem$, this.selectedItem$])
			.pipe(take(1))
			.subscribe(([action, item, panel]) => {
				if (!action) {
					return
				}

				this.editorRepository.setLoading(true);
				this.itemsRepository.updatePanelBoard(item.partId, item.id, panel.article?.id, panel.id, {
					sku: action.sku,
				})
					.pipe(
						take(1),
						catchError((err) => {
							this.editorRepository.setLoading(false)
							return throwError(() => err);
						}),
						switchMap((item) => this.editorRepository.selectedItems$.pipe(take(1), map((selectedItems) => ({ selectedItems, item }))))
					)
					.subscribe(({ selectedItems, item }) => {
						this.editorRepository.setLoading(false);
						this.editorRepository.setSelectedItems([findUpdatedPanel(item, selectedItems[0])]);
					})
			});
	}

	public toggleItem(): void {
		this.isOpen = !this.isOpen
	}
}
