// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  padding-left: 0.3333333333rem;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/projects/ribbon-components/button-controller/button-controller.component.scss"],"names":[],"mappings":"AAEA;EACC,6BAAA;AADD","sourcesContent":["@use 'sass:math';\n\n:host {\n\tpadding-left: math.div(1rem, 3);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
