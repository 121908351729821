export const CustomisationStatuses = {
	PENDING: {
		color: '#D4B643',
		label: 'In aanvraag'
	},
	ACCEPTED: {
		color: '#739867',
		label: 'Geaccepteerd'
	},
	VALIDATED: {
		color: '#739867',
		label: 'Gevalideerd'
	},
	ARCHIVED: {
		color: '#BBBBBB',
		label: 'Gearchiveerd'
	},
}
