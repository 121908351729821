import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Component, forwardRef, Input, OnDestroy, OnInit, OnChanges } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';

@Component({
	selector: 'app-select-input',
	templateUrl: './select-input.component.html',
	providers: [
		{
			provide: [],
			useExisting: forwardRef(() => SelectInputComponent),
			multi: true,
		},
	],
})
export class SelectInputComponent implements OnInit, OnDestroy, ControlValueAccessor, OnChanges {
	@Input() label?: string;
	@Input() placeholder = '';
	@Input() disabled = false;
	@Input() configuration = false;
	@Input() loading = false;
	@Input() clearable = true;
	@Input() options = [];

	private componentDestroyed$: Subject<boolean> = new Subject<boolean>();

	public control: UntypedFormControl = new UntypedFormControl('');
	public updateValue = (_: any) => {};

	constructor(public ngControl: NgControl) {
		ngControl.valueAccessor = this;
	}

	private propagateChange(value: any): void {
		if (this.updateValue) {
			return this.updateValue(value);
		}

		if (this.control) {
			this.control.setValue(value, {
				emitEvent: false
			});
		}
	}

	public ngOnInit(): void {
		if (this.disabled) {
			this.control.disable();
		}

		this.control.valueChanges.pipe(
			takeUntil(this.componentDestroyed$),
		).subscribe((value) => {
			this.propagateChange(value);
		});
	}

	public trackByFn(index, item): void {
		return index;
	}

	public ngOnChanges(changes: any): void {
		if (changes.options) {
			// Update rendering or something
		}
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}

	public writeValue(value: any): void {
		setTimeout(() => this.control.setValue(value, {
			emitEvent: false
		}));
	}

	public registerOnChange(fn: any): void {
		this.updateValue = fn;
	}

	public registerOnTouched(): void {}

	public setDisabledState(isDisabled: boolean) {
		isDisabled ? this.control.disable() : this.control.enable()
		this.disabled = isDisabled;
	}
}
