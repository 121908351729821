import { Box3 } from 'three';

export class CollisionManager {
	minPositionValues: {
		x: number;
		y: number;
		z: number;
	};
	maxPositionValues: {
		x: number;
		y: number;
		z: number;
	};

	constructor(minPositionValues, maxPositionValues){
		this.minPositionValues = minPositionValues
		this.maxPositionValues = maxPositionValues
	}
	//// Check collision based on Object's childrens children -> panels
	checkCollision(object, collisionObjects) {
		let collision = 0;
		for (const item of collisionObjects) {
			for (const child of item.children) {
				if (item != object) {
					const firstBB = new Box3().setFromObject(object);
					const secondBB = new Box3().setFromObject(child);
					const isCollide = firstBB.intersectsBox(secondBB);
					if (isCollide) collision++;
				}
			}
		}
		return collision > 0 ? true : false;
	}

	//// Checks world constraints on specified axis
	checkPosition(axis, activeItem) {
		if (activeItem.position[axis] > this.maxPositionValues[axis]) {
			activeItem.position[axis] = this.maxPositionValues[axis] - 0.01;
		}

		if (activeItem.position[axis] < this.minPositionValues[axis]) {
			activeItem.position[axis] = this.minPositionValues[axis] + 0.01;
		}
	}
}
