import {
	BoxGeometry,
	MeshBasicMaterial,
	Mesh,
	CylinderGeometry,
} from 'three';

export class CutoutShapes {
	material = new MeshBasicMaterial({
		color: 0xe0e0e0,
		transparent: true,
		opacity: 0.5,
	});

	buildCube(
		width: number,
		height: number,
		depth: number,
		position: { x: number; y: number; z: number },
		rotation: { x: number; y: number; z: number }
	) {
		const geometry = new BoxGeometry(width, height, depth);
		const cube = new Mesh(geometry, this.material);
		cube.position.set(position.x, position.y, position.z);
		cube.rotation.set(rotation.x, rotation.y, rotation.z);

		return cube;
	}

	buildCylinder(
		radiusTop: number,
		radiusBottom: number,
		height: number,
		radialSegments: number,
		heightSegments: number,
		position: { x: number; y: number; z: number },
		rotation: { x: number; y: number; z: number }
	) {
		const geometry = new CylinderGeometry(
			radiusTop,
			radiusBottom,
			height,
			radialSegments,
			heightSegments
		);
		const cylinder = new Mesh(geometry, this.material);
		cylinder.position.set(position.x, position.y, position.z);
		cylinder.rotation.set(rotation.x, rotation.y, rotation.z);

		return cylinder;
	}
}
