import { Component, OnInit } from '@angular/core';
import { combineLatest, map, Observable, startWith, switchMap, take } from 'rxjs';

import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { ItemsRepository } from '~modules/projects/store/items/items.repository';
import { PartsRepository } from '~modules/projects/store/parts/parts.repository';
import { Item, Part } from '~shared/types';

@Component({
	templateUrl: './item.component.html',
})
export class ItemComponent implements OnInit {
	public openItems: Record<string, boolean> = {
		GENERAL: true
	};

	public activeItem$: Observable<Item>;
	public activePartItem$: Observable<string>;
	public activePart$: Observable<Part>;

	constructor(
		private readonly itemsRepository: ItemsRepository,
		private readonly editorRepository: EditorRepository,
		private readonly partsRepository: PartsRepository,
	) {}

	public ngOnInit(): void {
		this.activeItem$ = this.itemsRepository.items$
			.pipe(
				take(1),
				switchMap((items) => {
					return combineLatest([
						this.itemsRepository.activeItem$.pipe(startWith(null)),
						this.editorRepository.partEditorSelectedItem$.pipe(startWith(null))
					]).pipe(map(([activeItem, activePartItem]) => ({ activePartItem, activeItem, items })))
				}),
				map(({ items, activeItem, activePartItem }) => {
					if (activeItem) {
						return activeItem;
					}

					return items.find(({ id }) => id === activePartItem);
				})
			);

		this.activePart$ = this.partsRepository.activePart$;
	}
}
