import { Injectable } from '@angular/core';
import { updateRequestStatus } from '@ngneat/elf-requests';
import { take, tap } from 'rxjs/operators';
import { updateEntities, upsertEntities } from '@ngneat/elf-entities';
import { Observable } from 'rxjs';

import { HardwareItem } from '~shared/types';
import { HardwareService } from '~core/services/hardware.service';
import { HardwareType } from '~shared/enums';


import { hardwareItemStore } from './hardware-items.store';
import { hardwareItemsSelector } from './hardware-items.selectors';

@Injectable()
export class HardwareItemsRepository {
	public hardwareItems$ = hardwareItemsSelector.hardwareItems$;
	public hardwareItemsLoading$ = hardwareItemsSelector.hardwareItemsLoading$;
	public catalogItemLoading$ = hardwareItemsSelector.hardwareItemLoading$;
	public createCatalogItemLoading$ = hardwareItemsSelector.createCatalogItemLoading$;
	public updateCatalogItemLoading$ = hardwareItemsSelector.updateCatalogItemLoading$;
	public activeCatalogItem$ = hardwareItemsSelector.activeCatalogItem$;

	constructor(private readonly hardwareService: HardwareService) {}

	public getHardwareItems(hardwareType: HardwareType, accountId: string): Observable<HardwareItem[]> {
		hardwareItemStore.update(updateRequestStatus('get-hardware-items', 'pending'));
		return this.hardwareService
			.getHardwareItems(hardwareType, accountId)
			.pipe(
				take(1),
				tap((items) => {
					hardwareItemStore.update(
						upsertEntities(items),
						updateRequestStatus('get-hardware-items', 'success')
					)
				})
			)
	}

	public toggleFavourite(hardwareType: HardwareType, hardwareItemLabel: string, accountId: string): Observable<HardwareItem> {
		hardwareItemStore.update(updateRequestStatus('toggle-catalog-item-favourite', 'pending'));
		return this.hardwareService
			.toggleCatalogItemFavourite(hardwareType, hardwareItemLabel, accountId)
			.pipe(
				take(1),
				tap((item) => {
					hardwareItemStore.update(
						updateEntities(hardwareItemLabel, { isFavourite: item.isFavourite }),
						updateRequestStatus('toggle-catalog-item-favourite', 'success')
					)
				})
			)
	}
}
