import { OutlineControllerComponent } from './outline-controller/outline-controller.component';
import { ButtonControllerComponent } from './button-controller/button-controller.component';
import { FillerControllerComponent } from './filler-controller/filler-controller.component';
import { InfoControllerComponent } from './info-controller/info-controller.component';

export const RibbonComponents = [
	InfoControllerComponent,
	OutlineControllerComponent,
	ButtonControllerComponent,
	FillerControllerComponent
];
