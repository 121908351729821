import { Component, Inject, EventEmitter, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { CustomisationStatuses } from '~modules/projects/components/customisation/customisation.constant';

@Component({
	templateUrl: "./customisation-status.modal.html"
})
export class CustomisationStatusModalComponent {
	public customisationStatuses = CustomisationStatuses

	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		public dialogRef: MatDialogRef<CustomisationStatusModalComponent>,
	) {}
}
