import { Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-loading',
	templateUrl: './loading.component.html',
})
export class LoadingComponent {
	@Input() loading: boolean;
	@Input() size?: string;
}
