import { BoardSelectionModalComponent } from "./board-selection/board-selection.modal";
import { CustomisationStatusModalComponent } from "./customisation-status/customisation-status.modal";
import { StatusModalComponent } from "./status/status.modal";
import { DeliveryModalComponent } from "./delivery/delivery.modal";
import { StatusHistoryModalComponent } from "./status-history/status-history.modal";
import { CreateAccountModalComponent } from "./create-account/create-account.modal";

export const Modals = [
	BoardSelectionModalComponent,
	StatusModalComponent,
	DeliveryModalComponent,
	StatusHistoryModalComponent,
	CustomisationStatusModalComponent,
	CreateAccountModalComponent
]
