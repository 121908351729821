export interface HingeVariant {
	sku: string;
	colour: string;
	spring: boolean;
}

export interface HingeCatalogItem {
	hardwareType: string;
	hingeType: string;
	label: string;
	name: string;
	variantList: HingeVariant[];
}

export interface HingeConnector {
	catalogItem: HingeCatalogItem;
	sku: string;
	variant: HingeVariant;
}

export interface Hinge {
	connectionType: string;
	connectors: HingeConnector[];
	objPositions: unknown[]
}
