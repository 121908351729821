import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { GlobalWorkerOptions } from 'pdfjs-dist';
import { fabric } from "fabric";

import { DrawingPage } from '~core/pages/sandbox/sandbox.types';

import { buildPage } from './pdf-page.helpers';


GlobalWorkerOptions.workerSrc = '/assets/pdfjs/pdf.worker.js'

@Component({
	templateUrl: './pdf-page.component.html',
	selector: 'app-pdf-page'
})
export class PdfPageComponent implements AfterViewInit {
	@ViewChild('container', { read: ElementRef }) containerRef: ElementRef;
	@ViewChild('canvas', { read: ElementRef }) canvasRef: ElementRef;
	@ViewChild('fabric', { read: ElementRef }) fabricRef: ElementRef;

	@Input() page: DrawingPage;

	@Output() addComment: EventEmitter<{ x: number; y: number }> = new EventEmitter();

	public startX: number;
	public startY: number;

	public ngAfterViewInit(): void {
		const { nativeElement } = this.containerRef;
		buildPage(this.page.pdf, this.canvasRef.nativeElement);

		nativeElement.addEventListener('mousedown', (e) => {
			this.startX = e.pageX;
			this.startY = e.pageY;
		});

		nativeElement.addEventListener('mouseup', (e) => {
			const diffX = Math.abs(e.pageX - this.startX);
			const diffY = Math.abs(e.pageY - this.startY);

			if (diffX < 10 && diffY < 10) {
				const percentageX = e.offsetX / nativeElement.clientWidth;
				const percentageY = e.offsetY / nativeElement.clientHeight;

				this.addComment.emit({
					x: Math.round((percentageX + Number.EPSILON) * 100),
					y: Math.round((percentageY + Number.EPSILON) * 100),
				})
			}
		});

		const fabricCanvas = new fabric.Canvas(this.fabricRef.nativeElement);

		var rect = new fabric.Rect({
			left: 100,
			top: 100,
			fill: 'blue',
			width: 20,
			height: 20
		});

		fabricCanvas.add(rect);
	}
}
