export const DRAWER_FRONT_TYPES = {
	GLASS_LOW: 'Glas laag',
	PANEL: 'Paneel',
	METAL: 'Metaal',
	GLASS: 'Glas',
	RAIL: 'Rail'
};

export const DRAWER_HEIGHT_OPTIONS = {
	N_80: '80',
	M_106: '106',
	K_144: '144',
	C_193: '193',
	F_257: '257'
}
