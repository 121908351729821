import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as uuid from 'uuid';
import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';

@Component({
	selector: 'app-single-radio-input',
	templateUrl: './single-radio-input.component.html',
	providers: [
		{
			provide: [],
			useExisting: forwardRef(() => SingleRadioInputComponent),
			multi: true,
		},
	],
})
export class SingleRadioInputComponent implements OnInit, OnDestroy, ControlValueAccessor {
	@Input() label?: string;
	@Input() name?: string;
	@Input() emitEvents?: boolean = true;
	@Input() value: string;

	private componentDestroyed$: Subject<boolean> = new Subject<boolean>();

	public disabled: boolean;
	public uuid = uuid.v4();
	public control: UntypedFormControl = new UntypedFormControl('');
	public updateValue = (_: any) => {};

	constructor(public ngControl: NgControl) {
		ngControl.valueAccessor = this;
	}

	private propagateChange(value: any): void {
		if (this.updateValue) {
			return this.updateValue(value);
		}

		if (this.control) {
			this.control.setValue(value, {
				emitEvent: this.emitEvents
			});
		}
	}

	public ngOnInit(): void {
		this.control.valueChanges
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe((value) => {
				this.propagateChange(value);
			});
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}

	public writeValue(value: any): void {
		setTimeout(() => this.control.setValue(value, {
			emitEvent: this.emitEvents
		}));
	}

	public registerOnChange(fn: any): void {
		this.updateValue = fn;
	}

	public registerOnTouched(): void {}

	public setDisabledState(isDisabled: boolean) {
		this.disabled = isDisabled;
		isDisabled
			? this.control.disable({ emitEvent: this.emitEvents })
			: this.control.enable({ emitEvent: this.emitEvents });
	}
}
