import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Component, forwardRef, Input, OnDestroy, OnInit, OnChanges } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';

import { StaticDataService } from '~core/services/static-data.service';

@Component({
	selector: 'app-dynamic-input',
	templateUrl: './dynamic-input.component.html',
	providers: [
		{
			provide: [],
			useExisting: forwardRef(() => DynamicInputComponent),
			multi: true,
		},
	],
})
export class DynamicInputComponent implements OnInit, OnDestroy, ControlValueAccessor, OnChanges {
	@Input() label?: string;
	@Input() placeholder = '';
	@Input() dataKey: string;
	@Input() disabled = false;
	@Input() multiple = false;

	private componentDestroyed$: Subject<boolean> = new Subject<boolean>();

	public optionsLoading$: Subject<boolean> = new Subject();
	public options = [];
	public control: UntypedFormControl = new UntypedFormControl('');
	public updateValue = (_: any) => {};

	constructor(
		public ngControl: NgControl,
		private readonly staticDataService: StaticDataService
	) {
		ngControl.valueAccessor = this;
	}

	private propagateChange(value: any): void {
		if (this.updateValue) {
			return this.updateValue(value);
		}

		if (this.control) {
			this.control.setValue(value);
		}
	}

	public ngOnInit(): void {
		if (this.disabled) {
			this.control.disable();
		}

		this.optionsLoading$.next(true);
		this.staticDataService.getStaticData(this.dataKey)
			.pipe(take(1))
			.subscribe((values: string[]) => {
				this.optionsLoading$.next(false);
				this.options = values.map((value) => ({
					value,
					label: value,
				}))
			})

		this.control.valueChanges.pipe(
			takeUntil(this.componentDestroyed$),
		).subscribe((value) => {
			this.propagateChange(value);
		});
	}

	public trackByFn(index, item): void {
		return index;
	}

	public ngOnChanges(changes: any): void {
		if (changes.options) {
			// Update rendering or something
		}
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}

	public writeValue(value: any): void {
		setTimeout(() => this.control.setValue(value));
	}

	public registerOnChange(fn: any): void {
		this.updateValue = fn;
	}

	public registerOnTouched(): void {}

	public setDisabledState(isDisabled: boolean) {
		isDisabled ? this.control.disable() : this.control.enable()
		this.disabled = isDisabled;
	}
}
