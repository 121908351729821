import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Auth, Hub } from 'aws-amplify';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, of, tap } from 'rxjs';

import { InternalUser, ExternalUser, CognitoUser, UserRole, UserType } from '../../modules/auth/types/user.types';

import { AccountService } from './account.service';

@Injectable()
export class AuthService {
	private cognitoUser$: BehaviorSubject<CognitoUser> = new BehaviorSubject(null);

	public loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public accountIdOverwrite$: BehaviorSubject<string> = new BehaviorSubject(localStorage.getItem('accountIdOverwrite') || null);
	public automaticOverwrite = false;
	public currentUser$: BehaviorSubject<InternalUser | ExternalUser> = new BehaviorSubject(null);
	public userId: string;
	public accountId: string;
	public jwtToken: string;

	constructor(
		private readonly router: Router,
		private readonly location: Location,
		private readonly accountService: AccountService
	) {
		Hub.listen('auth', async (data) => {
			switch (data.payload.event) {
			case 'signIn':
			case 'autoSignIn':
				this.cognitoUser$.next(await this.getCognitoUser());
				break;
			}
		});

		(async () => {
			this.cognitoUser$.next(await this.getCognitoUser());
		})();

		this.cognitoUser$
			.pipe(
				filter((cognitoUser) => !!cognitoUser),
			).subscribe((cognitoUser) => {
				this.jwtToken = cognitoUser.signInUserSession.idToken.jwtToken;
				this.userId = cognitoUser.attributes.email;

				this.loading$.next(true);
				this.handleCognitoUser(cognitoUser)
			});
	}

	public handleCognitoUser(cognitoUser, redirectTo?: string): void {
		this.accountService.getUser(cognitoUser.attributes.email)
			.pipe(
				take(1),
				switchMap((currentUser) => {
					return this.accountIdOverwrite$.pipe(
						map((accountIdOverwrite) => ({ accountIdOverwrite, currentUser }))
					)
				}),
				switchMap(({ currentUser, accountIdOverwrite }) => {
					if (!accountIdOverwrite) {
						return of({ accountIdOverwrite, currentUser })
					}

					return this.accountService.getAccount(accountIdOverwrite)
						.pipe(map((account) => ({
							currentUser: {
								...currentUser,
								account: {
									...account,
									overwritten: true
								},
								accountId: account.id
							},
							accountIdOverwrite
						})))
				})
			)
			.subscribe(({ currentUser, accountIdOverwrite }) => {
				this.currentUser$.next(currentUser);
				this.loading$.next(false);
				this.accountId = (currentUser as ExternalUser)?.account?.id || accountIdOverwrite || null;

				if (!currentUser) {
					return;
				}

				if (currentUser.registered || this.location.path().includes('/auth/complete-user')) {
					return;
				}

				this.router.navigate(['/', 'auth', 'complete-user'], {
					queryParams: {
						redirectTo: this.location.path()
					}
				});
			});
	}

	public overwriteAccountId(accountId: string, automaticOverwrite = false): void {
		this.accountIdOverwrite$.next(accountId);
		this.automaticOverwrite = automaticOverwrite;

		if (accountId) {
			return localStorage.setItem('accountIdOverwrite', accountId);
		}

		localStorage.removeItem('accountIdOverwrite')
	}

	public reloadCognitoUser(bypassCache = false): Observable<CognitoUser> {
		return from(this.getCognitoUser(bypassCache))
			.pipe(
				tap((cognitoUser) => this.cognitoUser$.next(cognitoUser))
			)
	}

	private async getCognitoUser(bypassCache = false): Promise<CognitoUser> {
		return Auth.currentAuthenticatedUser({
			bypassCache
		});
	}

	async logout(redirectTo?: string): Promise<void> {
		await Auth.signOut()
			.then(() => {
				localStorage.clear();
				this.cognitoUser$.next(null);
				this.currentUser$.next(null);
				window.location.href = `/auth/login${redirectTo ? `?redirectTo=${redirectTo}` : ''}`
			});
	}
}
