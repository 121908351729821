import { ConnectionType } from "~shared/enums";

export const CONNECTION_TYPE_OPTIONS: Record<ConnectionType, string> = {
	"DOWEL_SCREW": "DOWEL_SCREW",
	"EXCENTER": "EXCENTER",
	"SINGLE_ELBOW_EXCENTER": "SINGLE_ELBOW_EXCENTER",
	"DOUBLE_ELBOW_EXCENTER": "DOUBLE_ELBOW_EXCENTER",
	"SCREW": "SCREW",
	"DOWEL": "DOWEL",
	"DOWEL_SUPPORT": "DOWEL_SUPPORT",
	"SHELVE_SUPPORT": "SHELVE_SUPPORT",
	"SHELVE_SUPPORT_PIN": "SHELVE_SUPPORT_PIN",
	"GROOVE": "GROOVE",
	"GROOVE_STROKE": "GROOVE_STROKE",
	"GROOVE_SCREW": "GROOVE_SCREW",
	"GLUE": "GLUE",
	"DOWEL_GLUE": "DOWEL_GLUE",
	"HINGE": "HINGE",
	"HANDLE": "HANDLE"
}
