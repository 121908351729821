import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'translateObject'
})
export class TranslatePipe implements PipeTransform {
	transform(value: string, translations: Record<string, string>): string {
		return translations?.[value] || value;
	}
}
