import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
	Subject,
	takeUntil,
	Observable,
	switchMap,
	take,
	map,
} from 'rxjs';


import { ConnectionType } from '~shared/enums/item.enum';
import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { ItemsRepository } from '~modules/projects/store/items/items.repository';
import { Item } from '~shared/types';

import { CONNECTION_TYPE_OPTIONS } from './general.const'


@Component({
	templateUrl: './general.component.html',
})
export class GeneralComponent implements OnInit, OnDestroy {
	public configurationForm: FormGroup<{
		connectionType: FormControl<ConnectionType>;
		maximumEdgebanding: FormControl<boolean>;
		visibleConnections: FormControl<boolean>;
	}>;
	public openItems: Record<string, boolean> = {
		GENERAL: true,
	};
	public connectionTypeOptions = [];

	private componentDestroyed$: Subject<boolean> = new Subject()

	constructor(
		private readonly editorRepository: EditorRepository,
		private readonly itemsRepository: ItemsRepository,
		private readonly fb: FormBuilder
	) {}

	@HostListener('document:keydown.escape', ['$event'])
	public onKeydownHandler(event: KeyboardEvent) {
		this.initializeDefaults();
	}

	public ngOnInit(): void {
		this.configurationForm = this.fb.group({
			connectionType: [ConnectionType.DOWEL],
			maximumEdgebanding: [false],
			visibleConnections: [false],
		});

		this.configurationForm.get('connectionType').valueChanges
			.pipe(
				takeUntil(this.componentDestroyed$),
				switchMap((connectionType) => this.itemsRepository.activeItem$
					.pipe(take(1), map((item) => ({ item, connectionType })))),
				switchMap(({ connectionType, item }) => this.itemsRepository.updateItem(item.id, item.partId, { configurationOptions: { connectionType } })),
				take(1)
			)
			.subscribe();

		this.configurationForm.get('maximumEdgebanding').valueChanges
			.pipe(
				takeUntil(this.componentDestroyed$),
				switchMap((maximumEdgebanding) => this.itemsRepository.activeItem$
					.pipe(take(1), map((item) => ({ item, maximumEdgebanding })))),
				switchMap(({ maximumEdgebanding, item }) => this.itemsRepository.updateItem(item.id, item.partId, { configurationOptions: { maximumEdgebanding } })),
				take(1)
			)
			.subscribe();

		this.configurationForm.get('visibleConnections').valueChanges
			.pipe(
				takeUntil(this.componentDestroyed$),
				switchMap((visibleConnections) => this.itemsRepository.activeItem$
					.pipe(take(1), map((item) => ({ item, visibleConnections })))),
				switchMap(({ visibleConnections, item }) => this.itemsRepository.updateItem(item.id, item.partId, { configurationOptions: { visibleConnections } })),
				take(1)
			)
			.subscribe();

		this.initializeDefaults();
	}

	public toggleItem(itemName: string): void {
		this.openItems[itemName] = !this.openItems[itemName] || false;
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}

	public initializeDefaults(): void {
		this.itemsRepository.activeItem$
			.pipe(
				takeUntil(this.componentDestroyed$),
			)
			.subscribe((item) => {
				this.connectionTypeOptions = item.configurationOptions.connectionTypeOptions.map((option) => ({
					value: option,
					label: CONNECTION_TYPE_OPTIONS[option] || option,
				}))
				this.configurationForm.reset({}, { emitEvent: false });
				this.configurationForm.patchValue({
					connectionType: item.configurationOptions.connectionType,
					visibleConnections: item.configurationOptions.visibleConnections,
					maximumEdgebanding: item.configurationOptions.maximumEdgebanding,
				}, { emitEvent: false });
			})
	}
}
