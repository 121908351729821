import { DataTexture, EquirectangularReflectionMapping, Group, Material, Object3D, Scene, Texture } from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';

import { HardwareColour, HardwareFinish } from '~shared/enums';

export type LoadedItemDetails = {
	position?: { x: number; y: number; z: number };
	rotation?: { x: number; y: number; z: number };
	scale?: { x: number; y: number; z: number };
};

export class Loader {
	objLoader: OBJLoader = new OBJLoader();
	materialLoader: MTLLoader = new MTLLoader();

	constructor() {}

	loadFile(
		baseUrl: string,
		details: LoadedItemDetails,
		parent: Object3D,
		variant: HardwareColour | HardwareFinish,
		onLoad?: (object: Group, details, parent) => void
	) {
		const mtlUrl = `${baseUrl}_${variant}.mtl`;
		const objUrl = baseUrl + '.obj';
		//// Add material to object
		this.materialLoader.load(mtlUrl, (materials) => {
			materials.preload();
			this.objLoader.setMaterials(materials);

			// load a resource
			this.objLoader.load(
				// resource URL
				objUrl,
				// called when resource is loaded
				(object) => {
					onLoad(object, details, parent);
				},
				// called when loading is in progresses
				(xhr) => {
					// console.log((xhr.loaded / xhr.total) * 100 + '% loaded');
				},
				// called when loading has errors
				(error) => {
					console.error('An error happened', error);
				}
			);
		});
	}
}
