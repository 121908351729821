import { BaseAccount } from './account.types';

export enum UserType {
	INTERNAL = 'INTERNAL',
	EXTERNAL = 'EXTERNAL'
}

export enum UserRole {
	SALES = 'SALES',
	DESIGNER = 'DESIGNER',
	ADMIN = 'ADMIN',
	ACCOUNT_ADMIN = 'ACCOUNT_ADMIN',
	ACCOUNT_USER = 'ACCOUNT_USER',
}

export interface BaseUser {
	email: string;
	userType: UserType;
	registered: boolean;
}

export interface InternalUser extends BaseUser {
	firstname: string;
	lastname: string;
	language: 'DUTCH' | 'FRENCH' | 'GERMAN' | 'ENGLISH';
	telephonenumber: string;
	email: string;
	userRole: UserRole;
	account: BaseAccount;
}

export interface ExternalUser extends BaseUser {
	firstname: string;
	lastname: string;
	language: 'DUTCH' | 'FRENCH' | 'GERMAN' | 'ENGLISH';
	telephonenumber: string;
	email: string;
	userRole: UserRole;
	account: BaseAccount;
	accountId: string;
}

export interface CognitoUser {
	attributes: {
		sub: string;
		email: string;
	};
	username: string;
	signInUserSession: {
		idToken: {
			jwtToken: string;
		}
	};
}
