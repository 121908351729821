import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { ConstraintType, IConstraint } from '~shared/types'

export class ParameterValidators {
	public static checkConstraints(constraint: IConstraint): (AbstractControl) => ValidationErrors | null {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!constraint) {
				return null;
			}

			if (constraint.type === ConstraintType.INLIST || constraint.type === ConstraintType.LARGERTHEN_INLIST || constraint.type === ConstraintType.SMALLERTHEN_INLIST) {
				return (constraint.values?.includes(control.value))
					? null
					: { constraintError: {
						inListValues: constraint.values,
					} }
			}


			if (constraint.type === ConstraintType.BETWEEN) {
				const lowerBoundCheck = constraint.strict ? Number(constraint.lowerBoundary) < Number(control.value) : Number(constraint.lowerBoundary) <= Number(control.value);
				const upperBoundCheck = constraint.strict ? Number(control.value) < Number(constraint.upperBoundary) : Number(control.value) <= Number(constraint.upperBoundary)
				return (lowerBoundCheck && upperBoundCheck)
					? null
					: { constraintError: {
						lowerBoundValue: constraint.lowerBoundary,
						upperBoundValue: constraint.upperBoundary,
					} }
			}

			if (constraint.type === ConstraintType.SMALLER_THEN) {
				if (constraint.strict)  {
					return (Number(control.value) < Number(constraint.boundary))
						? null
						: { constraintError: {
							upperBoundValue: Number(constraint.boundary),
						} }
				}

				return (Number(control.value) <= Number(constraint.boundary))
					? null
					: { constraintError: {
						upperBoundValue: Number(constraint.boundary),
					} }
			}

			if (constraint.type === ConstraintType.LARGER_THEN) {
				if (constraint.strict) {
					return (Number(constraint.boundary) < Number(control.value))
						? null
						: { constraintError: {
							lowerBoundValue: Number(constraint.boundary),
						} }
				}

				return (Number(constraint.boundary) <= Number(control.value))
					? null
					: { constraintError: {
						lowerBoundValue: Number(constraint.boundary),
					} }
			}

			return null;
		};
	}
}
