// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section {
  margin: 0;
  margin-top: 5px;
  width: 400px;
  text-align: center;
  border: 1px solid #AAA;
  border-radius: 5px;
}
section .title-bar {
  padding: 5px;
  background-color: #E1E1E1;
  border-bottom: 1px solid #AAA;
  font-weight: bolder;
}
section .title-bar p {
  margin: 0;
}
section .comments {
  height: 120px;
  font-weight: lighter;
  font-size: smaller;
  overflow-block: auto;
}
section .comments div {
  padding: 2px;
}
section .comments div:hover {
  background-color: #E5E5E5;
  border-bottom: 1px solid #CCC;
  border-top: 1px solid #CCC;
}
section .comments div:hover .cross {
  visibility: visible;
  cursor: pointer;
}
section .comments div p {
  display: inline;
  margin: 0;
}
section .comments div .cross {
  float: inline-end;
  visibility: hidden;
  font-size: medium;
}
section .comments div .button-add {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/projects/components/comments/comments.component.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;EAEA,sBAAA;EACA,kBAAA;AAAJ;AAEI;EACI,YAAA;EAEA,yBAAA;EACA,6BAAA;EAEA,mBAAA;AAFR;AAGQ;EACI,SAAA;AADZ;AAKI;EACI,aAAA;EAEA,oBAAA;EACA,kBAAA;EACA,oBAAA;AAJR;AAMQ;EACI,YAAA;AAJZ;AAMY;EACI,yBAAA;EACA,6BAAA;EACA,0BAAA;AAJhB;AAMgB;EACI,mBAAA;EACA,eAAA;AAJpB;AAQY;EACI,eAAA;EACA,SAAA;AANhB;AASY;EACI,iBAAA;EACA,kBAAA;EACA,iBAAA;AAPhB;AAUY;EACI,eAAA;AARhB","sourcesContent":["section{\n    margin: 0;\n    margin-top: 5px;\n    width: 400px;\n    text-align: center;\n\n    border: 1px solid #AAA;\n    border-radius: 5px;\n\n    .title-bar{\n        padding: 5px;\n\n        background-color: #E1E1E1;\n        border-bottom: 1px solid #AAA;\n\n        font-weight: bolder;\n        p{\n            margin: 0;\n        }\n    }\n\n    .comments{\n        height: 120px;\n\n        font-weight: lighter;\n        font-size: smaller;\n        overflow-block: auto;\n        \n        div{\n            padding: 2px;\n            \n            &:hover{\n                background-color: #E5E5E5;\n                border-bottom: 1px solid #CCC;\n                border-top: 1px solid #CCC;\n\n                .cross{\n                    visibility: visible;\n                    cursor: pointer;\n                }\n            }\n\n            p{\n                display: inline;\n                margin: 0\n            }\n\n            .cross{\n                float: inline-end;\n                visibility: hidden;\n                font-size: medium;\n            }\n\n            .button-add{\n                cursor: pointer;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
