import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { combineLatest, filter, map, mergeMap, Observable, of, Subject, switchMap, take, takeUntil } from 'rxjs';
import { Texture } from 'three';

import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { ItemsRepository } from '~modules/projects/store/items/items.repository';
import { GenericItem } from '~shared/components/cabinet-builder/engine-render.service';
import { Item } from '~shared/types';

@Component({
	templateUrl: './back.component.html',
})
export class BackComponent implements OnInit, OnDestroy {
	public configurationForm: FormGroup<{
		backPosition: FormControl<string>;
	}>;
	public openItems: Record<string, boolean> = {
		GENERAL: true,
		DISMOUNTABLE: true,
		DISTANCE_INLAY: true,
		BACK_POSITION: true
	};
	public activePanel$: Observable<GenericItem>;
	public activeItem$: Observable<Item>;
	public selectedItem$: Observable<GenericItem>
	public backPositionControl = new FormControl();
	public dismountableControl = new FormControl();

	private componentDestroyed$: Subject<boolean> = new Subject()

	constructor(
		private readonly fb: FormBuilder,
		private readonly itemsRepository: ItemsRepository,
		private readonly editorRepository: EditorRepository,
	) {}

	public ngOnInit(): void {
		this.activeItem$ = this.itemsRepository.activeItem$;
		this.activePanel$ = this.editorRepository.selectedItems$
			.pipe(map(([item]) => item));

		this.initializeDefaults();

		this.backPositionControl.valueChanges
			.pipe(
				takeUntil(this.componentDestroyed$),
				switchMap((value) => combineLatest([this.activePanel$, this.activeItem$]).pipe(take(1), map(([panel, item]) => ({ panel, value, item })))),
				switchMap(({ panel, value, item }) => this.itemsRepository.updatePanel(item.partId, item.id, panel.id, {
					backPanelType: value
				}))
			)
			.subscribe()

		this.dismountableControl.valueChanges
			.pipe(
				takeUntil(this.componentDestroyed$),
				switchMap((value) => combineLatest([this.activePanel$, this.activeItem$]).pipe(take(1), map(([panel, item]) => ({ panel, value, item })))),
				switchMap(({ panel, value, item }) => this.itemsRepository.updatePanel(item.partId, item.id, panel.id, {
					disMountable: value
				}))
			)
			.subscribe()

		this.configurationForm = this.fb.group({
			backPosition: [null],
		});
	}

	public toggleItem(itemName: string): void {
		this.openItems[itemName] = !this.openItems[itemName] || false;
	}

	public initializeDefaults(): void {
		this.editorRepository.selectedItems$
			.pipe(
				takeUntil(this.componentDestroyed$),
				filter((items) => !!items && !!items.length)
			)
			.subscribe(([panel]) => {
				if (!panel) {
					return;
				}

				this.backPositionControl.setValue(panel.backPanelType, { emitEvent: false })
				this.dismountableControl.setValue(panel.disMountable, { emitEvent: false })
			})
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}
}
