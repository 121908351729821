import { createStore, withProps } from '@ngneat/elf';
import { withActiveId, withEntities } from '@ngneat/elf-entities';
import { withRequestsStatus } from '@ngneat/elf-requests';

import { HardwareItem } from '~shared/types';

export const hardwareItemStore = createStore(
	{ name: 'hardware-items' },
	withEntities<HardwareItem, 'label'>({ idKey: 'label' }),
	withActiveId(),
	withRequestsStatus<string>()
);
