import { select } from '@ngneat/elf';
import { selectActiveEntity, selectAllEntities } from '@ngneat/elf-entities';
import { selectRequestStatus } from '@ngneat/elf-requests';
import { map } from 'rxjs/operators';

import { cuttingListStore } from './cutting-lists.store';

export const cuttingListsSelector = {
	cuttingLists$: cuttingListStore.pipe(selectAllEntities()),
	cuttingListsLoading$: cuttingListStore.pipe(selectRequestStatus('get-cutting-lists'), map((status) => status.value === 'pending')),
	activeCuttingList$: cuttingListStore.pipe(selectActiveEntity()),
};
