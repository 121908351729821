import { Item } from '~shared/types';

import { EditorState } from '../store/editor/editor.types';

export const aggregateDimensions = (state: Partial<EditorState>, item?: Item) => {
	const { dimensions } = state

	return {
		dimension: {
			depth: {
				...Object.keys(dimensions || []).filter((key) => key.startsWith('D')).reduce((acc, key) => ({ ...acc, [key]: { value: Number(dimensions[key]) } }), {}),
				D: { value: Number(dimensions?.['D']) || 600 },
			},
			width: {
				...Object.keys(dimensions || []).filter((key) => key.startsWith('W')).reduce((acc, key) => ({ ...acc, [key]: { value: Number(dimensions[key]) } }), {}),
				W: { value: Number(dimensions?.['W']) || 1000 },
			},
			height: {
				...Object.keys(dimensions || []).filter((key) => key.startsWith('H')).reduce((acc, key) => ({ ...acc, [key]: { value: Number(dimensions[key]) } }), {}),
				H: { value: Number(dimensions?.['H']) || 2500 },
			},
		},
		outlineSetLabel: `${state.outlineMap?.FRONT?.label}_${state.outlineMap?.TOP?.label}_${state.outlineMap?.SIDE?.label}`
	}
}
