import { ParameterSet, ParameterValue } from "~shared/shared.types";

export interface ParameterWithLocation {
	dimension: ParameterValue;
	edgeLocation: string;
}

interface CutoutHardware {
	colour: string;
	hardwareType: string;
	id: string;
	parameterSet: ParameterSet;
}

export interface ICutout {
	cutoutType: string;
	id: string;
	position: Record<string, ParameterWithLocation | ParameterValue>;
	hardware: CutoutHardware;
}
