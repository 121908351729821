import { select } from '@ngneat/elf';
import { selectActiveEntity, selectAllEntities } from '@ngneat/elf-entities';
import { selectRequestStatus } from '@ngneat/elf-requests';
import { map } from 'rxjs/operators';

import { hardwareItemStore } from './hardware-items.store';

export const hardwareItemsSelector = {
	hardwareItems$: hardwareItemStore.pipe(selectAllEntities()),
	activeCatalogItem$: hardwareItemStore.pipe(selectActiveEntity()),
	hardwareItemsLoading$: hardwareItemStore.pipe(
		selectRequestStatus('get-hardware-items'),
		map((status) => status.value === 'pending')
	),
	hardwareItemLoading$: hardwareItemStore.pipe(
		selectRequestStatus('get-hardware-item'),
		map((status) => status.value === 'pending')
	),
	createCatalogItemLoading$: hardwareItemStore.pipe(
		selectRequestStatus('create-hardware-item'),
		map((status) => status.value === 'pending')
	),
	updateCatalogItemLoading$: hardwareItemStore.pipe(
		selectRequestStatus('update-hardware-item'),
		map((status) => status.value === 'pending')
	),
	// deletePanelLoading$: itemStore.pipe(
	// 	selectRequestStatus('delete-panel'),
	// 	map((status) => status.value === 'pending')
	// ),
	// deleteArticleLoading$: itemStore.pipe(
	// 	selectRequestStatus('delete-article'),
	// 	map((status) => status.value === 'pending')
	// ),
	// updateItemDimensionLoading$: itemStore.pipe(
	// 	selectRequestStatus('update-item-dimension'),
	// 	map((status) => status.value === 'pending')
	// ),
	// activeItem$: itemStore.pipe(selectActiveEntity()),
	// tempNewItem$: itemStore.pipe(select((state) => state.tempNewItem)),
};
