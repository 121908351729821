import {
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	catchError,
	delay,
	Observable,
	switchMap,
	throwError,
} from 'rxjs';

import { AuthService } from '../services/auth.service';

@Injectable()
export class AddHeaderInterceptor implements HttpInterceptor {
	constructor(
		private readonly authService: AuthService
	) {}

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return next
			.handle(request.clone({
				headers: request.headers.append(
					'Authorization',
					`Bearer ${this.authService.jwtToken}`
				),
			}))
			.pipe(
				catchError((error) => {
					if (error.status === 401) {
						return this.authService.reloadCognitoUser(true)
							.pipe(
								delay(100),
								switchMap(() => next.handle(request.clone({
									headers: request.headers.append(
										'Authorization',
										`Bearer ${this.authService.jwtToken}`
									),
								})))
							);
					}

					return throwError(() => error);
				})
			);
	}
}
