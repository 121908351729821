import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { combineLatest, map, mergeMap, Observable, of, Subject, switchMap, take, takeUntil, tap } from 'rxjs';

import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { ItemsRepository } from '~modules/projects/store/items/items.repository';
import { GenericItem } from '~shared/components/cabinet-builder/engine-render.service';
import { ConstructionParameter } from '~shared/enums';
import { Item } from '~shared/types';

@Component({
	templateUrl: './carcase.component.html',
})
export class CarcaseComponent implements OnInit, OnDestroy {
	public configurationForm: FormGroup<{
		backOffset: FormControl<string>;
		backPosition: FormControl<string>;
	}>;
	public openItems: Record<string, boolean> = {
		GENERAL: true,
		DISTANCE_INLAY: true,
		CONNECTION_LEFT: true,
		CONNECTION_RIGHT: true,
	};
	public selectedItem$: Observable<GenericItem>;
	public activePanel$: Observable<GenericItem>;
	public activeItem$: Observable<Item>;
	public rightConstructionTypeControl = new FormControl();
	public leftConstructionTypeControl = new FormControl();

	private componentDestroyed$: Subject<boolean> = new Subject()

	constructor(
		private readonly editorRepository: EditorRepository,
		private readonly itemsRepository: ItemsRepository,
		private readonly fb: FormBuilder
	) {}

	public ngOnInit(): void {
		this.activeItem$ = this.itemsRepository.activeItem$;
		this.activePanel$ = this.editorRepository.selectedItems$
			.pipe(
				map(([item]) => item),
			);

		this.configurationForm = this.fb.group({
			backOffset: [null],
			backPosition: [null],
		});

		this.rightConstructionTypeControl.valueChanges
			.pipe(
				takeUntil(this.componentDestroyed$),
				mergeMap((values) => {
					return combineLatest([this.activePanel$, this.itemsRepository.activeItem$]).pipe(
						take(1),
						map(([panel, item]) => {
							return { values, panel, item };
						})
					);
				}),
				switchMap(({ values, item, panel }) => {
					if (!item || !panel) {
						return
					}

					return this.itemsRepository.updatePanel(item.partId, item.id, panel.id, {
						rightConstructionType: values
					})
				}),
				take(1)
			)
			.subscribe()

		this.leftConstructionTypeControl.valueChanges
			.pipe(
				takeUntil(this.componentDestroyed$),
				mergeMap((values) => {
					return combineLatest([this.activePanel$, this.itemsRepository.activeItem$]).pipe(
						take(1),
						map(([panel, item]) => {
							return { values, panel, item };
						})
					);
				}),
				switchMap(({ values, item, panel }) => {
					if (!item || !panel) {
						return
					}

					return this.itemsRepository.updatePanel(item.partId, item.id, panel.id, {
						leftConstructionType: values
					})
				}),
				take(1)
			)
			.subscribe()
	}

	public toggleItem(itemName: string): void {
		this.openItems[itemName] = !this.openItems[itemName] || false;
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}
}
