import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { CuttingList, CuttingListLine } from '~shared/types';

@Injectable()
export class CuttingListService {
	public baseUrl: string = `${environment.baseUrl}/cuttinglist-api/v1`;

	constructor(private http: HttpClient) {}

	public getCuttingLists(projectId: string): Observable<CuttingList[]> {
		return this.http
			.get<CuttingList[]>(`${this.baseUrl}/project/${projectId}/cuttinglist`);
	}

	public createCuttingList(projectId: string, body): Observable<CuttingList> {
		return this.http
			.post<CuttingList>(`${this.baseUrl}/project/${projectId}/cuttinglist`, body);
	}

	public updateCuttingList(projectId: string, cuttingListId: string, body): Observable<CuttingList> {
		return this.http
			.put<CuttingList>(`${this.baseUrl}/project/${projectId}/cuttinglist/${cuttingListId}`, body);
	}

	public duplicateCuttingList(projectId: string, cuttingListId: string): Observable<CuttingList> {
		return this.http
			.post<CuttingList>(`${this.baseUrl}/project/${projectId}/cuttinglist/${cuttingListId}`, {});
	}

	public deleteCuttingList(projectId: string, cuttingListId: string): Observable<void> {
		return this.http
			.delete<void>(`${this.baseUrl}/cuttinglist/${cuttingListId}`);
	}




	public getCuttingListLines(cuttingListId: string): Observable<CuttingListLine[]> {
		if (cuttingListId === 'temp') {
			return of([]);
		}

		return this.http
			.get<CuttingListLine[]>(`${this.baseUrl}/cuttinglist/${cuttingListId}/cuttinglistlines`);
	}

	public createCuttingListLine(cuttingListId: string, body): Observable<CuttingListLine> {
		return this.http
			.post<CuttingListLine>(`${this.baseUrl}/cuttinglist/${cuttingListId}/cuttinglistline`, body);
	}

	public updateCuttingListLine(cuttingListId: string, cuttingListLineId: string, body): Observable<CuttingListLine> {
		return this.http
			.put<CuttingListLine>(`${this.baseUrl}/cuttinglist/${cuttingListId}/cuttinglistline/${cuttingListLineId}`, body);
	}

	public duplicateCuttingListLine(cuttingListId: string, cuttingListLineId: string): Observable<CuttingListLine> {
		return this.http
			.post<CuttingListLine>(`${this.baseUrl}/cuttinglist/${cuttingListId}/cuttinglistline/${cuttingListLineId}`, {});
	}

	public deleteCuttingListLine(cuttingListId: string, cuttingListLineId: string): Observable<void> {
		return this.http
			.delete<void>(`${this.baseUrl}/cuttinglist/${cuttingListId}/cuttinglistline/${cuttingListLineId}`, {});
	}
}
