import { Item } from './item.types'

export interface CatalogItem {
	id: string;
	name: string;
	description: string;
	application: string[];
	item: Item;
	catalogId: string;
	favourite: boolean;
	accountId: string;
}
