import { IPolyhedron } from "~shared/types/polyhedron.types";

import { ArticleType, BackPanelType, FrontConnection, PanelType } from "../enums/item.enum";

import { FinishedBoard } from "./boards.types";
import { ICutout } from "./cutout.types";
import { IParameterSet } from "./parameter.types";
import { IConnection } from "./connection.types";

export interface IMeshMaterial {
	opacity: number;
	colour: string;
}


export interface MeshMaterial {
	opacity: number,
	colour: string,
	transparent: boolean;
}

export interface IEdge {
	faceNormal: FaceNormal;
	edgeType: string;
	faceIndex: number;
	edgeband: boolean;
	length: string; // should be number perhaps?
}

export enum FaceNormal {
	FRONT = 'F',
	RIGHT = 'R',
	TOP = 'T',
	LEFT = 'L',
	BOTTOM = 'B',
	LEFT_TOP = 'L_T',
	RIGHT_TOP = 'R_T'
}

export interface IPanel {
	id: string;
	sequence?: number;
	customisations?: string[]
	cutouts?: ICutout[];
	backPanelType?: BackPanelType;
	disMountable?: boolean;
	shape?: IPolyhedron;
	edges?: IEdge[];
	panelType?: PanelType;
	parameterSet?: IParameterSet;
	removable?: boolean;
	panelGroup?: ArticleType;
	outlineFaceIdx?: number;
	outlineFaceNormal?: FaceNormal;
	construction?: unknown;
	board?: FinishedBoard;
	frontConnection?: FrontConnection;
	hingeConnection?: IConnection;
	handleConnection?: IConnection;
}
