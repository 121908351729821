export const commonEnvironment = {
	articleServiceUrl: '/article-api/v1/',
	outlineServiceUrl: '/outline-api/v1/',
	boardServiceUrl: '/board-api/v1',
	projectServiceUrl: '/project-api/v1/',
	accountServiceUrl: '/account-api/v1',
};

export function addBaseUrlToUrls(environment, commonEnvironment) {
	Object.keys(commonEnvironment).forEach((key: string) => {
		if (key.includes("Url")) {
			environment[key] = environment.baseUrl + commonEnvironment[key];
		}
	});
}
