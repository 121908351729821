import {
	Component,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';


import { Item } from '~shared/types';
import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { ItemsRepository } from '~modules/projects/store/items/items.repository';

import { FILLER_ZONE_NAMES, FILLER_ZONE_ALIASES } from './filler-controller.const';

@Component({
	selector: 'app-filler-controller',
	templateUrl: './filler-controller.component.html'
})
export class FillerControllerComponent implements OnInit, OnDestroy {
	public disableFields$: Observable<boolean>;
	public activeItem$: Observable<Item>;
	public selectedFaceIdx$: Observable<number>;
	public fillerZoneNames = FILLER_ZONE_NAMES;
	public fillerZoneAliases = FILLER_ZONE_ALIASES;

	private componentDestroyed$: Subject<boolean> = new Subject();

	constructor(
		private readonly editorRepository: EditorRepository,
		private readonly itemsRepository: ItemsRepository,
	) {}

	ngOnInit(): void {
		this.selectedFaceIdx$ = this.editorRepository.selectedFaceIdx$;
		this.disableFields$ = this.editorRepository.disableFields$;
		this.activeItem$ = this.itemsRepository.activeItem$;
	}

	public handleButtonClick(type: string, faceIdx: number): void {
		this.editorRepository.setActiveConfigurationPanel(`FILLER_${type}`);
		this.editorRepository.setSelectedFaceIdx(faceIdx)
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete()
	}
}
