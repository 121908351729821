export interface CuttingList {
	id: string;
	project: string;
	name: string;
}

export interface CuttingListLine {
	id: string;
	cuttingListId: string;
	description: string;
	dimension: {
		length: number;
		width: number;
	};
	cardinality: number;
	boardSku: string;
	edgebandMap: {
		one: boolean;
		two: boolean;
		three: boolean;
		four: boolean;
	}
}
