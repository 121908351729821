import { Address } from '~shared/shared.types';

export interface Company {
	name: string;
	companyClass: 'BV' | 'NV' | 'VOF' | 'EZ';
	vatNumber: string;
	address: Address;
}

export enum AccountType {
	INDIVIDUAL = 'INDIVIDUAL',
	PROFESSIONAL = 'PROFESSIONAL'
}

export enum DeliverableAddressType {
	SAME = 'SAME',
	OTHER = 'OTHER'
}

export interface BaseAccount {
	id: string;
	deliveryDetails: Address;
	name: string;
	overwritten?: boolean;
	company?: Company;
	invoiceAddress: Address;
	generalEmail?: string;
	invoiceEmail?: string;
}
