import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class  UserInputsService {
  private customizationData: BehaviorSubject<any> =  new BehaviorSubject<any>(null);

  iconSelected = new EventEmitter<any>();

  outlinesChecked = new EventEmitter<any>();

  cancelIconSelection = new EventEmitter<any>();

  cabinetNameInput = new EventEmitter<any>();

  createdSpacePart = new EventEmitter<any>();

  selectedCabinetData = new EventEmitter<any>();

  changeCabinetData = new EventEmitter<any>();

  getNewCabinetID = new EventEmitter<any>();

  updateCabinet = new EventEmitter<any>();

  newCabinetName = new EventEmitter<any>();

  onAddNewCabinet = new EventEmitter<any>();

  openMaterialEvent = new EventEmitter<any>();

  selectedSpacePart = new EventEmitter<any>();


  constructor() { }

  emitOutlinesChecked(firteringResults: any): void {
    this.outlinesChecked.emit(firteringResults);
  }

  getOutlinesChecked(): any {
    return this.outlinesChecked;
  }

  setCustomization(data: any): void {
    this.customizationData.next(data);
  }

  getCustomization(): Observable<any> {
    return this.customizationData.asObservable();
  }
}

