import { Component, Input, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-button-controller',
	templateUrl: './button-controller.component.html',
	styleUrls: ['./button-controller.component.scss'],
})
export class ButtonControllerComponent {
	public eventEmitter: EventEmitter<any>;
	private internalData: any;

	@Input() set data(data: any) {
		this.internalData = data;
	}

	get data(): any {
		return this.internalData;
	}

	onClick(): void {
		this.eventEmitter.emit();
	}
}
