import { createStore, withProps } from '@ngneat/elf';
import { withActiveId, withEntities } from '@ngneat/elf-entities';
import { withRequestsStatus } from '@ngneat/elf-requests';

import { CatalogItem } from '~shared/types';

interface ItemProps {
	tempNewItem: null | CatalogItem;
}

export const catalogItemStore = createStore(
	{ name: 'catalog-items' },
	withEntities<CatalogItem>(),
	withActiveId(),
	withProps<ItemProps>({ tempNewItem: null }),
	withRequestsStatus<string>()
);
