import { Injectable } from '@angular/core';
import {
	HttpInterceptor,
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpResponse,
	HttpErrorResponse,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';

import { AlertService } from '../../core/services/alert.service';
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
	constructor(private alertService: AlertService) {}
	public intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return next.handle(req)
			.pipe(catchError((err) => {
				this.alertService.handleError(err);
				return throwError(() => err)
			}))
	}
}
