import { select } from '@ngneat/elf';
import { selectActiveEntity, selectAllEntities } from '@ngneat/elf-entities';
import { selectRequestStatus } from '@ngneat/elf-requests';
import { map } from 'rxjs/operators';

import { catalogItemStore } from './catalog-items.store';

export const catalogItemsSelector = {
	catalogItems$: catalogItemStore.pipe(selectAllEntities()),
	activeCatalogItem$: catalogItemStore.pipe(selectActiveEntity()),
	catalogItemsLoading$: catalogItemStore.pipe(
		selectRequestStatus('get-catalog-items'),
		map((status) => status.value === 'pending')
	),
	catalogItemLoading$: catalogItemStore.pipe(
		selectRequestStatus('get-catalog-item'),
		map((status) => status.value === 'pending')
	),
	createCatalogItemLoading$: catalogItemStore.pipe(
		selectRequestStatus('create-catalog-item'),
		map((status) => status.value === 'pending')
	),
	updateCatalogItemLoading$: catalogItemStore.pipe(
		selectRequestStatus('update-catalog-item'),
		map((status) => status.value === 'pending')
	),
	// deletePanelLoading$: itemStore.pipe(
	// 	selectRequestStatus('delete-panel'),
	// 	map((status) => status.value === 'pending')
	// ),
	// deleteArticleLoading$: itemStore.pipe(
	// 	selectRequestStatus('delete-article'),
	// 	map((status) => status.value === 'pending')
	// ),
	// updateItemDimensionLoading$: itemStore.pipe(
	// 	selectRequestStatus('update-item-dimension'),
	// 	map((status) => status.value === 'pending')
	// ),
	// activeItem$: itemStore.pipe(selectActiveEntity()),
	// tempNewItem$: itemStore.pipe(select((state) => state.tempNewItem)),
};
