import { AppRepository } from '../../../shared/store/app/app.repository';

import { ProjectsRepository } from './projects/projects.repository';
import { PartsRepository } from './parts/parts.repository';
import { ItemsRepository } from './items/items.repository';
import { EditorRepository } from './editor/editor.repository';
import { OutlineSetsRepository } from './outline-sets/outline-sets.repository';
import { CustomisationsRepository } from './customisations/customisations.repository';
import { CuttingListsRepository } from './cutting-lists/cutting-lists.repository';
import { CuttingListLinesRepository } from './cutting-list-lines/cutting-list-lines.repository';
import { CatalogItemsRepository } from './catalog-items/catalog-items.repository';
import { PartEditorRepository } from './part-editor/part-editor.repository';
import { HardwareItemsRepository } from './hardware-items/hardware-items.repository';

export const Repositories = [
	ProjectsRepository,
	PartsRepository,
	ItemsRepository,
	OutlineSetsRepository,
	EditorRepository,
	CustomisationsRepository,
	CuttingListsRepository,
	CuttingListLinesRepository,
	CatalogItemsRepository,
	PartEditorRepository,
	HardwareItemsRepository,
	AppRepository
];
