import { ItemEditor } from './itemEditor';
import { PartEditor } from './partEditor';

export class MovementManager {
	events: Array<{ funct: () => void; eventName: string }> = [];
	constructor(private readonly mouseVector, private readonly engine) {}

	dragObject(evt) {
		//// Check which axis is locked
		this.mouseVector.x =
			(evt.offsetX / this.engine.container.clientWidth) * 2 - 1;
		this.mouseVector.y =
			-(evt.offsetY / this.engine.container.clientHeight) * 2 + 1;
		this.engine.raycaster.setFromCamera(
			this.mouseVector,
			this.engine.camera
		);
	}

	moveObject(plane, planeIntersect, shift, object) {
		//// Move alongside the locked plane axis
		this.engine.raycaster.ray.intersectPlane(plane, planeIntersect);
		object.position.addVectors(planeIntersect, shift);
	}

	addMovementEvents(
		eventFunctions: Array<{
			funct: (evt?) => void;
			eventName: string;
			editor: ItemEditor | PartEditor;
		}>
	) {
		for (const funcInfo of eventFunctions) {
			funcInfo.funct = funcInfo.funct.bind(funcInfo.editor);
			this.engine.renderer.domElement.addEventListener(
				funcInfo.eventName,
				funcInfo.funct
			);
			this.events.push({
				funct: funcInfo.funct,
				eventName: funcInfo.eventName,
			});
		}
	}

	removeEvents() {
		if (!this.engine.renderer) {
			return;
		}

		for (const event of this.events) {
			this.engine.renderer.domElement.removeEventListener(
				event.eventName,
				event.funct
			);
		}
	}
}
