import { createStore, withProps } from '@ngneat/elf';
import { stateHistory } from '@ngneat/elf-state-history';

import { PartEditorState } from './part-editor.types';

export const partEditorStore = createStore(
	{ name: 'part-editor' },
	withProps<PartEditorState>({
		itemPositions: []
	})
)

export const partEditorStoreHistory = stateHistory(partEditorStore);
