import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { HardwareItem } from '~shared/types';
import { HardwareType } from '~shared/enums';

@Injectable()
export class HardwareService {
	baseUrl: string = `${environment.baseUrl}/hardware-api/v1`;

	constructor(private readonly http: HttpClient) {}

	public getHardwareItems(hardwareType: HardwareType, accountId: string): Observable<HardwareItem[]> {
		return this.http
			.get<HardwareItem[]>(`${this.baseUrl}/hardware/account/${accountId}/${hardwareType}`);
	}

	public toggleCatalogItemFavourite(hardwareType: HardwareType, hardwareCatalogLabel: string, accountId: string): Observable<HardwareItem> {
		return this.http
			.patch<HardwareItem>(`${this.baseUrl}/hardware/account/${accountId}/${hardwareType}/label/${hardwareCatalogLabel}/favourite`, {});
	}
}
