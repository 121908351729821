import { HardwareColour, HardwareFinish, HardwareHandleType, HardwareMount, HardwareStyle, HardwareType } from "~shared/enums";

import { ICoordinates } from "./dimension.types";

export interface HardwareItem {
	hardwareType: HardwareType;
	label: string;
	name: string;
	description: string;
	size: HardwareItemSize;
	obj: HardwareItemObject;
	variantList: HardwareItemVariant[];
	handleMount: HardwareMount;
	handleType: HardwareHandleType;
	style: HardwareStyle;
	isFavourite?: boolean;
}

interface HardwareItemSize {
	width: number;
	height: number;
	depth: number;
	diameter: number;
}

export interface HardwareItemObject {
	label: string;
	insertion_point: ICoordinates;
	rotation: ICoordinates;
	offset: ICoordinates;
	size: ICoordinates
}

interface HardwareItemSku {
	sku: string;
	supplierSky: string;
}

export interface HardwareItemVariant {
	sku: HardwareItemSku;
	colour: HardwareColour | HardwareFinish;
	supplier: string;
	size: HardwareItemSize;
	description: string;
	image_filenames: string[]
}
