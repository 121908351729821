import { CheckboxInputComponent } from './checkbox-input/checkbox-input.component';
import { SelectInputComponent } from './select-input/select-input.component';
import { TextInputComponent } from './text-input/text-input.component';
import { SearchInputComponent } from './search-input/search-input.component';
import { TextareaInputComponent } from './textarea-input/textarea-input.component';
import { ValidationMessageComponent } from './validation-message/validation-message.component';
import { IconComponent } from './icon/icon.component';
import { RadioInputComponent } from './radio-input/radio-input.component';
import { SingleRadioInputComponent } from './single-radio-input/single-radio-input.component';
import { TelInputComponent } from './tel-input/tel-input.component';
import { ConstructionParameterInputComponent } from './construction-parameter-input/construction-parameter-input.component';
import { LoadingComponent } from './loading/loading.component';
import { EmptyStateComponent } from './empty-state/empty-state.component';
import { DynamicInputComponent } from './dynamic-input/dynamic-input.component';
import { TagInputComponent } from './tag-input/tag-input.component';
import { CabinetBuilderComponent } from './cabinet-builder/cabinet-builder.component';
import { SharedConstructionParametersComponent } from './shared-construction-parameters/shared-construction-parameters.component';
import { HandlePositionInputComponent } from './handle-position-input/handle-position-input.component';
import { HandleSelectionInputComponent } from './handle-selection-input/handle-selection-input.component';
import { LoadingBarComponent } from './loading-bar/loading-bar.component';

export const Components = [
	CheckboxInputComponent,
	SelectInputComponent,
	TextInputComponent,
	TextareaInputComponent,
	ValidationMessageComponent,
	IconComponent,
	RadioInputComponent,
	TelInputComponent,
	LoadingComponent,
	EmptyStateComponent,
	ConstructionParameterInputComponent,
	SearchInputComponent,
	DynamicInputComponent,
	TagInputComponent,
	CabinetBuilderComponent,
	SharedConstructionParametersComponent,
	SingleRadioInputComponent,
	HandlePositionInputComponent,
	HandleSelectionInputComponent,
	LoadingBarComponent
];
