import { CommentsComponent } from './comments/comments.component';
import { EditorWrapperComponent } from './editor-wrapper/editor-wrapper.component';
import { ItemEditorComponent } from './item-editor/item-editor.component';
import { ItemDetailsRibbonComponent } from './item-details-ribbon/item-details-ribbon.component';
import { EditorConfigurationComponent } from './editor-configuration/editor-configuration.component';
import { PartEditorCanvasComponent } from './part-editor/part-editor.component';
import { NavigationPanelComponent } from './navigation-panel/navigation-panel.component';
import { NotificationBoardComponent } from './notification-board/notification-board.component';
import { ProjectNavigatorComponent } from './project-navigator/project-navigator.component';
import { ProjectHeaderComponent } from './project-header/project-header.component';
import { BoardSelectorComponent } from './board-selector/board-selector.component';
import { CustomisationComponent } from './customisation/customisation.component';
import { CuttingListNavigatorComponent } from './cutting-list-navigator/cutting-list-navigator.component';
import { PanelCutoutComponent } from './panel-cutout/panel-cutout.component';
import { PanelConnectionComponent } from './panel-connection/panel-connection.component';
import { PanelConstructionComponent } from './panel-construction/panel-construction.component';
import { HandleComponent } from './handle/handle.component';
import { HandleOffsetComponent } from './handle-offset/handle-offset.component';

export const Components = [
	CommentsComponent,
	EditorWrapperComponent,
	ItemEditorComponent,
	ItemDetailsRibbonComponent,
	NavigationPanelComponent,
	NotificationBoardComponent,
	EditorConfigurationComponent,
	ProjectNavigatorComponent,
	ProjectHeaderComponent,
	PartEditorCanvasComponent,
	BoardSelectorComponent,
	CustomisationComponent,
	CuttingListNavigatorComponent,
	PanelCutoutComponent,
	PanelConnectionComponent,
	PanelConstructionComponent,
	HandleComponent,
	HandleOffsetComponent
];
