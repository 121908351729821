import { createStore, withProps } from '@ngneat/elf';
import { withActiveId, withEntities } from '@ngneat/elf-entities';
import { withRequestsStatus } from '@ngneat/elf-requests';

import { Customisation } from '~shared/types';

export const customisationsStore = createStore(
	{ name: 'customisations' },
	withEntities<Customisation>(),
	withActiveId(),
	withRequestsStatus<string>()
);
