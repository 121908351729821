import { selectActiveEntity, selectAllEntities } from '@ngneat/elf-entities';
import { selectRequestStatus } from '@ngneat/elf-requests';
import { map } from 'rxjs/operators';

import { customisationsStore } from './customisations.store';

export const customisationsSelector = {
	customisations$: customisationsStore.pipe(selectAllEntities()),
	activeCustomisation$: customisationsStore.pipe(selectActiveEntity()),
	customisationsLoading$: customisationsStore.pipe(
		selectRequestStatus('get-customisations'),
		map((status) => status.value === 'pending')
	),
	updateLoading$: customisationsStore.pipe(
		selectRequestStatus('update-customisation'),
		map((status) => status.value === 'pending')
	),
	createLoading$: customisationsStore.pipe(
		selectRequestStatus('create-customisation'),
		map((status) => status.value === 'pending')
	),
	deleteLoading$: customisationsStore.pipe(
		selectRequestStatus('delete-customisation'),
		map((status) => status.value === 'pending')
	),
	createFileLoading$: customisationsStore.pipe(
		selectRequestStatus('create-file'),
		map((status) => status.value === 'pending')
	)
};
