import {
	Color,
	Mesh,
	MeshBasicMaterial,
	Vector2,
	Object3D,
	Group,
	Raycaster,
	Material,
} from 'three';

import { PanelType } from '~shared/enums';
import { getDefaultMaterial } from '~shared/helpers';
import { SelectionType } from '~shared/shared.types';

import { GenericItem, ShapeInfo } from './engine-render.service';
import { HelperFunctions } from './helperFunctions';

export class SelectionManager {
	selectedObjects: Mesh[] = [];
	selectedPrimaryObjects: ShapeInfo[] = [];
	lastIntersection?;
	constructor(
		private readonly raycaster: Raycaster,
		private readonly scene,
		private readonly camera
	) {}

	getIntersections(from: Vector2, isolatedItem?: GenericItem): Mesh {
		// Again, item editor only function.
		this.raycaster.setFromCamera(from, this.camera);

		const intersections = this.raycaster.intersectObjects(
			this.scene.children,
			true
		);

		if (intersections && intersections.length) {
			for (let intersect of intersections) {
				if (
					isolatedItem &&
					isolatedItem?.id === intersect.object?.userData?.isolationId
				) {
					this.lastIntersection = intersect;
					return intersect.object as Mesh;
				}

				if (
					!isolatedItem &&
					intersect.object.type != 'FloorPlan' &&
					!intersect.object.userData.isEdgeLine &&
					!intersect.object.userData.isClickableFace &&
					!intersect.object.userData.disallowSelection
				) {
					this.lastIntersection = intersect;
					return intersect.object as Mesh;
				}
			}
		}

		return undefined;
	}

	getSelectedShapesInfo(): Array<ShapeInfo> {
		return this.selectedObjects.map((object) => this.getShapeInfo(object));
	}

	getShapeInfo(object: Mesh): ShapeInfo {
		if (!object) {
			return null;
		}

		return {
			type: object.userData.type || object.parent.userData.type,
			object: object.userData.object || object.parent.userData.object,
		};
	}

	static getSelectionType(item?: any): SelectionType {
		if (!item) {
			return null;
		}

		if (item.type === 'face') {
			return SelectionType.FACE;
		}

		if (item.articleZone) {
			return SelectionType.ARTICLE_ZONE;
		}

		if (item.panelType === PanelType.FRONT) {
			return SelectionType.DOOR;
		}

		if (item.article) {
			return SelectionType.ARTICLE;
		}
	}

	handleObjectSelection(object: Mesh) {
		for (let child of object.parent.children) {
			this.selectedObjects.push(child as Mesh);
		}

		this.selectedObjects.push(object);
		this.selectedPrimaryObjects.push(this.getShapeInfo(object));
	}

	deselectAll(transparentPanelTypes: PanelType[], isolatedItem: GenericItem) {
		this.removeHighlight(
			this.scene.children,
			transparentPanelTypes,
			isolatedItem
		);

		this.selectedObjects = [];
		this.selectedPrimaryObjects = [];
	}

	private removeHighlight(
		children,
		transparentPanelTypes: PanelType[],
		isolatedItem: GenericItem
	): void {
		children.forEach((item) => {
			if (
				item.userData.isEdgeLine ||
				item.userData.type == 'FloorPlan'
			) {
				return;
			}

			item.userData.highlighted = false;
			// If the mesh had a temporary mat applied, use that to remove the highlight
			if (item.userData?.temporaryMaterial) {
				item.material?.dispose();
				item.material = item.userData?.temporaryMaterial;

				return this.removeHighlight(
					item.children || [],
					transparentPanelTypes,
					isolatedItem
				);
			}

			if (item.userData?.originalMaterial) {
				item.material?.dispose();
				item.material = item.userData?.originalMaterial;

				return this.removeHighlight(
					item.children || [],
					transparentPanelTypes,
					isolatedItem
				);
			}

			this.removeHighlight(
				item.children || [],
				transparentPanelTypes,
				isolatedItem
			);
		});
	}

	getCollisionObject(
		from: Vector2,
		checkCondition: (object) => boolean
	): {
		object: Group | Object3D;
		intersection;
	} {
		// This function is ony called in the part editor
		this.raycaster.setFromCamera(from, this.camera);

		const intersections = this.raycaster.intersectObjects(
			this.scene.children,
			true
		);

		if (intersections && intersections.length) {
			for (let intersect of intersections) {
				if (
					intersect.object.userData.type != 'FloorPlan' &&
					!intersect.object.userData.isEdgeLine &&
					!intersect.object.userData.isClickableFace &&
					!intersect.object.userData.disallowSelection &&
					checkCondition(intersect.object)
				) {
					return {
						object: intersect.object.parent.parent,
						intersection: intersect,
					};
				}
			}
		}

		return undefined;
	}

	selectObject(mouseX, mouseY, container, isolatedItem?: GenericItem) {
		// This function is used in the item editor

		const mouseVector = new Vector2();
		mouseVector.x = (mouseX / container.clientWidth) * 2 - 1;
		mouseVector.y = -(mouseY / container.clientHeight) * 2 + 1;
		const selectObject = this.getIntersections(mouseVector, isolatedItem);

		const isSelectionOfDifferentType = !this.selectedPrimaryObjects.find(
			(obj) => {
				return (
					SelectionManager.getSelectionType(obj.object) ===
					SelectionManager.getSelectionType(
						selectObject?.userData?.object as GenericItem
					)
				);
			}
		);

		return [selectObject, isSelectionOfDifferentType];
	}

	// This function is only used for the partEditor
	highlightGroup(isSelected: boolean, selectedObject: Group | null) {
		if (!selectedObject) {
			return;
		}

		for (const object of selectedObject.children) {
			this.highlightGroupObject(isSelected, object);
		}
	}

	private highlightGroupObject(isSelected: boolean, object: Object3D): void {
		if (object?.children) {
			object.children.forEach((childGroup) => this.highlightGroupObject(isSelected, childGroup))
		}

		if (!object || !(object as Mesh).material) {
			return;
		}

		if (((object as Mesh).material as Material)?.type === "MeshStandardMaterial") {
			if (object.userData.highlighted && isSelected) {
				// If it was already highlighted and should be highlighted, do nothing
				return;
			}

			if (object.userData.highlighted && !isSelected) {
				// If it was already highlighted and should NOT be selected, restore the og texture
				object.userData.highlighted = false;
				(object as Mesh).material = object.userData.originalMaterial
				return;
			}

			object.userData.originalMaterial = (object as Mesh).material;
			object.userData.highlighted = true;

			(object as Mesh).material = HelperFunctions.bufferMaterialMap({
				...object?.userData?.object?.mesh,
				colour: '#175832',
			});
		}
	}
}
