import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Component, forwardRef, Input, OnDestroy, OnInit, OnChanges } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';

@Component({
	selector: 'app-tag-input',
	templateUrl: './tag-input.component.html',
	providers: [
		{
			provide: [],
			useExisting: forwardRef(() => TagInputComponent),
			multi: true,
		},
	],
})
export class TagInputComponent implements OnInit, OnDestroy, ControlValueAccessor, OnChanges {
	@Input() label?: string;
	@Input() placeholder = '';
	@Input() disabled = false;
	@Input() loading = false;
	@Input() options = [];
	@Input() singleSelection = false;

	private componentDestroyed$: Subject<boolean> = new Subject<boolean>();

	public control: UntypedFormControl = new UntypedFormControl('');
	public updateValue = (_: any) => {};

	constructor(public ngControl: NgControl) {
		ngControl.valueAccessor = this;
	}

	private propagateChange(value: any): void {
		if (this.updateValue) {
			return this.updateValue(value);
		}

		if (this.control) {
			this.control.setValue(value);
		}
	}

	public ngOnInit(): void {
		if (this.disabled) {
			this.control.disable();
		}

		this.control.valueChanges.pipe(
			takeUntil(this.componentDestroyed$),
		).subscribe((value) => {
			this.propagateChange(value);
		});
	}

	public handleClick(value: string): void {
		if (this.control.value.includes(value)) {
			return this.control.setValue(this.control.value.filter((v) => v !== value));
		}

		if (this.singleSelection) {
			return this.control.setValue([value])
		}

		this.control.setValue([...this.control.value, value])
	}

	public ngOnChanges(changes: any): void {
		if (changes.options) {
			// Update rendering or something
		}
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}

	public writeValue(value: any): void {
		setTimeout(() => this.control.setValue(value));
	}

	public registerOnChange(fn: any): void {
		this.updateValue = fn;
	}

	public registerOnTouched(): void {}

	public setDisabledState(isDisabled: boolean) {
		isDisabled ? this.control.disable({
			emitEvent: false
		}) : this.control.enable({
			emitEvent: false
		})
		this.disabled = isDisabled;
	}
}
