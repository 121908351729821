import { ConstructionParameter, MeasurementUnit } from "~shared/enums"

import { IConstraint } from './constraint.types';

export interface IParameterValue {
	parameterType: ConstructionParameter;
	value?: number;
	modifiable?: boolean;
	measurementUnit: MeasurementUnit;
	constraint?: IConstraint;
}

export interface IParameterSet {
	parameters: IParameterValue[];
}
