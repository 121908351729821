import { createStore, withProps } from '@ngneat/elf';
import { withActiveId, withEntities } from '@ngneat/elf-entities';
import { withRequestsStatus } from '@ngneat/elf-requests';

import { Part } from '~shared/types';

export const partStore = createStore(
	{ name: 'parts' },
	withEntities<Part>(),
	withActiveId(),
	withRequestsStatus<string>()
);
