import { Shape } from "./shape.types";

export interface ArticleZoneMeshMaterial {
	component: string,
	opacity: number,
	colour: string,
	transparent: boolean
}

export interface IArticleZone {
	id: string;
	shape: Shape;
	isVisible: boolean;
	mesh: ArticleZoneMeshMaterial;
}
