import { createStore, withProps } from '@ngneat/elf';
import { withActiveId, withEntities } from '@ngneat/elf-entities';
import { withRequestsStatus } from '@ngneat/elf-requests';

import { CuttingListLine } from '~shared/types';

export const cuttingListLineStore = createStore(
	{ name: 'cutting-list-line' },
	withEntities<CuttingListLine>(),
	withActiveId(),
	withRequestsStatus<string>()
);
