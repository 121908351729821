import { Pipe, PipeTransform } from '@angular/core';

import { ProjectStatus } from '~shared/enums';
import { Project } from '~shared/types';

@Pipe({
	name: 'projectfilter',
	pure: false,
})
export class ProjectFilterPipe implements PipeTransform {
	transform(items: Project[], status: ProjectStatus): any {
		if (!items || !status) {
			return items;
		}

		return items.filter((item) => item.status === status);
	}
}
