import { selectActiveEntity, selectAllEntities } from '@ngneat/elf-entities';
import { selectRequestStatus } from '@ngneat/elf-requests';
import { map } from 'rxjs/operators';

import { partStore } from './parts.store';

export const partsSelector = {
	parts$: partStore.pipe(selectAllEntities()),
	activePart$: partStore.pipe(selectActiveEntity()),
	partsLoading$: partStore.pipe(
		selectRequestStatus('parts'),
		map((status) => status.value === 'pending')
	)
};
