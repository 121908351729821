import { Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-empty-state',
	templateUrl: './empty-state.component.html',
})
export class EmptyStateComponent {
	@Input() empty: boolean;
	@Input() text = 'Geen data gevonden';
}
