import { Component, Inject, EventEmitter, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { filter, map, Observable, switchMap, take } from 'rxjs';

import { PartStatuses } from '~modules/projects/projects.const';

@Component({
	templateUrl: "./delivery.modal.html"
})
export class DeliveryModalComponent implements OnInit {
	public control = new FormControl<string>('')

	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		public dialogRef: MatDialogRef<DeliveryModalComponent>,
	) {}

	public ngOnInit(): void {
		if (!this.data.expectedDeliveryDate) {
			return;
		}

		this.control.setValue(this.data.expectedDeliveryDate)
	}
}
