import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Auth } from 'aws-amplify';

import { AuthService } from 'src/app/core/services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
	constructor(private _router: Router, private authService: AuthService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		// const currentUser = this.authService.currentUserValue;
		// if (currentUser) {
		//   // logged in so return true
		//   return true;
		// }

		// // not logged in so redirect to login page with the return url
		// this.authService.logout();
		// return false;
		const redirectUrl = state.url === '/auth/logout' ? '/' : state.url;
		return this._check(redirectUrl);
		// return true;
	}

	/**
	 * Can activate child
	 *
	 * @param childRoute
	 * @param state
	 */
	canActivateChild(
		childRoute: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	) {
		const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
		return this._check(redirectUrl);
	}

	/**
	 * Check the authenticated status
	 *
	 * @param redirectURL
	 * @private
	 */
	private async _check(redirectTo: string): Promise<boolean> {
		return await Auth.currentAuthenticatedUser()
			.then(() => {
				return true;
			})
			.catch(() => {
				this.authService.logout(redirectTo);
				//  this._router.navigate(['/auth/login'], {queryParams: {redirectURL}});
				return false;
			});
	}
}
