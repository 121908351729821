import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { Item, Part } from '~shared/types';
import { ItemsRepository } from '~modules/projects/store/items/items.repository';
import { PartsRepository } from '~modules/projects/store/parts/parts.repository';
import {
	BackComponent,
	BaseComponent,
	CarcaseComponent,
	DoorComponent,
	DrawerComponent,
	DynamicDividerComponent,
	FaceComponent,
	FillerConnectionComponent,
	FillerTComponent,
	FillerLComponent,
	FillerBComponent,
	FillerRComponent,
	HorizontalDividerComponent,
	ItemComponent,
	LayoutComponent,
	OutlineFrontComponent,
	OutlineSideComponent,
	OutlineTopComponent,
	PartComponent,
	VerticalDividerComponent,
	FrameComponent,
	SuspendedRodComponent,
	SidemountedRodComponent,
	GeneralComponent,
} from '~modules/projects/configuration-components';
import { ConfigurationItem } from '~modules/projects/components/editor-configuration/editor-configuration.component';
import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { DesignerMode } from '~shared/enums';

@Component({
	templateUrl: './cabinet-builder.page.html',
})
export class CabinetBuilderComponent implements OnInit, OnDestroy {
	public activeItem$: Observable<Item>;
	public activePart$: Observable<Part>;
	public fullscreen$: Observable<boolean>;
	public configurationControllers: ConfigurationItem[] = [];
	public activeConfigurationPanel$: Observable<string>
	public defaultConfigurationController: ConfigurationItem;
	public designerMode$: Observable<DesignerMode>;

	private componentDestroyed$: Subject<boolean> = new Subject();

	constructor(
		private readonly itemsRepository: ItemsRepository,
		private readonly partsRepository: PartsRepository,
		private readonly editorRepository: EditorRepository,
	) {}

	// Get projects for side-navbar
	public ngOnInit(): void {
		this.designerMode$ = this.editorRepository.designerMode$;
		this.fullscreen$ = this.editorRepository.partEditorFullscreen$;
		this.activeItem$ = this.itemsRepository.activeItem$;
		this.activePart$ = this.partsRepository.activePart$;
		this.activeConfigurationPanel$ = this.editorRepository.activeConfigurationPanel$;
		this.defaultConfigurationController = new ConfigurationItem(GeneralComponent, 'GENERAL')
		this.configurationControllers = [
			new ConfigurationItem(OutlineTopComponent, 'OUTLINE_TOP'),
			new ConfigurationItem(OutlineFrontComponent, 'OUTLINE_FRONT'),
			new ConfigurationItem(OutlineSideComponent, 'OUTLINE_SIDE'),
			new ConfigurationItem(HorizontalDividerComponent, 'XZ_DIVIDER'),
			new ConfigurationItem(VerticalDividerComponent, 'YZ_DIVIDER'),
			new ConfigurationItem(DynamicDividerComponent, 'SHELVES'),
			new ConfigurationItem(SuspendedRodComponent, 'SUSPENDED_ROD'),
			new ConfigurationItem(SidemountedRodComponent, 'SIDEMOUNTED_ROD'),
			new ConfigurationItem(FrameComponent, 'FRAME'),
			new ConfigurationItem(DoorComponent, 'DOOR'),
			new ConfigurationItem(BackComponent, 'BACK'),
			new ConfigurationItem(CarcaseComponent, 'CARCASE'),
			new ConfigurationItem(ItemComponent, 'ITEM'),
			new ConfigurationItem(FillerTComponent, 'FILLER_T'),
			new ConfigurationItem(FillerBComponent, 'FILLER_B'),
			new ConfigurationItem(FillerLComponent, 'FILLER_L'),
			new ConfigurationItem(FillerRComponent, 'FILLER_R'),
			new ConfigurationItem(FillerConnectionComponent, 'FILLER_CONNECTION'),
			new ConfigurationItem(BaseComponent, 'BASE'),
			new ConfigurationItem(PartComponent, 'PART'),
			new ConfigurationItem(LayoutComponent, 'LAYOUT'),
			new ConfigurationItem(FillerConnectionComponent, 'FILLER_SUPPORT'),
			new ConfigurationItem(DrawerComponent, 'DRAWER'),
			new ConfigurationItem(FaceComponent, 'FACE'),
		];
	}

	// Stop loading when leaving
	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}
}
