import { UserRole } from "~modules/auth/types/user.types";

export class User {
	id: number;
	password: string;
	email: string;
	firstname: string;
	familyname: string;
	phonenumber: string;
	userRole: UserRole;
}
