import { OutlineTopComponent } from './outline-top/outline-top.component';
import { OutlineFrontComponent } from './outline-front/outline-front.component';
import { OutlineSideComponent } from './outline-side/outline-side.component';
import { HorizontalDividerComponent } from './horizontal-divider/horizontal-divider.component';
import { VerticalDividerComponent } from './vertical-divider/vertical-divider.component';
import { DynamicDividerComponent } from './dynamic-divider/dynamic-divider.component';
import { DoorComponent } from './door/door.component';
import { BackComponent } from './back/back.component';
import { CarcaseComponent } from './carcase/carcase.component';
import { ItemComponent } from './item/item.component';
import { FillerTComponent } from './filler-t/filler-t.component';
import { FillerBComponent } from './filler-b/filler-b.component';
import { FillerLComponent } from './filler-l/filler-l.component';
import { FillerRComponent } from './filler-r/filler-r.component';
import { FillerConnectionComponent } from './filler-connection/filler-connection.component';
import { BaseComponent } from './base/base.component';
import { LayoutComponent } from './layout/layout.component';
import { PartComponent } from './part/part.component';
import { DrawerComponent } from './drawer/drawer.component';
import { FrameComponent } from './frame/frame.component';
import { FaceComponent } from './face/face.component';
import { SuspendedRodComponent } from './suspended-rod/suspended-rod.component';
import { SidemountedRodComponent } from './sidemounted-rod/sidemounted-rod.component';
import { GeneralComponent } from './general/general.component';

export { OutlineTopComponent } from './outline-top/outline-top.component';
export { OutlineFrontComponent } from './outline-front/outline-front.component';
export { OutlineSideComponent } from './outline-side/outline-side.component';
export { HorizontalDividerComponent } from './horizontal-divider/horizontal-divider.component';
export { VerticalDividerComponent } from './vertical-divider/vertical-divider.component';
export { DynamicDividerComponent } from './dynamic-divider/dynamic-divider.component';
export { DoorComponent } from './door/door.component';
export { BackComponent } from './back/back.component';
export { CarcaseComponent } from './carcase/carcase.component';
export { ItemComponent } from './item/item.component';
export { FillerTComponent } from './filler-t/filler-t.component';
export { FillerBComponent } from './filler-b/filler-b.component';
export { FillerLComponent } from './filler-l/filler-l.component';
export { FillerRComponent } from './filler-r/filler-r.component';
export { FillerConnectionComponent } from './filler-connection/filler-connection.component';
export { BaseComponent } from './base/base.component';
export { LayoutComponent } from './layout/layout.component';
export { PartComponent } from './part/part.component';
export { DrawerComponent } from './drawer/drawer.component';
export { FaceComponent } from './face/face.component';
export { FrameComponent } from './frame/frame.component';
export { SuspendedRodComponent } from './suspended-rod/suspended-rod.component';
export { SidemountedRodComponent } from './sidemounted-rod/sidemounted-rod.component';
export { GeneralComponent } from './general/general.component';

export const ConfigurationComponents = [
	OutlineTopComponent,
	OutlineFrontComponent,
	OutlineSideComponent,
	HorizontalDividerComponent,
	VerticalDividerComponent,
	DynamicDividerComponent,
	DoorComponent,
	BackComponent,
	CarcaseComponent,
	ItemComponent,
	FillerTComponent,
	FillerBComponent,
	FillerLComponent,
	FillerRComponent,
	FillerConnectionComponent,
	BaseComponent,
	LayoutComponent,
	PartComponent,
	FaceComponent,
	DrawerComponent,
	FrameComponent,
	SuspendedRodComponent,
	SidemountedRodComponent,
	GeneralComponent
]
