import { createStore, withProps } from '@ngneat/elf';
import { withActiveId, withEntities } from '@ngneat/elf-entities';
import { withRequestsStatus } from '@ngneat/elf-requests';

import { Item } from '~shared/types';

interface ItemProps {
	tempNewItem: null | Item;
	partItems: Item[];
}

export const itemStore = createStore(
	{ name: 'items' },
	withEntities<Item>(),
	withActiveId(),
	withProps<ItemProps>({ tempNewItem: null, partItems: [] }),
	withRequestsStatus<string>()
);
