import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { filter, Subject, takeUntil } from "rxjs";

import { AuthService } from "~core/services/auth.service";
import { UserRole } from "~modules/auth/types/user.types";

@Directive({
	selector: '[appUserRole]',
})
export class UserRoleDirective implements OnInit, OnDestroy {
	@Input() appUserRole: UserRole[];

	private directiveDestroyed$: Subject<boolean> = new Subject();

	constructor(
		private readonly templateRef: TemplateRef<unknown>,
        private readonly viewContainer: ViewContainerRef,
		private readonly authService: AuthService
	) {}

	public ngOnInit(): void {
		this.authService.currentUser$
			.pipe(
				filter((user) => !!user),
				takeUntil(this.directiveDestroyed$)
			)
			.subscribe((user) => {
				this.viewContainer.clear();

				if (this.appUserRole.includes(user?.userRole)) {
					return this.viewContainer.createEmbeddedView(this.templateRef);
				}

				this.viewContainer.clear();
			})
	}

	public ngOnDestroy(): void {
		this.directiveDestroyed$.next(true);
		this.directiveDestroyed$.complete()
	}
}
