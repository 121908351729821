import { Injectable } from '@angular/core';
import { setProps } from '@ngneat/elf';

import { appStore } from './app.store';
import { appSelector } from './app.selectors';

@Injectable()
export class AppRepository {
	public loading$ = appSelector.loading$

	public setLoading(loading: boolean): void {
		appStore.update(
			setProps({
				loading
			})
		);
	}
}
