import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {  combineLatest, debounceTime, EMPTY, filter, map, Observable, of, skip, startWith, Subject, switchMap, take, takeUntil, tap } from 'rxjs';

import { AuthService } from '~core/services/auth.service';
import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { HardwareItemsRepository } from '~modules/projects/store/hardware-items/hardware-items.repository';
import { ItemsRepository } from '~modules/projects/store/items/items.repository';
import { GenericItem } from '~shared/components/cabinet-builder/engine-render.service';
import { HardwareType } from '~shared/enums';
import { findUpdatedPanel } from '~shared/helpers';
import { HardwareItem, Item, HardwareItemVariant } from '~shared/types';

@Component({
	selector: 'app-handle',
	templateUrl: './handle.component.html'
})

export class HandleComponent implements OnInit, OnDestroy {
	@Input() partId: string;
	@Input() disabled: boolean = false;

	public hardwareItems$: Observable<HardwareItem[]>;
	public disableFields$: Observable<boolean>;
	public handlePositionControl = new FormControl();
	public handleSelectionControl = new FormControl({
		label: 'none',
		variant: 0
	});
	public selectedItem$: Observable<GenericItem>
	public isOpen = true;
	public loading = false;

	private componentDestroyed$: Subject<boolean> = new Subject();

	constructor(
		private editorRepository: EditorRepository,
		private authService: AuthService,
		private hardwareItemsRepository: HardwareItemsRepository,
		private itemsRepository: ItemsRepository,
	) { }

	public ngOnInit(): void {
		this.hardwareItems$ = this.hardwareItemsRepository.hardwareItems$
			.pipe(map((items) => items.filter((item) => item.isFavourite)));

		this.authService.currentUser$
			.pipe(
				takeUntil(this.componentDestroyed$),
				filter((user) => !!user),
				switchMap((user) => this.hardwareItemsRepository.getHardwareItems(HardwareType.HANDLE, user.account.id)),
				take(1),
			)
			.subscribe();

		this.selectedItem$ = this.editorRepository.selectedItems$
			.pipe(
				map(([item]) => item),
				// 	filter((item) => !!item && !!item.board?.label),
				// 	distinctUntilChanged((previous, current) => previous.board.label === current.board.label),
				// 	tap((item) => {
				// 		this.loading = true;
				// 		this.boardsService.getCatalogBoard(item.board.label)
				// 			.pipe(take(1))
				// 			.subscribe((result) => {
				// 				this.loading = false;
				// 				this.board$.next(result);
				// 			})
				// 	})
			);

		this.editorRepository.selectedItems$
			.pipe(
				takeUntil(this.componentDestroyed$),
				switchMap(([item]) => this.hardwareItems$.pipe(
					map((hardwareItems) => ({
						hardwareItems,
						item
					}))
				)),
				tap(({ item, hardwareItems }) => {
					if (!item?.handleConnection) {
						return this.handleSelectionControl.setValue({
							label: 'none',
							variant: 0
						}, { emitEvent: false });
					}

					const hardwareItem = hardwareItems.find((hardwareItem) => hardwareItem.label === item.handleConnection.hardware[0]?.catalogItem?.label);
					const variantIndex = hardwareItem?.variantList.findIndex((variant) => variant.colour === item.handleConnection.hardware[0]?.variant.colour)
					this.handleSelectionControl.setValue({
						label: item.handleConnection.hardware[0]?.catalogItem?.label,
						variant: variantIndex === -1 ? 0 : variantIndex || 0
					}, { emitEvent: false });
					this.handlePositionControl.setValue(item.handleConnection.location || item.handleConnection.locations[0], { emitEvent: false })
				})
			)
			.subscribe()

		combineLatest([
			this.handleSelectionControl.valueChanges.pipe(startWith(null)),
			this.handlePositionControl.valueChanges.pipe(startWith(null))
		])
			.pipe(
				skip(1),
				debounceTime(100), // TODO: figure out why this is needed
				takeUntil(this.componentDestroyed$),
				switchMap(() => combineLatest([
					this.itemsRepository.activeItem$,
					this.selectedItem$,
					this.hardwareItems$
				])
					.pipe(
						take(1),
						switchMap(([item, door, hardwareItems]) => {
							if (this.handleSelectionControl.value?.label !== 'none') {
								return of([item, door, hardwareItems]);
							}

							this.itemsRepository.deleteHardwareFromPanel(item.partId, item.id, door.article?.id, door.id)
								.pipe(
									take(1),
									switchMap((item) => this.editorRepository.selectedItems$.pipe(take(1), map((selectedItems) => ({ selectedItems, item }))))
								)
								.subscribe(({ selectedItems, item }) => {
									this.editorRepository.setSelectedItems([findUpdatedPanel(item, selectedItems[0])]);
								})

							return EMPTY;
						}),
						map(([item, door, hardwareItems]: [Item, GenericItem, HardwareItem[]]) => {
							const handle = hardwareItems.find((item) => item.label === this.handleSelectionControl.value.label);
							const variant = handle.variantList[this.handleSelectionControl.value.variant];
							const position = this.handlePositionControl.value;

							return { handle, variant, item, door, position };
						})
					)),
				switchMap(({ handle, variant, item, door, position }) => this.handleHandleSelection(item, door, handle, variant, position)),
				switchMap((item) => this.editorRepository.selectedItems$.pipe(take(1), map((selectedItems) => ({ selectedItems, item }))))
			)
			.subscribe(({ selectedItems, item }) => {
				this.editorRepository.setSelectedItems([findUpdatedPanel(item, selectedItems[0])]);
			})

		this.disableFields$ = this.editorRepository.disableFields$
	}

	public toggleItem(): void {
		this.isOpen = !this.isOpen
	}

	private handleHandleSelection(item: Item, door: GenericItem, handle: HardwareItem, variant: HardwareItemVariant, location: string): Observable<Item> {
		if (!door.handleConnection || door.handleConnection.hardware[0].catalogItem?.label !== handle.label || door.handleConnection.hardware[0]?.variant.colour !== variant.colour) {
			return this.itemsRepository.addHardwareToPanel(item.partId, item.id, door.article?.id, door.id, {
				hardwareType: HardwareType.HANDLE,
				label: handle.label,
				colour: variant.colour
			}).pipe(take(1))
		}

		return this.itemsRepository.updatePanelConnection(item.partId, item.id, door.article?.id, door.id, 'HANDLE', {
			location,
		}).pipe(take(1))
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}
}
