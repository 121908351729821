import { createStore, withProps } from '@ngneat/elf';
import { withActiveId, withEntities } from '@ngneat/elf-entities';
import { withRequestsStatus } from '@ngneat/elf-requests';

import { OutlineSet } from '~shared/types';

export const outlineSetStore = createStore(
	{ name: 'outline-sets' },
	withEntities<OutlineSet, 'label'>({ idKey: 'label'}),
	withActiveId(),
	withRequestsStatus<`outline-sets` | `outline-sets-${string}`>()
);
