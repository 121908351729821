import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, first, map, Observable, of, Subject, switchMap, take, takeUntil, tap, BehaviorSubject } from 'rxjs';

import { Part, IArticle, IArticleZone, Item, Project } from '~shared/types';
import { DesignerMode } from '~shared/enums/designer-mode.enum';
import { PanelType, ThreeObjectTypeEnum } from '~shared/enums';
import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { ItemsRepository } from '~modules/projects/store/items/items.repository';
import { GenericItem, ShapeInfo } from '~shared/components/cabinet-builder/engine-render.service';
import { EditorMode } from '~modules/projects/store/editor/editor.types';

@Component({
	selector: 'app-item-editor',
	templateUrl: './item-editor.component.html'
})
export class ItemEditorComponent implements OnInit, OnDestroy {
	@Input() mode: DesignerMode;
	@Input() project: Project;
	@Input() part: Part;
	@Input() item: Item;

	public buttons$: Observable<{ icon: string; action: string }[]>;
	public showDivider = false;
	public selectedId: string;
	public editorMode: EditorMode = EditorMode.DEFAULT;
	public shapeInfo: Array<ShapeInfo>;
	public showDoor = false;
	public doorsTransparent$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public transparentPanelTypes$: Observable<PanelType[]>;
	public isolatedItem$: Observable<GenericItem>;
	public selectedArticleZones$: Observable<IArticleZone[]>
	public selectedArticles$: Observable<IArticle[]>
	public selectedDoors$: Observable<GenericItem[]>
	public selectedPanel$: Observable<GenericItem>
	public activeItem$: Observable<Item>;
	public mode$: Observable<EditorMode>;
	public hasFrontPanelType$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	private componentDestroyed$: Subject<boolean> = new Subject();

	constructor(
		private readonly editorRepository: EditorRepository,
		private readonly itemsRepository: ItemsRepository
	) {}

	public ngOnInit(): void {
		this.activeItem$ = this.itemsRepository.activeItem$
			.pipe(tap((item) => {
				this.editorRepository.setMode(EditorMode.DEFAULT)

				const doorIds = this.getDoorIds(item);
				this.hasFrontPanelType$.next(!!doorIds?.length);
			}));
		this.selectedArticleZones$ = this.editorRepository.selectedArticleZones$;
		this.selectedArticles$ = this.editorRepository.selectedArticles$;
		this.transparentPanelTypes$ = this.editorRepository.transparentPanelTypes$;
		this.isolatedItem$ = this.editorRepository.isolatedItem$;
		this.selectedDoors$ = this.editorRepository.selectedDoors$;
		this.mode$ = this.editorRepository.mode$
			.pipe(tap((mode) => this.editorMode = mode));
		this.selectedPanel$ = this.editorRepository.selectedItems$
			.pipe(map(([panel]) => panel));

		this.editorRepository.selectedDoors$
			.pipe(
				takeUntil(this.componentDestroyed$),
				switchMap((items) => this.editorRepository.openDoorIds$
					.pipe(
						take(1),
						map((openDoorIds) => ({ openDoorIds, items }))
					)
				)
			)
			.subscribe(({ openDoorIds, items }) => {
				if (items.length === 0 && openDoorIds.length > 0) {
					return this.doorsTransparent$.next(true);
				}

				if (items.length !== 1) {
					return this.doorsTransparent$.next(false);
				}

				if (!openDoorIds.includes(items[0]?.id)) {
					return this.doorsTransparent$.next(false);
				}

				return this.doorsTransparent$.next(true);
			});

		this.editorRepository.openDoorIds$
			.pipe(
				takeUntil(this.componentDestroyed$),
			)
			.subscribe((ids) => {
				if (ids.length === 0) {
					this.doorsTransparent$.next(false)
				}
			})

		this.buttons$ = combineLatest([
			this.activeItem$,
			this.selectedArticleZones$,
			this.selectedArticles$,
			this.selectedDoors$,
			this.selectedPanel$,
			this.editorRepository.disableFields$,
			this.itemsRepository.deletePanelLoading$,
			this.itemsRepository.deleteArticleLoading$,
			this.itemsRepository.recreateBackLoading$,
			this.editorRepository.mode$,
		])
			.pipe(
				takeUntil(this.componentDestroyed$),
				switchMap(([
					item,
					articleZones,
					articles,
					doors,
					panel,
					disableFields,
					deletePanelLoading,
					deleteArticleLoading,
					recreateBackLoading,
					mode
				]) => {
					if (mode === EditorMode.SELECT_ARTICLE_ZONES) {
						return of([{
							icon: 'undo',
							action: 'RESET_MODE',
							disabled: false,
							tooltip: 'Verlaat deur modus'
						}])
					}

					if (articles.length || doors.length) {
						const parentArticle = item.articles?.find((article) => article.id === articles[0]?.parentArticleId);
						const deletePossible = panel?.removable || articles[0]?.removable || doors[0]?.removable;
						const actions = [];
						actions.push({
							icon: 'delete',
							action: 'DELETE',
							disabled: disableFields || !deletePossible,
							loading: deletePanelLoading || deleteArticleLoading,
							tooltip: (disableFields || !deletePossible) ? 'Dit item kan niet verwijderd worden' : 'Verwijder artikel'
						})
						if (parentArticle?.removable) {
							actions.push({
								icon: 'delete-parent',
								action: 'DELETE_PARENT',
								loading: deletePanelLoading || deleteArticleLoading,
								tooltip: 'Verwijder artikelgroep'
							})
						}
						return of(actions)
					}

					if (panel?.articleZoneBackFace) {
						return of([{
							icon: 'recreate-back',
							action: 'RECREATE_BACK',
							disabled: false,
							loading: recreateBackLoading,
							tooltip: 'Rug opnieuw aanmaken'
						}])
					}

					const oneArticleZoneSelected = (item?.articleZones?.length > 1 && articleZones.length !== 1) || disableFields;
					return of([{
						icon: 'vertical-divider',
						action: 'YZ_DIVIDER',
						disabled: oneArticleZoneSelected,
						tooltip: oneArticleZoneSelected && 'Kies eerst een layout'
					}, {
						icon: 'horizontal-divider',
						action: 'XZ_DIVIDER',
						disabled: oneArticleZoneSelected,
						tooltip: oneArticleZoneSelected && 'Kies eerst een layout'
					}, {
						icon: 'dynamic-divider',
						action: 'SHELVES',
						disabled: oneArticleZoneSelected,
						tooltip: oneArticleZoneSelected && 'Kies eerst een layout'
					}, {
						icon: 'suspended-rod',
						action: 'SUSPENDED_ROD',
						disabled: oneArticleZoneSelected
					}, {
						icon: 'sidemounted-rod',
						action: 'SIDEMOUNTED_ROD',
						disabled: oneArticleZoneSelected
					}, {
						icon: 'drawer',
						action: 'DRAWER',
						disabled: oneArticleZoneSelected,
						tooltip: oneArticleZoneSelected && 'Kies eerst een layout'
					}, {
						icon: 'door',
						action: 'DOOR',
						disabled: disableFields || articleZones.length > 0
					}])
				})
			)
	}

	public toggleDoorTransparency(shouldOpen: boolean): void {
		combineLatest([this.editorRepository.selectedDoors$, this.editorRepository.openDoorIds$, this.itemsRepository.activeItem$])
			.pipe(
				take(1),
				map(([doors, existingOpenDoorsIds, item]) => [doors.map((door) => door.id), existingOpenDoorsIds, item]),
				tap(([doorIds, existingOpenDoorsIds, item]: [string[], string[], Item]) => {
					const allDoorIds = this.getDoorIds(item);
					if (doorIds.length === 0 && !shouldOpen) {
						this.doorsTransparent$.next(false);
						return this.editorRepository.setOpenDoorsIds([]);
					}

					if (doorIds.length === 0 && shouldOpen) {
						this.doorsTransparent$.next(true);
						return this.editorRepository.setOpenDoorsIds(allDoorIds);
					}

					if (shouldOpen) {
						this.doorsTransparent$.next(true);
						return this.editorRepository.setOpenDoorsIds([...doorIds, ...existingOpenDoorsIds])
					}

					this.doorsTransparent$.next(false);
					this.editorRepository.setOpenDoorsIds(existingOpenDoorsIds.filter((id) => !doorIds.includes(id)))
				})
			)
			.subscribe();
	}

	public exitIsolationMode(): void {
		this.editorRepository.setIsolatedItem(null);
		this.editorRepository.setActiveConfigurationPanel(null);
	}

	public enterIsolationMode(): void {
		this.editorRepository.selectedItems$
			.pipe(first())
			.subscribe((items) => this.editorRepository.setIsolatedItem(items?.[0]))
	}

	public trackByFn(index): void {
		return index;
	}

	onButtonClick(action: string, disabled: boolean): void {
		if (disabled) {
			return;
		}

		if (action === 'RECREATE_BACK') {
			return this.recreateBack();
		}

		if (action === 'DELETE') {
			return this.deleteItem();
		}

		if (action === 'DELETE_PARENT') {
			return this.deleteParentItem();
		}

		if (action === 'SIDEMOUNTED_ROD') {
			return this.createRod('SIDEMOUNTED_ROD');
		}

		if (action === 'SUSPENDED_ROD') {
			return this.createRod('SUSPENDED_ROD');
		}

		if (action === 'RESET_MODE') {
			this.editorRepository.setActiveConfigurationPanel(null);
			this.editorRepository.setSelectedItems([]);
			this.editorRepository.setMode(EditorMode.DEFAULT);
		}

		if (action === 'DOOR') {
			this.editorRepository.setMode(EditorMode.SELECT_ARTICLE_ZONES);
			this.toggleDoorTransparency(true);
		}

		return this.editorRepository.setActiveConfigurationPanel(action);
	}

	public toggleTransparency(hasAlreadyTransparency: boolean): void {
		if (hasAlreadyTransparency) {
			return this.editorRepository.setTransparency([])
		}

		this.editorRepository.setTransparency([
			PanelType.TOP,
			PanelType.BOTTOM,
			PanelType.SIDELEFT,
			PanelType.SIDERIGHT,
			PanelType.BACK,
			PanelType.FILLER,
			PanelType.FILLER_SUPPORT,
			PanelType.FRONT,
			PanelType.DOOR,
			PanelType.DRAWER_FRONT
		])
	}

	private deleteParentItem(): void {
		combineLatest([this.selectedArticles$,
			this.activeItem$,])
			.pipe(
				take(1),
				switchMap(([articles, activeItem]) => {
					const parentArticle = activeItem.articles?.find((article) => article.id === articles?.[0]?.parentArticleId);
					return this.itemsRepository.deleteArticle(this.item.partId, this.item.id, parentArticle.id);
				}),
				take(1)
			)
			.subscribe(() => {
				this.editorRepository.setActiveConfigurationPanel(null);
				this.editorRepository.setSelectedItems([]);
			})
	}

	private deleteItem(): void {
		combineLatest([this.selectedArticles$, this.selectedPanel$])
			.pipe(
				take(1),
				switchMap(([[article], panel]) => {
					if (panel.removable) {
						return this.itemsRepository.deletePanel(this.item.partId, this.item.id, panel.id);
					}

					return this.itemsRepository.deleteArticle(this.item.partId, this.item.id, article.id);
				}),
				take(1)
			)
			.subscribe(() => {
				this.editorRepository.setActiveConfigurationPanel(null);
				this.editorRepository.setSelectedItems([]);
			})
	}

	private createRod(type: string): void {
		combineLatest([this.activeItem$, this.selectedArticleZones$])
			.pipe(
				take(1),
				switchMap(([item, [articleZone]]) => {
					return this.itemsRepository.createArticleInArticleZone(item.partId, item.id, articleZone?.id || item.articleZones[0].id, type, {})
				}),
				take(1)
			)
			.subscribe(() => {
				this.editorRepository.setActiveConfigurationPanel(null);
				this.editorRepository.setSelectedItems([]);
			})
	}

	private recreateBack(): void {
		combineLatest([this.selectedPanel$])
			.pipe(
				take(1),
				switchMap(([panel]) => {
					return this.itemsRepository.recreateBack(this.item.partId, this.item.id, panel.articleZone.id, panel.faceIdx);
				}),
				take(1)
			)
			.subscribe(() => {
				this.editorRepository.setActiveConfigurationPanel(null);
				this.editorRepository.setSelectedItems([]);
			})
	}

	private getDoorIds(item: Item): string[] {
		return item?.articles?.reduce((articleAcc, article) => {
			return [...articleAcc, ...(article?.panels || [])?.reduce((panelAcc, panel) => {
				if (![PanelType.FRONT, PanelType.DRAWER_FRONT].includes(panel.panelType)) {
					return panelAcc;
				}

				return [panel.id, ...panelAcc];
			}, [])]
		}, [])
	}

	onShapeSelect(shapeInfo: Array<ShapeInfo>) {
		this.showDoor = false;
		this.showDivider = false;

		if (shapeInfo?.length === 1) {
			if (shapeInfo[0].type === ThreeObjectTypeEnum.LAYOUT) {
				this.showDivider = true;
				this.shapeInfo = shapeInfo;
			}
		}

		if (shapeInfo?.length >= 1) {
			if (
				shapeInfo.every(
					(shapeInfo) => shapeInfo.type === ThreeObjectTypeEnum.LAYOUT
				)
			) {
				this.showDoor = true;
				this.shapeInfo = shapeInfo;
			}
		}
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}
}
