import { combineLatest, Observable, Subject, throwError } from 'rxjs';
import { switchMap, take, takeUntil, map, catchError, filter, tap} from 'rxjs/operators';
import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { findUpdatedPanel } from '~shared/helpers'
import { IArticle, Item } from '~shared/types';
import { ParameterValidators } from '~shared/validators/parameters';
import { ItemsRepository } from '~modules/projects/store/items/items.repository';
import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { ParameterValue } from '~shared/shared.types';
import { ConstructionParameter } from '~shared/enums';

import { GenericItem } from '../cabinet-builder/engine-render.service';

interface ParameterMeta {
	label: string;
	parameterType: ConstructionParameter;
	inputType: string;
	level?: string;
}

@Component({
	selector: 'app-construction-parameter-input',
	templateUrl: './construction-parameter-input.component.html',
})
export class ConstructionParameterInputComponent implements OnInit, OnDestroy {
	@Input() name: string;
	@Input() parameters: ParameterMeta[] = [];
	@Input() selectedItem: Observable<IArticle>;
	@Input() infoImage?: string;
	@Input() infoText?: string;

	private componentDestroyed$: Subject<boolean> = new Subject<boolean>();

	public availableParameters: (ParameterValue & ParameterMeta)[] = [];
	public panelType = null;
	public disabled = false;
	public show = true;
	public isOpen = true;
	public formGroup: FormGroup = new FormGroup({});

	constructor(
		private readonly itemsRepository: ItemsRepository,
		private readonly editorRepository: EditorRepository,
	) {}

	public ngOnInit(): void {
		this.initializeDefaults();

		this.editorRepository.selectedItems$
			.pipe(
				takeUntil(this.componentDestroyed$),
				map(([item]) => item),
				filter((item) => !!item),
			)
			.subscribe((item) => this.panelType = item.panelType)

		this.editorRepository.disableFields$
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe((disabled) => this.disabled = disabled)
	}

	@HostListener('document:keydown.escape')
	public onKeydownHandler() {
		this.initializeDefaults()
	}

	public submitForm(): void {
		if (!this.formGroup.valid) {
			return
		}

		this.editorRepository.setLoading(true);
		combineLatest([this.itemsRepository.activeItem$, this.editorRepository.selectedItems$])
			.pipe(
				take(1),
				switchMap(([item, [panel]]) => {
					const availableParams = this.availableParameters;

					if (availableParams[0].level === 'panel') {
						return this.itemsRepository.updatePanelParameter(
							item.partId,
							item.id,
							panel.article?.id,
							panel.id,
							availableParams
								.filter((param) => !!this.formGroup.value[param.parameterType])
								.map((param) => ({
									value: Number(this.formGroup.value[param.parameterType]),
									parameterType: param.parameterType
								})),
						).pipe(take(1), map(() => panel));
					}

					return this.itemsRepository.updateArticleParameter(
						item.partId,
						item.id,
						panel.article?.id,
						availableParams
							.filter((param) => !!this.formGroup.value[param.parameterType])
							.map((param) => ({
								value: Number(this.formGroup.value[param.parameterType]),
								parameterType: param.parameterType
							})),
					).pipe(take(1), map(() => panel))
				}),
				catchError((err) => {
					this.editorRepository.setLoading(false)
					return throwError(() => err);
				}),
				switchMap((panel) => this.itemsRepository.activeItem$.pipe(take(1), map((item) => ({ item, panel }))))
			)
			.subscribe(({ item, panel }) => {
				this.editorRepository.setLoading(false);

				const updatedPanel = findUpdatedPanel(item, panel);

				if (!updatedPanel) {
					return
				}

				this.editorRepository.setSelectedItems([{
					...updatedPanel,
					parameterSet: updatedPanel.parameterSet
				}])
			})
	}

	public toggleItem(): void {
		this.isOpen = !this.isOpen
	}

	public initializeDefaults(): void {
		combineLatest([this.itemsRepository.activeItem$, this.editorRepository.selectedItems$])
			.pipe(
				takeUntil(this.componentDestroyed$),
			)
			.subscribe(([_, [item]]: [Item, GenericItem[]]) => {
				this.formGroup = new FormGroup({});

				const mappedParameters: (ParameterValue & ParameterMeta)[] = (this.parameters || [])
					.map((parameterName: ParameterMeta) => {
						const panelLevelParam: ParameterValue = item?.parameterSet?.parameters?.find(({ parameterType }) => parameterName.parameterType === parameterType);
						if (panelLevelParam) {
							return { ...panelLevelParam, ...parameterName, level: 'panel' }
						}

						const articleLevelParam = item?.article?.parameterSet?.parameters?.find(({ parameterType }) => parameterName.parameterType === parameterType);
						if (articleLevelParam) {
							return { ...articleLevelParam, ...parameterName, level: 'article' }
						}
					})
					.filter((param) => !!param);

				if (mappedParameters.length < 1) {
					return this.show = false;
				}

				this.show = true;
				this.availableParameters = mappedParameters.map((parameter: (ParameterValue & ParameterMeta)) => {
					this.formGroup.addControl(parameter.parameterType, new FormControl(parameter.value, [ParameterValidators.checkConstraints(parameter.constraint)]));

					// if (parameter.modifiable) {
					this.formGroup.get(parameter.parameterType).enable();
					// } else {
					// this.formGroup.get(parameter.parameterType).disable();
					// }

					return parameter;
				});
			})
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}
}
