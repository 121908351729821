import { PanelType } from "~shared/enums"

export enum ConnectorTypes {
	DOWEL_SCREW = 'DOWEL_SCREW',
	EXCENTER = 'EXCENTER',
	EXCENTER_INSIDE = 'EXCENTER_INSIDE',
	EXCENTER_OUTSIDE = 'EXCENTER_OUTSIDE',
	SCREW = 'SCREW',
	DOWEL_GLUE = 'DOWEL_GLUE',
	SHELVE_SUPPORT = 'SHELVE_SUPPORT',
	SHELVE_SUPPORT_PIN = 'SHELVE_SUPPORT_PIN',
	GROOVE = 'GROOVE',
	GROOVE_STROKE = 'GROOVE_STROKE',
	GROOVE_SCREW = 'GROOVE_SCREW',
	GLUE = 'GLUE',
	DOWEL = 'DOWEL',
}

export const connectionOptions = [
	{
		label: 'Drevel + schroef',
		value: ConnectorTypes.DOWEL_SCREW
	},
	{
		label: 'Excenter',
		value: ConnectorTypes.EXCENTER
	},
	{
		label: 'Excenter binnen',
		value: ConnectorTypes.EXCENTER_INSIDE
	},
	{
		label: 'Excenter buiten',
		value: ConnectorTypes.EXCENTER_OUTSIDE
	},
	{
		label: 'Schoef',
		value: ConnectorTypes.SCREW
	},
	{
		label: 'Drevel + lijn',
		value: ConnectorTypes.DOWEL_GLUE
	},
	{
		label: 'Legplankdrager',
		value: ConnectorTypes.SHELVE_SUPPORT
	},
	{
		label: 'Groef',
		value: ConnectorTypes.GROOVE
	},
	{
		label: 'Groef met slag',
		value: ConnectorTypes.GROOVE_STROKE
	},
	{
		label: 'Groef met schoef',
		value: ConnectorTypes.GROOVE_SCREW
	},
	{
		label: 'Lijm',
		value: ConnectorTypes.GLUE
	},
	{
		label: 'Drevel',
		value: ConnectorTypes.DOWEL
	},
	{
		label: 'Legplankdrager met vastzetpin',
		value: ConnectorTypes.SHELVE_SUPPORT_PIN
	},
]

export const connectionsMap = {
	[PanelType.TOP]: {
		DEFAULT: [ConnectorTypes.DOWEL_SCREW, ConnectorTypes.EXCENTER_INSIDE, ConnectorTypes.EXCENTER_OUTSIDE, ConnectorTypes.DOWEL_GLUE]
	},
	[PanelType.BOTTOM]: {
		DEFAULT: [ConnectorTypes.DOWEL_SCREW, ConnectorTypes.EXCENTER_INSIDE, ConnectorTypes.EXCENTER_OUTSIDE, ConnectorTypes.DOWEL_GLUE]
	},
	[PanelType.FILLER]: {
		DEFAULT: [ConnectorTypes.SCREW, ConnectorTypes.GLUE]
	},
	[PanelType.FILLER_SUPPORT]: {
		DEFAULT: [ConnectorTypes.SCREW]
	},
	[PanelType.FILLER_FRONT]: {
		DEFAULT: [ConnectorTypes.EXCENTER]
	},
	[PanelType.SHELF]: {
		DEFAULT: [ConnectorTypes.SHELVE_SUPPORT, ConnectorTypes.SHELVE_SUPPORT_PIN]
	},
	[PanelType.BACK]: {
		RECESSED: [ConnectorTypes.GROOVE, ConnectorTypes.GROOVE_SCREW],
		FLUSH: [ConnectorTypes.EXCENTER_INSIDE, ConnectorTypes.EXCENTER_OUTSIDE, ConnectorTypes.GROOVE_STROKE, ConnectorTypes.DOWEL_SCREW, ConnectorTypes.DOWEL_GLUE],
		MOUNTED: [ConnectorTypes.EXCENTER_INSIDE, ConnectorTypes.EXCENTER_OUTSIDE, ConnectorTypes.DOWEL_SCREW, ConnectorTypes.DOWEL_GLUE],
	},
	[PanelType.DIVIDER]: {
		DEFAULT: [ConnectorTypes.EXCENTER, ConnectorTypes.DOWEL_SCREW, ConnectorTypes.DOWEL_GLUE],
	},
}
