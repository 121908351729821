import { select } from '@ngneat/elf';
import { selectActiveEntity, selectAllEntities } from '@ngneat/elf-entities';
import { selectRequestStatus } from '@ngneat/elf-requests';
import { map } from 'rxjs/operators';

import { cuttingListLineStore } from './cutting-list-lines.store';

export const cuttingListLinesSelector = {
	cuttingListLines$: cuttingListLineStore.pipe(selectAllEntities()),
	cuttingListLinesLoading$: cuttingListLineStore.pipe(selectRequestStatus('items'), map((status) => status.value === 'pending')),
	activeCuttingListLine$: cuttingListLineStore.pipe(selectActiveEntity()),
};
