import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';

import { AuthService } from '~core/services/auth.service';
import { ExternalUser, InternalUser } from '~modules/auth/types/user.types';

import { HeaderMenuItem } from './header.types';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
	@Input() menuItems: HeaderMenuItem[] = [];
	@Output() sidenavToggle = new EventEmitter<void>();

	public currentUser$: Observable<InternalUser | ExternalUser>


	constructor(
		private readonly authService: AuthService,
	) {}

	public ngOnInit(): void {
		this.currentUser$ = this.authService.currentUser$;
	}
}
