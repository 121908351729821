import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as uuid from 'uuid';
import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';

import { HardwareItem } from '~shared/types';

@Component({
	selector: 'app-handle-selection-input',
	templateUrl: './handle-selection-input.component.html',
	providers: [
		{
			provide: [],
			useExisting: forwardRef(() => HandleSelectionInputComponent),
			multi: true,
		},
	],
})
export class HandleSelectionInputComponent implements OnInit, OnDestroy, ControlValueAccessor {
	// @Input() label?: string;
	@Input() name?: string;
	// @Input() orientation?: string = 'horizontal';
	@Input() options?: HardwareItem[];

	private componentDestroyed$: Subject<boolean> = new Subject<boolean>();

	public disabled: boolean;
	public uuid = uuid.v4();
	public variantControl: UntypedFormControl = new UntypedFormControl(0);
	public hardwareLabelControl: UntypedFormControl = new UntypedFormControl('none');
	public updateValue = (_: any) => {};

	constructor(public ngControl: NgControl) {
		ngControl.valueAccessor = this;
	}

	private propagateChange(value: any): void {
		if (this.updateValue) {
			return this.updateValue(value);
		}

		if (this.hardwareLabelControl) {
			this.hardwareLabelControl.setValue(value, {
				emitEvent: false
			});
		}
	}

	public ngOnInit(): void {
		this.hardwareLabelControl.valueChanges
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe(() => this.variantControl.setValue(0));

		this.hardwareLabelControl.valueChanges
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe((label) => {
				this.propagateChange({
					label, variant: this.variantControl.value,
				});
			});

		this.variantControl.valueChanges
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe((variant) => {
				this.propagateChange({
					variant, label: this.hardwareLabelControl.value
				});
			});
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}

	public changeVariant(index: number): void {
		this.variantControl.setValue(index);
	}

	public writeValue(value: any): void {
		setTimeout(() => {
			this.hardwareLabelControl.setValue(value?.label || 'none', {
				emitEvent: false
			});

			this.variantControl.setValue(value?.variant || 0, {
				emitEvent: false
			})
		});
	}

	public registerOnChange(fn: any): void {
		this.updateValue = fn;
	}

	public registerOnTouched(): void {}

	public setDisabledState(isDisabled: boolean) {
		this.disabled = isDisabled;
		isDisabled
			? this.hardwareLabelControl.disable({ emitEvent: false })
			: this.hardwareLabelControl.enable({ emitEvent: false });
	}
}
