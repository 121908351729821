import { createStore, withProps } from '@ngneat/elf';

import { AppState } from './app.types';

export const appStore = createStore(
	{ name: 'app' },
	withProps<AppState>({
		loading: false,
	})
)
