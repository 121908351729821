import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { catchError, combineLatest, filter, map, Observable, startWith, Subject, switchMap, take, takeUntil, tap, throwError } from 'rxjs';

import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { ItemsRepository } from '~modules/projects/store/items/items.repository';
import { GenericItem } from '~shared/components/cabinet-builder/engine-render.service';
import { Item } from '~shared/types';


@Component({
	selector: 'app-panel-construction',
	templateUrl: './panel-construction.component.html',
})
export class PanelConstructionComponent implements OnInit, OnDestroy {
	public openItems: Record<string, boolean> = {
		CONNECTION_LEFT: true,
		CONNECTION_RIGHT: true,
		CONNECTION_TOP: true,
		CONNECTION_BOTTOM: true,
	}
	public selectedItem$: Observable<GenericItem>;
	public leftConstructionTypeControl = new FormControl();
	public leftConstructionTypeOptions = [];
	public rightConstructionTypeControl = new FormControl();
	public rightConstructionTypeOptions = [];
	public topConstructionTypeControl = new FormControl();
	public topConstructionTypeOptions = [];
	public bottomConstructionTypeControl = new FormControl();
	public bottomConstructionTypeOptions = [];
	public activeItem$: Observable<Item>;

	private componentDestroyed$: Subject<boolean> = new Subject()

	constructor(
		private readonly itemsRepository: ItemsRepository,
		private readonly editorRepository: EditorRepository,
	) {}

	public ngOnInit(): void {
		this.activeItem$ = this.itemsRepository.activeItem$;
		this.selectedItem$ = this.editorRepository.selectedItems$
			.pipe(
				takeUntil(this.componentDestroyed$),
				map(([item]) => item),
				filter((item) => !!item),
				tap((item) => {
					this.leftConstructionTypeOptions = (item.construction?.leftConstructionTypeOptions || []).map((option) => ({
						icon: `/editor/connections/LEFT_${option}.svg`,
						value: option
					}));
					this.rightConstructionTypeOptions = (item.construction?.rightConstructionTypeOptions || []).map((option) => ({
						icon: `/editor/connections/RIGHT_${option}.svg`,
						value: option
					}));
					this.leftConstructionTypeControl.setValue(item.construction?.leftConstructionType, { emitEvent: false });
					this.rightConstructionTypeControl.setValue(item.construction?.rightConstructionType, { emitEvent: false });

					this.bottomConstructionTypeOptions = (item.construction?.bottomConstructionTypeOptions || []).map((option) => ({
						icon: `/editor/connections/LEFT_${option}.svg`,
						value: option
					}));
					this.topConstructionTypeOptions = (item.construction?.topConstructionTypeOptions || []).map((option) => ({
						icon: `/editor/connections/RIGHT_${option}.svg`,
						value: option
					}));
					this.bottomConstructionTypeControl.setValue(item.construction?.bottomConstructionType, { emitEvent: false });
					this.topConstructionTypeControl.setValue(item.construction?.topConstructionType, { emitEvent: false });
				})
			);

		this.editorRepository.disableFields$
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe((disabled) => {
				if (disabled) {
					this.leftConstructionTypeControl.disable({ emitEvent: false });
					this.rightConstructionTypeControl.disable({ emitEvent: false });
					this.topConstructionTypeControl.disable({ emitEvent: false });
					this.bottomConstructionTypeControl.disable({ emitEvent: false });
					return;
				}

				this.leftConstructionTypeControl.enable({ emitEvent: false });
				this.rightConstructionTypeControl.enable({ emitEvent: false });
				this.topConstructionTypeControl.enable({ emitEvent: false });
				this.bottomConstructionTypeControl.enable({ emitEvent: false });
			})

		combineLatest([
			this.leftConstructionTypeControl.valueChanges.pipe(startWith(null)),
			this.rightConstructionTypeControl.valueChanges.pipe(startWith(null)),
			this.topConstructionTypeControl.valueChanges.pipe(startWith(null)),
			this.bottomConstructionTypeControl.valueChanges.pipe(startWith(null))
		])
			.pipe(
				takeUntil(this.componentDestroyed$),
				filter(([leftValue, rightValue, topValue, bottomValue]) => leftValue || rightValue || topValue || bottomValue),
				switchMap(([leftValue, rightValue, topValue, bottomValue]) => combineLatest([
					this.itemsRepository.activeItem$,
					this.selectedItem$
				])
					.pipe(take(1), map(([item, panel]) => ({ leftValue, rightValue, topValue, bottomValue, item, panel })))),
				tap(() => this.editorRepository.setLoading(true)),
				switchMap(({ leftValue, rightValue, item, panel, topValue, bottomValue }) => {
					return this.itemsRepository
						.updatePanel(item.partId, item.id, panel.id, {
							construction: {
								leftConstructionType: leftValue || this.leftConstructionTypeControl.value,
								rightConstructionType: rightValue || this.rightConstructionTypeControl.value,
								topConstructionType: topValue || this.topConstructionTypeControl.value,
								bottomConstructionType: bottomValue || this.bottomConstructionTypeControl.value,
							}
						})
						.pipe(map(() => ({ leftValue, rightValue, topValue, bottomValue })))
				}),
				takeUntil(this.componentDestroyed$),
				catchError((err) => {
					this.editorRepository.setLoading(false);
					return throwError(() => err);
				}),
			)
			.subscribe(({ leftValue, rightValue, topValue, bottomValue }) => {
				this.editorRepository.setLoading(false)
				this.leftConstructionTypeControl.setValue(leftValue || this.leftConstructionTypeControl.value, { emitEvent: false });
				this.rightConstructionTypeControl.setValue(rightValue || this.rightConstructionTypeControl.value, { emitEvent: false });
				this.topConstructionTypeControl.setValue(topValue || this.topConstructionTypeControl.value, { emitEvent: false });
				this.bottomConstructionTypeControl.setValue(bottomValue || this.bottomConstructionTypeControl.value, { emitEvent: false });
			})
	}

	public toggleItem(itemName: string): void {
		this.openItems[itemName] = !this.openItems[itemName] || false;
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}
}
