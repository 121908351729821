import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
@Injectable({
	providedIn: 'root',
})
export class AlertService {
	constructor(private toastService: ToastrService) {}
	handleError(err: HttpErrorResponse) {
		let errorMessage: string;
		if (err.error instanceof ErrorEvent) {
			// A client side or network error occured,Handle it accordingly
		} else {
			if (err.status === 403) {
				return;
			}

			switch (err.status) {
			case 400:
				errorMessage = `${err.message}`;
				break;
			case 401:
				errorMessage = `${err.message}`;
				break;
			case 403:
				errorMessage = `${err.message}`;
				break;
			case 404:
				errorMessage = `${err.message}`;
				break;
			case 409:
				errorMessage = `${err.error.message}`;
				break;
			case 412:
				errorMessage = `${err.message}`;
				break;
			case 500:
				errorMessage = `${err.message}`;
				break;
			case 503:
				errorMessage = `${err.message}`;
				break;
			default:
				errorMessage = `Something went wrong!`;
			}

			this.toastService.error(errorMessage);
		}
	}
}
