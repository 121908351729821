import  { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

interface InputDialogData{
    title: string
    inputs: string[]
    sendButtonText?: string
}

@Component({
    templateUrl: "./input-dialog.component.html"
})

export class InputDialogComponent{
    constructor(@Inject(MAT_DIALOG_DATA) public data: InputDialogData){}
}