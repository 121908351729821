import { selectActiveEntity, selectAllEntities } from '@ngneat/elf-entities';
import { selectRequestStatus } from '@ngneat/elf-requests';
import { map } from 'rxjs/operators';

import { projectStore } from './projects.store';

export const projectsSelector = {
	projects$: projectStore.pipe(selectAllEntities()),
	project$: projectStore.pipe(selectActiveEntity()),
	projectsLoading$: projectStore.pipe(
		selectRequestStatus('projects'),
		map((status) => status.value === 'pending')
	)
};
