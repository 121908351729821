import { Component, Inject, EventEmitter, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { filter, map, Observable, switchMap, take } from 'rxjs';

import { PartStatuses } from '~modules/projects/projects.const';

@Component({
	templateUrl: "./status.modal.html"
})
export class StatusModalComponent {
	public partStatuses = PartStatuses

	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		public dialogRef: MatDialogRef<StatusModalComponent>,
	) {}
}
