import { Injectable } from '@angular/core';
import { updateRequestStatus } from '@ngneat/elf-requests';
import { take } from 'rxjs/operators';
import { deleteEntities, setActiveId, setEntities, updateEntities, upsertEntities, upsertEntitiesById } from '@ngneat/elf-entities';
import { Observable, tap } from 'rxjs';

import { CustomisationService } from '~core/services/customisation.service';
import { Customisation } from '~shared/types';

import { customisationsStore } from './customisations.store';
import { customisationsSelector } from './customisations.selectors';

@Injectable()
export class CustomisationsRepository {
	public customisations$ = customisationsSelector.customisations$;
	public activeCustomisation$ = customisationsSelector.activeCustomisation$;
	public customisationsLoading$ = customisationsSelector.customisationsLoading$;
	public createLoading$ = customisationsSelector.createLoading$;
	public updateLoading$ = customisationsSelector.updateLoading$;
	public deleteLoading$ = customisationsSelector.deleteLoading$;
	public createFileLoading$ = customisationsSelector.createFileLoading$;

	constructor(
		private readonly customisationService: CustomisationService,
	) {}

	public getCustomisations(ids: string[], skipActivation = false): Observable<Customisation[]> {
		customisationsStore.update(updateRequestStatus('get-customisations', 'pending'));
		return this.customisationService
			.getCustomisations(ids)
			.pipe(
				take(1),
				tap((customisations) => {
					customisationsStore.update(
						setEntities(customisations.sort((a, b) => a.id.localeCompare(b.id))),
						updateRequestStatus('get-customisations', 'success')
					)

					if (!skipActivation) {
						customisationsStore.update(
							setActiveId(customisations?.[0]?.id)
						)
					}
				})
			)
	}

	public createPanelCustomisation(partId: string, itemId: string, panelId: string, customisation): Observable<Customisation> {
		customisationsStore.update(updateRequestStatus('create-customisation', 'pending'));
		return this.customisationService
			.createPanelCustomisation(partId, itemId, panelId, customisation)
			.pipe(
				take(1),
				tap((customisation) =>
					customisationsStore.update(
						upsertEntities(customisation),
						setActiveId(customisation.id),
						updateRequestStatus('create-customisation', 'success')
					)
				)
			)
	}

	public createLayoutCustomisation(partId: string, itemId: string, articleZoneId: string, customisation): Observable<Customisation> {
		customisationsStore.update(updateRequestStatus('create-customisation', 'pending'));
		return this.customisationService
			.createLayoutCustomisation(partId, itemId, articleZoneId, customisation)
			.pipe(
				take(1),
				tap((customisation) =>
					customisationsStore.update(
						upsertEntities(customisation),
						setActiveId(customisation.id),
						updateRequestStatus('create-customisation', 'success')
					)
				)
			)
	}

	public createItemCustomisation(partId: string, itemId: string, customisation): Observable<Customisation> {
		customisationsStore.update(updateRequestStatus('create-customisation', 'pending'));
		return this.customisationService
			.createItemCustomisation(partId, itemId, customisation)
			.pipe(
				take(1),
				tap((customisation) =>
					customisationsStore.update(
						upsertEntities(customisation),
						setActiveId(customisation.id),
						updateRequestStatus('create-customisation', 'success')
					)
				)
			)
	}

	public createPartCustomisation(partId: string, customisation): Observable<Customisation> {
		customisationsStore.update(updateRequestStatus('create-customisation', 'pending'));
		return this.customisationService
			.createPartCustomisation(partId, customisation)
			.pipe(
				take(1),
				tap((customisation) =>
					customisationsStore.update(
						upsertEntities(customisation),
						setActiveId(customisation.id),
						updateRequestStatus('create-customisation', 'success')
					)
				)
			)
	}

	public activateCustomisation(customisationId: string): void {
		customisationsStore.update(setActiveId(customisationId));
	}

	public updateCustomisation(customisationId: string, customisation): Observable<Customisation> {
		customisationsStore.update(updateRequestStatus('update-customisation', 'pending'));
		return this.customisationService
			.updateCustomisation(customisationId, customisation)
			.pipe(
				take(1),
				tap((customisation) => customisationsStore.update(
					upsertEntities(customisation),
					updateRequestStatus('update-customisation', 'success')
				))
			)
	}

	public createFile(customisationId: string, formData: FormData): Observable<Customisation> {
		customisationsStore.update(updateRequestStatus('create-file', 'pending'));
		return this.customisationService
			.createFile(customisationId, formData)
			.pipe(
				take(1),
				tap((customisation) => customisationsStore.update(
					upsertEntities(customisation),
					updateRequestStatus('create-file', 'success')
				))
			)
	}

	public downloadFile(customisationId: string, fileKey: string): Observable<any> {
		customisationsStore.update(updateRequestStatus('download-file', 'pending'));
		return this.customisationService
			.downloadFile(customisationId, fileKey)
			.pipe(
				take(1),
				tap(() => customisationsStore.update(
					updateRequestStatus('download-file', 'success')
				))
			)
	}

	public deleteFile(customisationId: string, fileKey: string): Observable<void> {
		customisationsStore.update(updateRequestStatus('delete-file', 'pending'));
		return this.customisationService
			.deleteFile(customisationId, fileKey)
			.pipe(
				take(1),
				tap(() => customisationsStore.update(
					updateEntities(customisationId, (customisation) => ({
						...customisation,
						files: customisation.files.filter(({ key }) => key !== fileKey)
					})),
					updateRequestStatus('delete-file', 'success')
				))
			)
	}

	public deleteCustomisation(customisationId: string): Observable<void> {
		customisationsStore.update(updateRequestStatus('delete-customisation', 'pending'));
		return this.customisationService
			.deleteCustomisation(customisationId)
			.pipe(
				take(1),
				tap(() =>
					customisationsStore.update(
						deleteEntities(customisationId),
						updateRequestStatus('delete-customisation', 'success')
					)
				)
			)
	}

	public clearCustomisations(): void {
		customisationsStore.update(
			setActiveId(undefined),
			setEntities([]),
		)
	}
}
