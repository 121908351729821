export const FILLER_ZONE_NAMES = {
	T: 'Boven',
	B: 'Onder',
	L: 'Links',
	R: 'Rechts',
	L_T: 'Links schuin',
	R_T: 'Rechts schuin'
}

export const FILLER_ZONE_ALIASES = {
	T: 'T',
	B: 'B',
	L: 'L',
	R: 'R',
	L_T: 'L',
	R_T: 'R'
}
