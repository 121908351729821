export const AVAILABLE_HANDLE_POSITIONS = [
	'VLT',
	'VLC',
	'VLB',
	'HLT',
	'HCT',
	'HRT',
	'HLB',
	'HCB',
	'HRB',
	'VRT',
	'VRC',
	'VRB',
	'HCC',
	'VCC'
]
