import { Pipe, PipeTransform } from '@angular/core';

import { Part } from '~shared/types';

@Pipe({
	name: 'partfilter',
	pure: false,
})
export class PartFilterPipe implements PipeTransform {
	transform(items: Part[], projectId: string): any {
		if (!items || !projectId) {
			return items;
		}

		return items.filter((item) => item.project.id === projectId);
	}
}
