import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserInputsService } from 'src/app/modules/projects/services/user-inputs.service'

@Component({
  selector: 'app-notification-board',
  templateUrl: './notification-board.component.html',
  styleUrls: ['./notification-board.component.scss']
})
export class NotificationBoardComponent implements OnInit, OnDestroy {
  private unsubscribe = new Subject<void>();
  message = '';
  currentCabinet;
  showCusts = false;
  custsData;

  constructor(
    private userInputsService: UserInputsService
  ) { }

  ngOnInit(): void {
    // TODO get data from item service
    this.allowChangeCabinet();
    this.customizations();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  allowChangeCabinet(): void {
    this.userInputsService.changeCabinetData
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(data => {
      if (data) {
        this.message = 'If you want to change settings of this cabinet please enter the construction regime.';
        this.currentCabinet = data;
      } else {
        this.message = '';
      }
    });
  }

  customizations(): void {
    this.userInputsService.getCustomization()
    .pipe(takeUntil(this.unsubscribe))
    .subscribe((data) => {
      if (data !== null) {
        this.showCusts = true;
        this.custsData = data;
      }
    });
  }

  declineDmnsnChange(): void {
    this.message = '';
  }

  confirmDmnsnChange(): void {
    this.userInputsService.updateCabinet.emit(this.currentCabinet);
    this.message = '';
  }

}
