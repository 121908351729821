import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Injectable()
export class StaticDataService {
	public baseUrl: string = `${environment.baseUrl}/board-api/v1`;

	constructor(private http: HttpClient) {}

	public getStaticData(dataKey: string): Observable<string[]> {
		return this.http
			.get<string[]>(`${this.baseUrl}/static/${dataKey}`);
	}
}
