import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { ExternalUser, InternalUser } from '~modules/auth/types/user.types';

import { AuthService } from '../../services/auth.service';

@Component({
	selector: 'app-user-info',
	templateUrl: './user-info.component.html',
})
export class UserInfoComponent implements OnInit {
	public isUserInfoOpen = false;
	public currentUser$: Observable<InternalUser | ExternalUser>;
	public isUserInfoClicked: boolean;

	constructor(public authService: AuthService) {}
	@HostListener('click')
	openMenu(): void {
		this.isUserInfoClicked = true;
	}

	@HostListener('document:click')
	clickOutsideMenu(): void {
		if (!this.isUserInfoClicked) {
			this.isUserInfoOpen = false;
		}

		this.isUserInfoClicked = false;
	}

	async ngOnInit(): Promise<void> {
		this.currentUser$ = this.authService.currentUser$;
	}

	removeUserInfo(): void {
		this.isUserInfoOpen = false;
	}

	toggleUserInfo(): void {
		this.isUserInfoOpen = !this.isUserInfoOpen;
	}

	public onLogOut(e: Event): void {
		e.preventDefault();

		this.isUserInfoOpen = false;
		this.authService.logout();
	}
}
