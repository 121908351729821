import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { pick } from 'ramda';
import { filter, map, Observable, Subject, take, takeUntil, tap } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Part, Item, Project, ProjectTree, RawBoard, FinishedBoard, ItemMaterialMap } from '~shared/types';
import {
	StandardDialogComponent,
	Button,
} from '~shared/components/standard-dialog/standard-dialog.component';
import { PartsRepository } from '~modules/projects/store/parts/parts.repository';
import { BoardSelectionModalComponent } from '~modules/projects/modals/board-selection/board-selection.modal';
import { ItemsRepository } from '~modules/projects/store/items/items.repository';
import { EditorRepository } from '~modules/projects/store/editor/editor.repository';

import { PANEL_GROUP_TRANSLATIONS } from './project-navigator.const';

@Component({
	selector: 'app-project-navigator',
	templateUrl: './project-navigator.component.html',
})
export class ProjectNavigatorComponent implements OnInit, OnDestroy {
	loading = false; // Show loading icon on top op side nav
	public showNewCabinetGroupSelection: boolean = false;
	public openBoardPanels: Record<string, boolean> = {}
	public openPartBoardPanel: string;
	public boardPanels: Record<string, any>;
	public boardPanelsLoading: boolean;
	public editingPart: string;
	public editingPartControl = new FormControl('');
	public editingItem: string;
	public editingItemControl = new FormControl('');
	public items$: Observable<Item[]>;
	public partEditorSelectedItem$: Observable<string>;
	public itemsLoading$: Observable<boolean>;
	public panelGroupTranslations = PANEL_GROUP_TRANSLATIONS;

	private componentDestroyed$: Subject<boolean> = new Subject();

	@Input() project: ProjectTree;
	@Input() selectedItem: Item;
	@Input() selectedPart: Part;
	@Input() openPart: string;
	@Input() showNewCabinetSelection: Record<string, boolean>;

	@Output() selectItem = new EventEmitter();
	@Output() selectPart = new EventEmitter();
	@Output() createItem = new EventEmitter();
	@Output() togglePart = new EventEmitter();
	@Output() setNewCabinetSelection = new EventEmitter();
	@Output() createPart = new EventEmitter();
	@Output() returnToMainView = new EventEmitter();

	constructor(
		private readonly itemsRepository: ItemsRepository,
		private readonly partsRepository: PartsRepository,
		private readonly editorRepository: EditorRepository,
		private readonly route: ActivatedRoute,
		private readonly dialog: MatDialog,
		private readonly router: Router,
	) {}

	public ngOnInit(): void {
		this.items$ = this.itemsRepository.items$
			.pipe(
				map((items) => items.map((item) => ({
					...item,
					parsedMaterialMap: this.aggregateMaterialMap(item?.materialMap || {})
				})))
			)

		this.partEditorSelectedItem$ = this.editorRepository.partEditorSelectedItem$;

		this.itemsRepository.items$
			.pipe(
				takeUntil(this.componentDestroyed$),
				filter((items) => items && !!items.length)
			).subscribe((items) => {
				this.boardPanels = items
					.map((item) => this.aggregateMaterialMap(item?.materialMap || {}))
					.reduce((acc, materialMap) => {
						return {
							...acc,
							...Object.keys(materialMap).reduce((materialAcc, key) => ({
								...materialAcc,
								[key]: acc[key] ? {
									items: [...acc[key].items, ...materialMap[key].items],
									sku: `${acc[key].sku}, ${materialMap[key].sku}`
								} : materialMap[key]
							}), {})
						};
					});
			})

		this.itemsLoading$ = this.itemsRepository.itemsLoading$;
	}

	public handleOpenPartBoards(e: Event, partId: string): void {
		e.preventDefault();
		e.stopImmediatePropagation();

		if (this.openPartBoardPanel === partId) {
			return this.openPartBoardPanel = null
		}

		this.openPartBoardPanel = partId;
		this.boardPanelsLoading = true;
		this.itemsRepository.getItems([partId])
			.pipe(take(1))
			.subscribe(() => this.boardPanelsLoading = false)
	}

	public handleOpenPartMaterial(e: Event, partId: string, panelGroup: string, selectedBoardLabels: string[], singleMaterialUpdate = false): void {
		e.preventDefault();
		e.stopImmediatePropagation();

		const dialogRef = this.dialog.open(BoardSelectionModalComponent, {
			data: { selectedBoardLabels }
		});

		dialogRef
			.afterClosed()
			.subscribe((action: RawBoard | FinishedBoard) => {
				if (!action) {
					return
				}

				this.itemsRepository.updatePartPanelGroupBoard(partId, panelGroup, {
					...pick(['sku', 'thickness', 'boardDimension'])(action),
					// ...(action.grain && { grain: action.grain })
				}, singleMaterialUpdate ? selectedBoardLabels[0] : null)
					.pipe(take(1))
					.subscribe();
			});
	}

	public handleOpenMaterial(e: Event, partId: string, itemId: string, panelGroup: string, selectedBoardLabels: string[], singleMaterialUpdate = false): void {
		e.preventDefault();
		e.stopImmediatePropagation();

		const dialogRef = this.dialog.open(BoardSelectionModalComponent, {
			data: { selectedBoardLabels }
		});

		dialogRef
			.afterClosed()
			.subscribe((action: RawBoard | FinishedBoard) => {
				if (!action) {
					return
				}

				this.itemsRepository.updatePanelGroupBoard(partId, itemId, panelGroup, {
					...pick(['sku', 'thickness', 'boardDimension'])(action),
					// ...(action.grain && { grain: action.grain })
				}, singleMaterialUpdate ? selectedBoardLabels[0] : null)
					.pipe(take(1))
					.subscribe();
			});
	}

	public handleOpenBoards(e: Event, itemId: string): void {
		e.preventDefault();
		e.stopImmediatePropagation();

		this.openBoardPanels[itemId] = !this.openBoardPanels[itemId] || false
	}

	public handleOpenItemCustomisations(e: Event, item: Item, part: Part): void {
		e.preventDefault();
		e.stopImmediatePropagation();

		this.handleItemSelect(item, part);
		this.editorRepository.setActiveConfigurationPanel('ITEM')
	}

	public handleOpenPartCustomisations(e: Event, part: Part): void {
		e.preventDefault();
		e.stopImmediatePropagation();

		this.handlePartSelect(part);
		this.editorRepository.setActiveConfigurationPanel('PART')
	}

	public async deletePart(part: Part, project: Project, e: Event): Promise<void> {
		e.preventDefault();
		e.stopImmediatePropagation();

		const dialogRef = this.dialog.open(StandardDialogComponent, {
			data: {
				title: 'Verwijderen kast',
				body: `Bent u zeker dat u kast "${part.name}" wil verwijderen uit project "${project.name}"?`,
				buttons: [new Button('Annuleren'), new Button('OK', 'DELETE', 'primary')],
				icon: 'trash',
				type: 'danger'
			},
		});

		dialogRef
			.afterClosed()
			.subscribe((action) => {
				if (action !== 'DELETE') {
					return
				}

				this.partsRepository.deletePart(part.id);
				this.editorRepository.setActiveConfigurationPanel(null);

				if (this.selectedPart.id === part.id) {
					this.itemsRepository.clearItems();
				}
			});
	}

	// Asks if item can be deleted, if yes, delete item
	// After delete navigates to projectDetails (Emitter)
	public deleteItem(item: Item, part: Part, project: Project, e: Event): void {
		e.preventDefault();
		e.stopImmediatePropagation();

		const dialogRef = this.dialog.open(StandardDialogComponent, {
			data: {
				title: 'Verwijderen kast',
				body: `Bent u zeker dat u kastelement "${item.name}" in groep "${part.name}" wil verwijderen uit project "${project.name}"?`,
				buttons: [new Button('Annuleren'), new Button('OK', 'DELETE', 'primary')],
				icon: 'trash',
				type: 'danger'
			},
		});

		dialogRef
			.afterClosed()
			.subscribe((action) => {
				if (action !== 'DELETE') {
					return
				}

				this.itemsRepository.deleteItem(part.id, item.id)
					.pipe(take(1))
					.subscribe(() =>
						this.editorRepository.setActiveConfigurationPanel(null));
			});
	}

	public duplicateItem(item: Item, part: Part, e: Event) {
		e.preventDefault();
		e.stopImmediatePropagation();

		this.itemsRepository.duplicateItem(part.id, item.id)
			.pipe(take(1))
			.subscribe(() =>
				this.editorRepository.setActiveConfigurationPanel(null))
	}

	public saveItem(item: Item, part: Part, e: Event) {
		e.preventDefault();
		e.stopImmediatePropagation();

		this.router.navigate(['/', 'projects', this.project.id, 'catalog', 'create'], {
			queryParams: {
				item: item.id,
				part: part.id,
			}
		})
	}

	public materialTrackBy(index, user) {
		return index;
	}

	public aggregateMaterialMap(materialMap: Record<string, string[]>): Record<string, Record<string, any>> {
		return Object.keys(materialMap).reduce((acc, groupKey: string) => {
			if (acc[groupKey]) {
				return {
					...acc,
					[groupKey]: {
						sku: `${acc[groupKey].sku}, ${materialMap[groupKey].join(', ')}`,
						items: [...acc[groupKey].sku, ...materialMap[groupKey]]
					}
				}
			}

			return {
				...acc,
				[groupKey]: {
					sku: materialMap[groupKey].join(', '),
					items: materialMap[groupKey]
				}
			}
		}, {})
	}

	public duplicatePart(part: Part, e: Event) {
		e.preventDefault();
		e.stopImmediatePropagation();

		this.partsRepository.duplicatePart(part.id)
			.pipe(take(1))
			.subscribe()
	}

	public editPart(part: Part, e: Event) {
		e.preventDefault();
		e.stopImmediatePropagation();

		this.editingPartControl.setValue(part.name);
		this.editingPart = part.id;
	}

	public editItem(item: Item, e: Event) {
		e.preventDefault();
		e.stopImmediatePropagation();

		this.editingItemControl.setValue(item.name);
		this.editingItem = item.id;
	}

	public submitPartName(project: Project, part: Part): void {
		this.partsRepository.updatePart(part.id, {
			name: this.editingPartControl.value,
			projectId: project.id
		})
			.pipe(take(1))
			.subscribe(() => this.editingPart = null)
	}

	public submitItemName(project: Project, part: Part, item: Item): void {
		this.itemsRepository.updateItemName(item.id, part.id, this.editingItemControl.value)
			.pipe(take(1))
			.subscribe(() => this.editingItem = null)
	}

	public handleItemSelect(item: Item, part: Part) {
		this.router.navigate(['/', 'projects', this.project.id, 'editor']);
		this.editorRepository.setActiveConfigurationPanel(null);
		const data = { item, part };
		this.selectItem.emit(data);
	}

	public handlePartSelect(part: Part) {
		this.router.navigate(['/', 'projects', this.project.id, 'editor']);

		this.togglePart.emit(part.id);
		this.selectPart.emit(part);
		this.editorRepository.setActiveConfigurationPanel(null);

		if (this.selectedPart?.id === part.id || this.selectedItem?.partId === part.id) {
			return;
		}

		this.itemsRepository.getItems([part.id])
			.pipe(take(1))
			.subscribe()
	}

	public handleTogglePart(partId: string, isOpen: boolean, e: Event): void {
		e.preventDefault()
		e.stopImmediatePropagation();

		this.itemsRepository.clearItems();
		if (isOpen) {
			return this.togglePart.emit(null);
		}

		this.togglePart.emit(partId);
		this.itemsRepository.getItems([partId])
			.pipe(take(1))
			.subscribe()
	}

	public openCabinetSelection(partId: string): void {
		this.setNewCabinetSelection.emit(partId);
	}

	public openCabinetGroupSelection(): void {
		this.showNewCabinetGroupSelection = !this.showNewCabinetGroupSelection;
	}

	public clickCreateItem(part: Part): void {
		this.editorRepository.setActiveConfigurationPanel(null);
		this.selectPart.emit(part);
		this.togglePart.emit(part.id);
		this.createItem.emit(part);
		this.router.navigate(['/', 'projects', this.project.id, 'editor'], {
			queryParams: {
				...this.route.snapshot.queryParams,
			}
		});
	}

	public clickAddItemFromCatalog(part: Part): void {
		this.router.navigate(['/', 'projects', this.project.id, 'catalog'], {
			queryParams: {
				part: part.id
			}
		})
	}

	public clickCreatePart(): void {
		this.showNewCabinetGroupSelection = false;
		this.createPart.emit();
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}
}
