import { Injectable } from '@angular/core';
import { updateRequestStatus } from '@ngneat/elf-requests';
import { map, take, tap } from 'rxjs/operators';
import {deleteEntities, setEntities, upsertEntities } from '@ngneat/elf-entities';
import { Observable } from 'rxjs';

import {CuttingListLine } from '~shared/types';
import { CuttingListService } from '~core/services/cutting-list.service';

import { cuttingListLineStore } from './cutting-list-lines.store';
import { cuttingListLinesSelector } from './cutting-list-lines.selectors';

@Injectable()
export class CuttingListLinesRepository {
	public cuttingListLines$ = cuttingListLinesSelector.cuttingListLines$;
	public cuttingListLinesLoading$ = cuttingListLinesSelector.cuttingListLinesLoading$;
	public activeCuttingListLine$ = cuttingListLinesSelector.activeCuttingListLine$;

	constructor(
		private readonly cuttingListService: CuttingListService
	) {}

	public getAll(cuttingListId: string): Observable<CuttingListLine[]> {
		cuttingListLineStore.update(updateRequestStatus('get-cutting-list-lines', 'pending'));
		return this.cuttingListService
			.getCuttingListLines(cuttingListId)
			.pipe(
				take(1),
				tap((cuttingListLines) => {
					cuttingListLineStore.update(
						setEntities(cuttingListLines),
						updateRequestStatus('get-cutting-list-lines', 'success')
					)
				})
			)
	}

	public create(cuttingListId: string, body): Observable<CuttingListLine> {
		cuttingListLineStore.update(updateRequestStatus('create-cutting-list-line', 'pending'));
		return this.cuttingListService
			.createCuttingListLine(cuttingListId, body)
			.pipe(
				take(1),
				tap((cuttingListLine) => {
					cuttingListLineStore.update(
						upsertEntities(cuttingListLine),
						updateRequestStatus('create-cutting-list-line', 'success')
					)
				})
			)
	}

	public update(cuttingListId: string, cuttingListLineId: string, body): Observable<CuttingListLine> {
		cuttingListLineStore.update(updateRequestStatus('update-cutting-list-line', 'pending'));
		return this.cuttingListService
			.updateCuttingListLine(cuttingListId, cuttingListLineId, body)
			.pipe(
				take(1),
				tap((cuttingListLine) => {
					cuttingListLineStore.update(
						upsertEntities(cuttingListLine),
						updateRequestStatus('update-cutting-list-line', 'success')
					)
				})
			)
	}

	public duplicate(cuttingListId: string, cuttingListLineId: string): Observable<CuttingListLine> {
		cuttingListLineStore.update(updateRequestStatus('duplicate-cutting-list-line', 'pending'));
		return this.cuttingListService
			.duplicateCuttingListLine(cuttingListId, cuttingListLineId)
			.pipe(
				take(1),
				tap((cuttingListLine) => {
					cuttingListLineStore.update(
						upsertEntities(cuttingListLine),
						updateRequestStatus('duplicate-cutting-list-line', 'success')
					)
				})
			)
	}

	public delete(cuttingListId: string, cuttingListLineId: string): Observable<void> {
		cuttingListLineStore.update(updateRequestStatus('delete-cutting-list-line', 'pending'));
		return this.cuttingListService
			.deleteCuttingListLine(cuttingListId, cuttingListLineId)
			.pipe(
				take(1),
				tap(() => {
					cuttingListLineStore.update(
						deleteEntities(cuttingListLineId),
						updateRequestStatus('delete-cutting-list-line', 'success')
					)
				})
			)
	}
}
