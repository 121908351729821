import { EventEmitter } from "@angular/core";

import { ConstructionParameter } from "~shared/enums/item.enum";

export interface Address {
	street: string;
	houseNumber: string;
	busNumber?: string;
	city: string;
	zip: string;
	country: 'BE' | 'NL' | 'FR' | 'LUX' | 'DE' | 'UK';
}

export interface BasicUserDetails {
	firstname: string;
	lastname: string;
	telephonenumber: string;
	language?: string;
}

export enum SelectionType {
	ARTICLE_ZONE = 'ARTICLE_ZONE',
	ARTICLE = 'ARTICLE',
	FACE = 'FACE',
	ROD = 'ROD',
	DOOR = 'DOOR'
}

export interface ParameterValue {
	parameterType: ConstructionParameter;
	value?: number;
	modifiable?: boolean;
	defaultValue?: any;
	measurementUnit: string;
	constraint?: any;
}

export interface ParameterSet {
	parameters: ParameterValue[];
}

export interface ControllerComponent {
	eventEmitter?: EventEmitter<any>;
	data?: any;
}

export interface PartPosition {
	position: {
		x: number;
		y: number;
		z: number;
	};
	rotation: {
		x: number;
		y: number;
		z: number;
	};
	itemId: string;
	hasPrefilledPosition: boolean;
	partId: string;
}
