import { Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Component({
	selector: 'app-icon',
	templateUrl: './icon.component.html',
})
export class IconComponent {
	@Input() name: string;
	@Input() muted: boolean;
	@Input() spin: boolean;
}
